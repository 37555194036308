import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import { get, post, put, deleteM } from "../../utility/Adapter";
import DataTable from 'react-data-table-component';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const SettingsSite = () => {

    const [langs, setLangs] = useState([]);
    const [langName, setLangName] = useState('');
    const [langCode, setLangCode] = useState('');

    const [editLangName, setEditLangName] = useState('');
    const [editLangCode, setEditLangCode] = useState('');
    const [editLangId, setEditLangId] = useState('');

    useEffect(function () {
        _loadLanguages();
        window.$('[data-toggle="popover"]').popover();
    }, []);

    const _loadLanguages = () => {
        get('languages').then(async function (response) {
            setLangs(response.data.data);

            setTimeout(function(){
                window.$('[data-toggle="popover"]').popover();
            },500);


            
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _setEditData = (_name, _code, _id) => {

        //alert.success('Oh look, an alert!');

        console.log(_name);

        setEditLangName(_name);
        setEditLangCode(_code);
        setEditLangId(_id);

        window.$('#exampleModal').modal('show');
    }

    const _update = () => {
        if (editLangName.length == 0) {
            //alert.error('Please enter language name');
            return false;
        }

        if (editLangCode.length == 0) {
            //alert.error('Please enter language code');
            return false;
        }

        window.$('#exampleModal').modal('hide');

        var data = JSON.stringify({
            "language_name": editLangName,
            "language_code": editLangCode,
            "language_id": editLangId
        });

        put('languages', data).then(async function (response) {
            _loadLanguages();
            //alert.success('Successfully updated!');
        }).catch(function (error) {
        });
    }

    const _save = () => {
        if (langName.length == 0 || langCode.length == 0) {
            return false;
        }

        window.$('#insertModal').modal('hide');

        var data = JSON.stringify({
            "language_name": langName,
            "language_code": langCode
        });

        post('languages', data).then(async function (response) {
            _loadLanguages();
            //alert.success('Successfully created new language!');
        }).catch(function (error) {
        });
    }

    const _deleteLanguage = (_id) => {
        console.log(_id);

        var data = JSON.stringify({
            "language_id": _id
        });

        deleteM('languages/' + _id, data).then(async function (response) {
            _loadLanguages();
            //alert.success('Successfully deleted!');
        }).catch(function (error) {
        });
    }

    const _deleteConfirmation = (_id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => _deleteLanguage(_id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    return (
        <>
            <Header />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        <div class="col">
                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">Site Languages</h1>

                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>

                                    <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                                        <button data-bs-toggle="modal" data-bs-target="#insertModal" type="button" class="btn btn-outline-primary btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto" id="newContactButton">
                                            <i data-acorn-icon="plus" class="icon" data-acorn-size="18"></i>
                                            <span className='ml-2'>Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}

                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelDefault" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabelDefault">Edit Language</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <label class="mb-3 top-label">
                                                <input type="text" onChange={(e) => setEditLangName(e.target.value)} class="form-control required" id="language" defaultValue={editLangName} data-error="Please enter language" />
                                                <span style={{ color: 'rgb(94, 92, 92)' }}>LANGUAGE</span>
                                            </label>

                                            <label class="mb-3 top-label">
                                                <input onChange={(e) => setEditLangCode(e.target.value)} type="text" class="form-control required" id="language_code" defaultValue={editLangCode} data-error="Please enter language code" />
                                                <span style={{ color: 'rgb(94, 92, 92)' }}>LANGUAGE CODE</span>
                                            </label>

                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary" onClick={() => _update()}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="insertModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelDefault" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabelDefault">Create Language</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <label class="mb-3 top-label">
                                                <input type="text" onChange={(e) => setLangName(e.target.value)} class="form-control required" id="language" defaultValue={editLangName} data-error="Please enter language" />
                                                <span style={{ color: 'rgb(94, 92, 92)' }}>LANGUAGE</span>
                                            </label>

                                            <label class="mb-3 top-label">
                                                <input onChange={(e) => setLangCode(e.target.value)} type="text" class="form-control required" id="language_code" defaultValue={editLangCode} data-error="Please enter language code" />
                                                <span style={{ color: 'rgb(94, 92, 92)' }}>LANGUAGE CODE</span>
                                            </label>

                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary" onClick={() => _save()}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="editModalLabel">Edit Language</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <input defaultValue={editLangName} class="form-control has-danger" placeholder="Language Name" type="text" onChange={(e) => setEditLangName(e.target.value)} />
                                            <input defaultValue={editLangCode} class="form-control mg-t-20" placeholder="Language Code" type="text" onChange={(e) => setEditLangCode(e.target.value)} />
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary" onClick={() => _update()}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col'>
                                <div class="mb-1 mb-lg-1 bg-transparent no-shadow d-none d-lg-block">
                                    <div class="row g-0">
                                        <div class="col-auto sw-0 d-none d-lg-flex"></div>
                                        <div class="col">
                                            <div class="ps-0 ps-5 pe-4 pt-0 pb-0 h-100">
                                                <div class="row g-0 h-100 align-content-center custom-sort">
                                                    <div class="col-3 col-lg-4 d-flex flex-column mb-lg-0 pe-3 d-flex">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="name">LANGUAGE</div>
                                                    </div>
                                                    <div class="col-3 col-lg-3 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="email">LANGUAGE CODE</div>
                                                    </div>
                                                    <div class="col-3 col-lg-3 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer" data-sort="phone"></div>
                                                    </div>
                                                    <div class="col-3 col-lg-1 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer" data-sort="group"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                langs.map((l, k) =>
                                    <div className='card mt-2 mb-2 pt-0 pb-0' key={k}>
                                        <div className='card-body pt-3 pb-3'>
                                            <div class="row g-0 h-100 align-content-center custom-sort">
                                                <div class="col-3 col-lg-4 d-flex flex-column mb-lg-0 pe-3 d-flex justify-content-center">
                                                    <div class="cursor-pointer" data-sort="name">{l.language_name}</div>
                                                </div>
                                                <div class="col-3 col-lg-3 d-flex flex-column pe-1 justify-content-center">
                                                    <div class="cursor-pointer" data-sort="email">{l.language_code}</div>
                                                </div>
                                                <div class="col-3 col-lg-3 d-flex flex-column pe-1 justify-content-center">
                                                    <div class="text-muted text-small cursor-pointer" data-sort="phone"></div>
                                                </div>
                                                <div class="col-3 col-lg-1 d-flex flex-column pe-1 justify-content-center">
                                                    <div class="btn-group me-1 check-all-container-checkbox-click" id="checkAllButton">
                                                        <button
                                                            onClick={() => _setEditData(l.language_name, l.language_code, l._id)}
                                                            type="button"
                                                            class="btn btn-outline-primary btn-icon btn-icon-only me-1 me-lg-auto"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title="Edit"
                                                            data-delay='{"show":"1000", "hide":"0"}'
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-edit mb-3 d-inline-block" style={{color:'#646666'}}><path d="M14.6264 2.54528C15.0872 2.08442 15.6782 1.79143 16.2693 1.73077C16.8604 1.67011 17.4032 1.84674 17.7783 2.22181C18.1533 2.59689 18.33 3.13967 18.2693 3.73077C18.2087 4.32186 17.9157 4.91284 17.4548 5.3737L6.53226 16.2962L2.22192 17.7782L3.70384 13.4678L14.6264 2.54528Z"></path></svg>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            class="btn btn-outline-primary btn-icon btn-icon-only me-1 me-lg-auto"
                                                            onClick={() => _deleteConfirmation(l._id)}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-bin mb-3 d-inline-block" style={{color:'#646666'}}><path d="M4 5V14.5C4 15.9045 4 16.6067 4.33706 17.1111C4.48298 17.3295 4.67048 17.517 4.88886 17.6629C5.39331 18 6.09554 18 7.5 18H12.5C13.9045 18 14.6067 18 15.1111 17.6629C15.3295 17.517 15.517 17.3295 15.6629 17.1111C16 16.6067 16 15.9045 16 14.5V5"></path><path d="M14 5L13.9424 4.74074C13.6934 3.62043 13.569 3.06028 13.225 2.67266C13.0751 2.50368 12.8977 2.36133 12.7002 2.25164C12.2472 2 11.6734 2 10.5257 2L9.47427 2C8.32663 2 7.75281 2 7.29981 2.25164C7.10234 2.36133 6.92488 2.50368 6.77496 2.67266C6.43105 3.06028 6.30657 3.62044 6.05761 4.74074L6 5"></path><path d="M2 5H18M12 9V13M8 9V13"></path></svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </main>

            <Footer/>
        </>
    )
}

export default SettingsSite;