import React, { useState, useHook, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';

const Logout = () => {
    useEffect(() => {
        Cookies.remove('hcEmployer');
        window.location = 'https://hireandcare.de/login';
    }, []);

    return (
        <>
            Please wait...
        </>
    )
}

export default Logout;