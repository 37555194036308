import React, { useState, useHook, useEffect, useRef } from "react";
import { get, post, put, deleteM } from "../utility/Adapter";

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { withParams } from "../HOC";

const Details = (props) => {

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState([]);
    const [enquiry, setEnquiry] = useState([]);
    const [candidates, setCandidates] = useState([]);
    const [status, setStatus] = useState(0);

    const t = (lang) => {
        lang = lang.replaceAll("_", " ");
        return lang;
    }

    var languageLevels = {
        NOT_ENTROLLED: t("not_yet_enrolled"),
        A1_ENTROLLED: t("a1_entrolled"),
        A1_COMPLETE: "A2 " + t("complete"),
        B1_COMPLETE: "B1 " + t("complete"),
        B2_COMPLETE: "B2 " + t("complete"),
        C1_COMPLETE: "C1 " + t("complete"),
        C2_COMPLETE: "C2 " + t("complete"),
    };



    var languageLevelsId = [
        { id: "NOT_ENTROLLED", val: t("not_yet_enrolled") },
        { id: "A1_ENTROLLED", val: t("a1_entrolled") },
        { id: "A1_COMPLETE", val: "A2 " + t("complete") },
        { id: "B1_COMPLETE", val: "B1 " + t("complete") },
        { id: "B2_COMPLETE", val: "B2 " + t("complete") },
        { id: "C1_COMPLETE", val: "C1 " + t("complete") },
        { id: "C2_COMPLETE", val: "C2 " + t("complete") },
    ];

    var candidateTypesAll =
    {
        GNM: t("GNM"),
        NURSE: t("Nurse"),
        REGISTERED_NURSE: t("registered_nurse"),
        STUDENT: t("current_student"),
        NONE: t("no_nursing_degree"),
    };

    var candidateTypes = [
        {
            id: "GNM",
            val: t("GNM"),
        },
        {
            id: "NURSE",
            val: t("Nurse"),
        },
        {
            id: "REGISTERED_NURSE",
            val: t("registered_nurse"),
        },
        {
            id: "STUDENT",
            val: t("current_student"),
        },
        {
            id: "NONE",
            val: t("no_nursing_degree"),
        },
    ];

    var gender = {
        m: t("male"),
        f: t("female"),
        t: t("transgender")
    }

    var family_status = {
        s: t("single"),
        m: t("married"),
        w: t("widowed"),
        d: t("divorced"),
    }

    var nc = {
        1: t("bsc_nursing_4_years_course"),
        2: t("msc_nursing_2_years_course"),
        3: t("diploma_in")
    }

    useEffect(() => {
        _loadDetails();
        /*success({
            title: "Button Clicked",
            text:
              "You have clicked the button. You may now complete the process of reading the notice.",
            modules: new Map([
              [
                Confirm,
                {
                  confirm: true,
                  buttons: [
                    {
                      text: "Ok",
                      primary: true,
                      click: notice => {
                        notice.close();
                      }
                    }
                  ]
                }
              ]
            ])
          });*/
    }, []);

    const _loadDetails = () => {
        setLoading(true);
        get("admin/enquiry_details/" + props.params.id)
            .then(async function (response) {
                setResult(response.data.result);
                setEnquiry(JSON.parse(response.data.data.enquiry));
                setLoading(false);

                setStatus(response.data.data.status)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const findCandidates = () => {
        setLoading(true);

        get("admin/candidates_active")
            .then(async function (response) {
                setCandidates(response.data.data);
                setLoading(false);
                window.$("#semiFullExample").modal("toggle");
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);


                info({
                    title: "Add Candidates",
                    text:
                        "No data found!",
                    modules: new Map([
                        [
                            Confirm,
                            {
                                confirm: true,
                                buttons: [
                                    {
                                        text: "Ok",
                                        primary: true,
                                        click: notice => {
                                            notice.close();
                                        }
                                    }
                                ]
                            }
                        ]
                    ])
                });
            });

    }

    const addEnquiry = (candidateId) => {
        setLoading(true);
        get("admin/enquiry/add_candidate/" + candidateId + "/" + props.params.id)
            .then(async function (response) {
                setLoading(false);
                window.$("#semiFullExample").modal("toggle");
                _loadDetails();
                info({
                    title: "Success",
                    text:
                        "Successfully added",
                    modules: new Map([
                        [
                            Confirm,
                            {
                                confirm: true,
                                buttons: [
                                    {
                                        text: "Ok",
                                        primary: true,
                                        click: notice => {
                                            notice.close();
                                        }
                                    }
                                ]
                            }
                        ]
                    ])
                });
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const _approveEnquiry = () => {
        confirmAlert({
            title: 'Confirm to approve',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => _approve()
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const _approve = () => {
        setLoading(true);
        get("admin/enquiry/approve/" + props.params.id)
            .then(async function (response) {
                setLoading(false);
                _loadDetails();
                info({
                    title: "Success",
                    text:
                        "Successfully approved",
                    modules: new Map([
                        [
                            Confirm,
                            {
                                confirm: true,
                                buttons: [
                                    {
                                        text: "Ok",
                                        primary: true,
                                        click: notice => {
                                            notice.close();
                                        }
                                    }
                                ]
                            }
                        ]
                    ])
                });
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    return (
        <>

            <div class="modal fade" id="semiFullExample" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-semi-full modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Candidates</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Nursing License</th>
                                        <th scope="col">Language Level</th>
                                        <th scope="col">Documents</th>
                                        <th scope="col">Created</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {candidates.map((c, k) => (
                                        <tr>
                                            <th scope="row">{k + 1}</th>
                                            <td>
                                                <a href={"/candidate/" + c.candidate_id}>
                                                    <span class="text-capitalize" id="contactName">
                                                        {c.candidate_first_name +
                                                            " " +
                                                            c.candidate_last_name}
                                                    </span>
                                                </a>
                                            </td>
                                            <td>{c.candidate_phone}</td>
                                            <td>{c.candidate_email}</td>
                                            <td>{c.candidate_licence == "Y" ? "Yes" : "No"}</td>
                                            <td style={{ textTransform: "capitalize" }}>{languageLevels[c.candidate_language_level]}</td>
                                            <td>{c.document_status == 0 ? "Pending" : "Uploaded"}</td>
                                            <td>{c.created}</td>
                                            <td>
                                                {c.candidate_status == 1 ? "Active" : "Inactive"}
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-quaternary mb-1" onClick={() => addEnquiry(c.candidate_id)}>Add</button>
                                            </td>
                                        </tr>
                                    ))}

                                    {
                                        candidates.length == 0 &&
                                        <tr>
                                            <td colSpan={9} className="text-center">
                                                No Data Found!
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <Header />
            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />

                        <div class="col">
                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container mb-3">
                                <div class="row">
                                    <div class="col mb-2">
                                        <h1 class="mb-2 pb-0 display-4" id="title">
                                            <i
                                                data-acorn-icon="send"
                                                class="icon"
                                                data-acorn-size="28"
                                            ></i>
                                            &nbsp;Enquiry Details [Status : {status == '1' ? "Approved" : "Not Approved"}]
                                        </h1>
                                        <div class="text-muted font-heading text-small"></div>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                                        {
                                            status == 0 || status == "" &&
                                            <button
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                type="button"
                                                class="btn btn-success btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto"
                                                id="newContactButton"
                                                onClick={() => _approveEnquiry()}
                                            >
                                                <i
                                                    data-acorn-icon="plus"
                                                    class="icon"
                                                    data-acorn-size="18"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal"
                                                ></i>
                                                <span className="ml-2">Approve Enquiry</span>
                                            </button>
                                        }

                                        <button
                                            type="button"
                                            class="btn btn-primary btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto"
                                            id="newContactButton"
                                            onClick={() => findCandidates()}
                                        >
                                            <i
                                                data-acorn-icon="plus"
                                                class="icon"
                                                data-acorn-size="18"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            ></i>
                                            <span className="ml-2">Add New Candidate</span>
                                        </button>
                                    </div>

                                </div>
                            </div>
                            {/* Title and Top Buttons End */}

                            <h3 className="mt-5 me-5">{t("search_details")}</h3>
                            <div class="card mb-5">
                                <div class="card-body">

                                    {
                                        enquiry.filterType != undefined && enquiry.filterType != "" &&
                                        <>
                                            <h4>By Candidate Type</h4>
                                            <hr /><br />
                                            {enquiry.filterType}
                                        </>
                                    }

                                    {
                                        enquiry.filterLanguage != undefined && enquiry.filterLanguage.length > 0 &&
                                        <>
                                            <br /><br />
                                            <h4>By Candidate Language</h4>
                                            <hr /><br />
                                            {
                                                JSON.parse(enquiry.filterLanguage).map((l, k) =>
                                                    <>
                                                        <span style={{ textTransform: "capitalize" }}>{languageLevels[l]}</span>
                                                    </>
                                                )
                                            }
                                        </>
                                    }

                                    {
                                        enquiry.filterDegree != undefined && enquiry.filterDegree != "" &&
                                        <>
                                            <br /><br />
                                            <h4>By Candidate Nursing Degree</h4>
                                            <br />

                                            <span style={{ textTransform: "capitalize" }}>{nc[enquiry.filterDegree]}</span>
                                        </>
                                    }

                                    {
                                        enquiry.filterGender != undefined && enquiry.filterGender != "" &&
                                        <>
                                            <br /><br />
                                            <h4>By Candidate Gender</h4>
                                            <br />

                                            <span style={{ textTransform: "capitalize" }}>{enquiry.filterGender}</span>
                                        </>
                                    }
                                </div>
                            </div>

                            <h3 className="mt-5 me-5">Results</h3>
                            <div class="card mb-5">
                                <div class="card-body">
                                    <table className="table">
                                        <thead>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>DOB</th>
                                            <th>Gender</th>
                                            <th>Experience</th>
                                            <th>Family Status</th>
                                            <th>Language Level</th>
                                            <th>Nursing Course</th>
                                            <th>Actions</th>
                                        </thead>
                                        <tbody>
                                            {
                                                result.map((r, k) =>
                                                    <tr>
                                                        <td>{k + 1}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{r.candidate_first_name} {r.candidate_last_name}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{candidateTypesAll[r.candidate_degree]}</td>
                                                        <td>{r.candidate_dob}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{gender[r.candidate_gender]}</td>
                                                        <td>{r.candidate_experience}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{family_status[r.candidate_family_status]}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{languageLevels[r.candidate_language_level]}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{nc[r.candidate_nursing_course]}</td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            }

                                            {
                                                result.length == 0 &&
                                                <tr>
                                                    <td colSpan={10} className="text-center">
                                                        No results found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </main>
        </>
    )
}

export default withParams(Details);
