import React, { useState, useHook, useEffect, useRef } from 'react';
import { get, post, put, deleteM } from "../../../utility/Adapter";

import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';

import Cookies from 'js-cookie';

const TranslatorDashboard = () => {
    return (
        <>
            <Header />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        {/* Page Content Start */}
                        <div class="col">
                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container mb-3">
                                <div class="row">
                                    <div class="col mb-2">
                                        <h1 class="mb-2 pb-0 display-4" id="title">Dashboard </h1>
                                        <div class="text-muted font-heading text-small"></div>
                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}
                        </div>
                    </div>
                </div>
            </main>

            <Footer/>
        </>
    )
}

export default TranslatorDashboard;