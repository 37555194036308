import React, { useState, useHook, useEffect, useRef } from 'react';
import { get, post, put, deleteM } from "../utility/Adapter";

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import Loader from "../Loader";

import { useTranslation } from "react-i18next";
import "../translations/i18n";

const Index = () => {

  const { t } = useTranslation();
  const [enquiries, setEnquiries] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [interview, setInterview] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    _loadEnquiries(1);
    /*success({
        title: "Button Clicked",
        text:
          "You have clicked the button. You may now complete the process of reading the notice.",
        modules: new Map([
          [
            Confirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Ok",
                  primary: true,
                  click: notice => {
                    notice.close();
                  }
                }
              ]
            }
          ]
        ])
      });*/
  }, []);

  var languageLevels = {
    NOT_ENTROLLED: t("not_yet_enrolled"),
    A1_ENTROLLED: t("a1_entrolled"),
    A1_COMPLETE: "A2 " + t("complete"),
    B1_COMPLETE: "B1 " + t("complete"),
    B2_COMPLETE: "B2 " + t("complete"),
    C1_COMPLETE: "C1 " + t("complete"),
    C2_COMPLETE: "C2 " + t("complete"),
  };

  var languageLevelsId = [
    { id: "NOT_ENTROLLED", val: t("not_yet_enrolled") },
    { id: "A1_ENTROLLED", val: t("a1_entrolled") },
    { id: "A1_COMPLETE", val: "A2 " + t("complete") },
    { id: "B1_COMPLETE", val: "B1 " + t("complete") },
    { id: "B2_COMPLETE", val: "B2 " + t("complete") },
    { id: "C1_COMPLETE", val: "C1 " + t("complete") },
    { id: "C2_COMPLETE", val: "C2 " + t("complete") },
  ];

  var candidateTypesAll =
  {
    GNM: t("GNM"),
    NURSE: t("Nurse"),
    REGISTERED_NURSE: t("registered_nurse"),
    STUDENT: t("current_student"),
    NONE: t("no_nursing_degree"),
  };

  var candidateTypes = [
    {
      id: "GNM",
      val: t("GNM"),
    },
    {
      id: "NURSE",
      val: t("Nurse"),
    },
    {
      id: "REGISTERED_NURSE",
      val: t("registered_nurse"),
    },
    {
      id: "STUDENT",
      val: t("current_student"),
    },
    {
      id: "NONE",
      val: t("no_nursing_degree"),
    },
  ];

  var gender = {
    m: t("male"),
    f: t("female"),
    t: t("transgender")
  }

  var family_status = {
    s: t("single"),
    m: t("married"),
    w: t("widowed"),
    d: t("divorced"),
  }

  var nc = {
    1: t("bsc_nursing_4_years_course"),
    2: t("msc_nursing_2_years_course"),
    3: t("diploma_in")
  }

  const _loadEnquiries = (page) => {
    setLoading(true);
    get("employer/enquiries/" + page)
      .then(async function (response) {
        setEnquiries(response.data.data);
        setRecommended(response.data.recommended);
        setInterview(response.data.interview);
        setLoading(false);

        setTimeout(function () {

          var uninitialized = window.$('#datatableRows').filter(function () {
            return !window.$.fn.DataTable.fnIsDataTable(this);
          });

          uninitialized.each(function () {
            window.$(this).dataTable(
              {
                dom: 'Bfrtip',
                buttons: [
                  {
                    extend: 'excel',
                    text: 'Export Excel',
                    className: 'btn btn-light',
                    exportOptions: {
                      columns: 'th:not(:last-child)'
                    }
                  },
                  {
                    extend: 'csv',
                    text: 'Export Csv',
                    className: 'btn btn-light',
                    exportOptions: {
                      columns: 'th:not(:last-child)'
                    }
                  },

                ]
              }
            );
          });
        }, 100)
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  const _addNew = () => {
    window.location = '/employer/enquiries/generate';
  }

  const _markAsInterest = (candidate_id) => {
    setLoading(true);

    var raw = JSON.stringify({
      "candidate_id": candidate_id,
    });

    setLoading(true);
    post('employer/recommended/mark_interested', raw).then(async function (response) {
      setLoading(false);

      if (response.data.success == false) {
        info({
          title: t("info"),
          text:
            t("already_marked"),
          modules: new Map([
            [
              Confirm,
              {
                confirm: true,
                buttons: [
                  {
                    text: "Ok",
                    primary: true,
                    click: notice => {
                      notice.close();
                    }
                  }
                ]
              }
            ]
          ])
        });
      }
      else {
        _loadEnquiries();
        success({
          title: t("success"),
          text:
            t("success_mark"),
          modules: new Map([
            [
              Confirm,
              {
                confirm: true,
                buttons: [
                  {
                    text: "Ok",
                    primary: true,
                    click: notice => {
                      notice.close();
                      window.location = "/employer/enquiry/" + response.data.enquiry_id
                    }
                  }
                ]
              }
            ]
          ])
        });
      }



    }).catch(function (error) {
      setLoading(false);
      info({
        title: t("info"),
        text:
          t("got_job"),
        modules: new Map([
          [
            Confirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Ok",
                  primary: true,
                  click: notice => {
                    notice.close();
                  }
                }
              ]
            }
          ]
        ])
      });
    });
  }

  var documentStatus = {
    0: 'Pending',
    1: 'Submitted',
    2: 'Waiting Proposal',
    3: 'Waiting Payment',
    4: 'Translation Progress',
    5: 'Translation Under Review',
    6: 'Completed'
  }

  return (
    <>
      <Header />
      <Loader loading={loading} />
      <main>
        <div class="container-fluid">
          <div class="row">
            {/* Menu Start */}
            <SideBar />
            {/*  Menu End */}

            {/* Page Content Start */}
            <div class="col">
              {/* Title and Top Buttons Start */}
              <div class="page-title-container mb-3">
                <div class="row">
                  <div class="col mb-2">
                    <h1 class="mb-2 pb-0 display-4" id="title">
                    </h1>
                    <div class="text-muted font-heading text-small"></div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      type="button"
                      class="btn btn-outline-primary btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto"
                      id="newContactButton"
                      onClick={() => _addNew()}
                    >
                      <i
                        data-acorn-icon="plus"
                        class="icon"
                        data-acorn-size="18"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      ></i>
                      <span className="ml-2">{t("generate_new_enquiry")}</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* Title and Top Buttons End */}

              <div class="row">
                <div class="col-12 col-md-12">
                  <h1 class="mb-0 pb-0 display-4" id="title">
                    {t("recommended_candidates")}</h1>
                  <hr />
                </div>
              </div>

              <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4 g-4 mb-5">
                {
                  recommended.map((r, k) =>
                    <div class="col">
                      <div class="card h-100">
                        <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                          <img src={r.candidate_image} class="card-img-top sh-30" alt="card image" style={{ objectFit: 'contain' }} />
                        </a>
                        <div class="card-body">
                          <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>HNC{r.id}</a>
                          <div class="text-muted mb-1">{candidateTypesAll[r.candidate_degree]}</div>

                          <div class="row g-0 align-items-center mb-2">
                            <div class="col pt-4">
                              <div class="d-flex align-items-center lh-1-25">{languageLevels[r.candidate_language_level]}</div>
                            </div>
                          </div>
                          <div class="row g-0 align-items-center mb-2">

                            <div class="col">
                              <div class="d-flex align-items-center lh-1-25">{nc[r.candidate_nursing_course]}</div>
                            </div>
                          </div>
                          <div class="row g-0 align-items-center mb-2">

                            <div class="col">
                              <div class="d-flex align-items-center lh-1-25">{r.candidate_dob}</div>
                            </div>
                          </div>
                          <div class="row g-0 align-items-center mb-2 mt-4">
                            <div className="col-12 mb-3">
                              <button type="button" class="btn btn-sm btn-outline-primary btn-icon btn-icon-start me-2 w-100" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                                <span>{t("view_details")}</span>
                              </button>
                            </div>
                            <div className="col-12">
                              {
                                (r.candidate_type == undefined || r.candidate_type == 0 || r.candidate_type == null) && r.exists == 0 &&
                                <button class="btn btn-sm btn-icon-start btn-outline-quaternary mb-1 w-100" type="button" onClick={() => _markAsInterest(r._id)}>
                                  <span>{t("mark_as_interest")}</span>
                                </button>
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>

              {
                recommended.length == 0 &&
                <div className='row'>
                  <div className='col-lg-12 text-center'>
                    {t('no_data_found')}
                  </div>
                </div>
              }

              <div class="row" style={{ marginTop: 120 }}>
                <div class="col-12 col-md-12">
                  <h1 class="mb-0 pb-0 display-4" id="title">
                    {t("interview_scheduled_candidates")}</h1>
                  <hr />
                </div>
              </div>

              <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4 g-4 mb-5">
                {
                  interview.map((r, k) =>
                    <>
                      {
                        r.candidate_date != "" ?
                          <div class="col">
                            <div class="card h-100">
                              <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                                <img src={r.candidate_image} class="card-img-top sh-30" alt="card image" style={{ objectFit: 'contain' }} />
                              </a>
                              <div class="card-body">
                                <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>HNC{r.id}</a>
                                <div class="text-muted mb-1">{candidateTypesAll[r.candidate_degree]}</div>

                                <div class="row g-0 align-items-center mb-2">
                                  <div class="col pt-4">
                                    <div class="d-flex align-items-center lh-1-25">{languageLevels[r.candidate_language_level]}</div>
                                  </div>
                                </div>
                                <div class="row g-0 align-items-center mb-2">

                                  <div class="col">
                                    <div class="d-flex align-items-center lh-1-25">{nc[r.candidate_nursing_course]}</div>
                                  </div>
                                </div>
                                <div class="row g-0 align-items-center mb-2">
                                  <div class="col">
                                    <div class="d-flex align-items-center lh-1-25">{r.candidate_dob}</div>
                                  </div>
                                </div>

                                <div class="text-muted mb-1 mt-4">Interview Date</div>
                                <div class="mb-1">{r.candidate_date}</div>

                                <div class="text-muted mb-1">Interview Url</div>
                                <div class="mb-1">{r.candidate_url}</div>

                                <div class="row g-0 align-items-center mb-2 mt-4">
                                  <div className="col">
                                    <button type="button" class="btn btn-sm btn-outline-primary btn-icon btn-icon-start me-2" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                                      <span>{t("view_details")}</span>
                                    </button>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          <div className="col"> No Data Found! </div>
                      }
                    </>

                  )
                }


              </div>

              {
                interview.length == 0 &&
                <div className='row'>
                  <div className='col-lg-12 text-center'>
                    {t('no_data_found')}
                  </div>
                </div>
              }


              <div class="row" style={{ marginTop: 120 }}>
                <div class="col-12 col-md-12">
                  <h1 class="mb-0 pb-0 display-4" id="title">
                    {t("enquiries")}</h1>
                  <hr />
                </div>
              </div>

              <div class="data-table-rows slim">
                <div class="data-table-responsive-wrapper">
                  <table id="datatableRows" class="data-table nowrap hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        {/*<th>{t("enq_id")}</th>*/}
                        <th>{t("results")}</th>
                        <th>{t("interested_candidates")}</th>
                        <th>{t("interview_candidates")}</th>

                        <th>{t("created")}</th>
                        <th>{t("actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        enquiries.map((e, k) =>
                          <tr>
                            <td>{k + 1}</td>
                            {/*<td><a href="#">HNC4</a></td>*/}
                            <td>{e.results}</td>
                            <td>{e.interested}</td>
                            <td>{e.interview}</td>
                            <td>{e.created}</td>
                            <td><button type="button" class="btn btn-primary btn-sm mb-1" onClick={() => window.location = "/employer/enquiry/" + e.id}>{t("view_details")}</button></td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>


            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  )
}

export default Index;