import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from '../Loader';
import { get, post, put, deleteM } from "../../utility/Adapter";
import { withParams } from "../HOC";

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import DataTable, { createTheme } from 'react-data-table-component';

var moment = require('moment');

const AffiliatesIndex = () => {

    const [affliators, setAffliators] = useState([]);
    const [affliatorId, setAffliatorId] = useState(0);
    const [loading, setLoading] = useState(false);

    Element.prototype.insertAfter = function (newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const _loadAffliates = () => {
        setLoading(true);
        get('affiliators').then(async function (response) {
            setAffliators(response.data.data);
            setLoading(false);

            var table = window.$('#datatableRows').DataTable();
            table.destroy();

            setTimeout(function () {

                var uninitialized = window.$('#datatableRows').filter(function () {
                    return !window.$.fn.DataTable.fnIsDataTable(this);
                });

                uninitialized.each(function () {
                    window.$(this).dataTable(
                        {
                            dom: 'Bfrtip',
                            buttons: [
                                {
                                    extend: 'excel',
                                    text: 'Export Excel',
                                    className: 'btn btn-light',
                                    exportOptions: {
                                        columns: 'th:not(:last-child)'
                                    }
                                },
                                {
                                    extend: 'csv',
                                    text: 'Export Csv',
                                    className: 'btn btn-light',
                                    exportOptions: {
                                        columns: 'th:not(:last-child)'
                                    }
                                },

                            ]
                        }
                    );
                });
            }, 100);

        }).catch(function (error) {
            console.log(error);
        });
    }

    useEffect(function () {
        _loadAffliates();


    }, []);

    const _save = () => {
        var ele = document.querySelectorAll(".error");

        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }

        var name = document.getElementById('name').value;
        var phone = document.getElementById('phone').value;
        var email = document.getElementById('email').value;
        var website = document.getElementById('website').value;
        var details = document.getElementById('details').value;
        var code = document.getElementById('code').value;

        if (code.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter affliator code";
            document.getElementById('code').insertAfter(errorElement);
            return false;
        }
        else if (name.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter name";
            document.getElementById('name').insertAfter(errorElement);
            return false;
        }
        else if (!validateEmail(email)) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter email";
            document.getElementById('email').insertAfter(errorElement);
            return false;
        }
        else if (phone.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter phone";
            document.getElementById('phone').insertAfter(errorElement);
            return false;
        }


        var raw = JSON.stringify({
            "email": email,
            "name": name,
            "phone": phone,
            "website": website,
            "details": details,
            "code": code
        });

        post('affiliators', raw).then(async function (response) {
            alert('Successfully created!');
            _loadAffliates();
            window.$("#exampleModal").modal('toggle');
        }).catch(function (error) {
            alert('Email address is already exists');
        });
    }

    const _editPopup = (id) => {
        window.$("#changeModal").modal('show');
        setAffliatorId(id);

        setLoading(true);
        get('affiliators/' + id).then(async function (response) {
            var data = response.data.data;

            document.getElementById('edit_name').value = data.affiliator_name ?? '';
            document.getElementById('edit_email').value = data.affiliator_email ?? '';
            document.getElementById('edit_phone').value = data.affiliator_phone ?? '';
            document.getElementById('edit_website').value = data.affiliator_website ?? '';
            document.getElementById('edit_details').value = data.affiliator_details ?? '';

            setLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _edit = () => {
        var ele = document.querySelectorAll(".error");

        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }

        var name = document.getElementById('edit_name').value;
        var phone = document.getElementById('edit_phone').value;
        var email = document.getElementById('edit_email').value;
        var website = document.getElementById('edit_website').value;
        var details = document.getElementById('edit_details').value;

        if (name.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter name";
            document.getElementById('name').insertAfter(errorElement);
            return false;
        }
        else if (!validateEmail(email)) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter email";
            document.getElementById('email').insertAfter(errorElement);
            return false;
        }
        else if (phone.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter phone";
            document.getElementById('phone').insertAfter(errorElement);
            return false;
        }

        var raw = JSON.stringify({
            "email": email,
            "name": name,
            "phone": phone,
            "website": website,
            "details": details
        });

        put("affiliators/" + affliatorId, raw).then(async function (response) {
            alert('Successfully updated!');
            _loadAffliates();
            window.$("#changeModal").modal('toggle');
        }).catch(function (error) {
            alert('Email address is already exists');
        });
    }

    const _inactive = (id) => {
        if (window.confirm("Are you sure you want to inactive?") == true) {
            setLoading(true);
            get('affiliators/inactive/' + id).then(async function (response) {
                _loadAffliates();
                setLoading(false);
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    const _delete = (id) => {
        if (window.confirm("Are you sure you want to delete?") == true) {
            setLoading(true);
            get('affiliators/delete/' + id).then(async function (response) {
                _loadAffliates();
                setLoading(false);
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    const _active = (id) => {
        if (window.confirm("Are you sure you want to activate?") == true) {
            setLoading(true);
            get('affiliators/activate/' + id).then(async function (response) {
                _loadAffliates();
                setLoading(false);
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    return (
        <>
            <Header />
            <Loader loading={loading} />

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Affiliator</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Affiliator Code</span>
                                <input class="form-control" id="code" placeholder="Affliator Code" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Affiliator Name</span>
                                <input class="form-control" id="name" placeholder="Affliator name" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Affiliator Email</span>
                                <input class="form-control" id="email" placeholder="Affliator email" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Affiliator Phone</span>
                                <input class="form-control" id="phone" placeholder="Affliator phone" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Affiliator Website</span>
                                <input class="form-control" id="website" placeholder="Affliator website" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Affiliator Details</span>
                                <textarea class="form-control" id="details" placeholder="Affliator details"></textarea>
                            </label>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => _save()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="changeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Edit Affiliator</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Affiliator Name</span>
                                <input class="form-control" id="edit_name" placeholder="Affliator name" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Affiliator Email</span>
                                <input class="form-control" id="edit_email" placeholder="Affliator email" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Affiliator Phone</span>
                                <input class="form-control" id="edit_phone" placeholder="Affliator phone" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Affiliator Website</span>
                                <input class="form-control" id="edit_website" placeholder="Affliator website" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Affiliator Details</span>
                                <textarea class="form-control" id="edit_details" placeholder="Affliator details"></textarea>
                            </label>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => _edit()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <main>
                <div class="container-fluid">
                    <div class="row">
                        <SideBar />

                        <div class="col">
                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container mb-5">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">
                                                Affiliates
                                            </h1>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                                        <button data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" class="btn btn-outline-primary btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto" id="newContactButton">
                                            <i data-acorn-icon="plus" class="icon" data-acorn-size="18"></i>
                                            <span className='ml-2'>Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}

                            <div class="data-table-rows slim mt-5">
                                <div class="data-table-responsive-wrapper">
                                    <table id="datatableRows" class="data-table nowrap hover">
                                        <thead>
                                            <tr>
                                                <th class="text-muted text-small text-uppercase">#</th>
                                                <th class="text-muted text-small text-uppercase">ID</th>
                                                <th class="text-muted text-small text-uppercase">Name</th>
                                                <th class="text-muted text-small text-uppercase">Phone</th>
                                                <th class="text-muted text-small text-uppercase">Email</th>
                                                <th class="text-muted text-small text-uppercase">Candidates</th>
                                                <th class="text-muted text-small text-uppercase">Register Url</th>
                                                <th class="text-muted text-small text-uppercase">Status</th>
                                                <th class="text-muted text-small text-uppercase">Created</th>
                                                <th class="text-muted text-small text-uppercase">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                affliators.map((a, k) =>
                                                    <tr>
                                                        <th scope="row">{k + 1}</th>
                                                        <td>{a.affiliator_id}</td>
                                                        <td className="text-capitalize">{a.affiliator_name}</td>
                                                        <td>{a.affiliator_phone}</td>
                                                        <td>{a.affiliator_email}</td>
                                                        <td>{a.documentsCount}</td>
                                                        <td><input type="text" value={"https://hireandcare.de/register/" + a.affiliator_id} /></td>
                                                        <td>
                                                            {
                                                                a.affliator_status == 0 ? <span class="badge bg-success">Active</span> : <span class="badge bg-warning text-dark">Inactive</span>
                                                            }
                                                        </td>
                                                        <td>{moment(a.createdAt).format('lll')}</td>
                                                        <td style={{ color: '#b3b1b1' }}>
                                                            <a href={"/affiliates/" + a._id}>View</a>
                                                            &nbsp;|&nbsp;
                                                            <a href="javascript:void(0);" onClick={() => _editPopup(a._id)}>Edit</a>
                                                            &nbsp;|&nbsp;

                                                            {
                                                                a.affliator_status == 0 ?
                                                                    <a href="javascript:void(0);" onClick={() => _inactive(a._id)}>Inactive</a>
                                                                    :
                                                                    <a href="javascript:void(0);" onClick={() => _active(a._id)}>Activate</a>

                                                            }


                                                            &nbsp;|&nbsp;
                                                            <a href="javascript:void(0);" onClick={() => _delete(a._id)}>Delete</a>
                                                        </td>
                                                    </tr>
                                                )
                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </main>

            <Footer />

        </>
    )
}

export default AffiliatesIndex;