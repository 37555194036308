import React, { useState, useHook, useEffect, useRef } from 'react';
import logo from '../images/logo.svg';
import avatar from '../images/avatar.jpg';

const Header = () => {
    return (
        <>
            <div id="nav" class="nav-container d-flex">
                <div class="nav-content d-flex">
                    {/* Logo Start */}
                    <div class="logo position-relative">
                        <a href="/">
                            {/* Logo can be added directly */}
                            <div class="img" style={{backgroundImage: `url(${logo})`, width: 300,minHeight: 52, marginTop:'-3px'}}></div>

                            {/* Or added via css to provide different ones for different color themes */}
                            {/*<div class="img">Auto AID</div>*/}
                        </a>
                    </div>
                    {/* Logo End */}

                    {/* User Menu Start */}
                    <div class="user-container d-flex">
                        <a href="#" class="d-flex user position-relative" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img class="profile" alt="profile" src={avatar} />
                            <div class="name">Admin</div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end user-menu wide">
                            <div class="row mb-3 ms-0 me-0">
                                <div class="col-12 ps-1 mb-2">
                                    <div class="text-extra-small text-primary">ACCOUNT</div>
                                </div>
                                <div class="col-6 ps-1 pe-1">
                                </div>
                                {/*<div class="col-6 pe-1 ps-1">
                                    <ul class="list-unstyled">
                                        <li>
                                            <a href="#">Security</a>
                                        </li>
                                        <li>
                                            <a href="#">Billing</a>
                                        </li>
                                    </ul>
</div>*/}
                            </div>
                            {/*<div class="row mb-1 ms-0 me-0">
                                <div class="col-12 p-1 mb-2 pt-2">
                                    <div class="text-extra-small text-primary">APPLICATION</div>
                                </div>
                                <div class="col-6 ps-1 pe-1">
                                    <ul class="list-unstyled">
                                        <li>
                                            <a href="#">Themes</a>
                                        </li>
                                        <li>
                                            <a href="#">Language</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-6 pe-1 ps-1">
                                    <ul class="list-unstyled">
                                        <li>
                                            <a href="#">Devices</a>
                                        </li>
                                        <li>
                                            <a href="#">Storage</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>*/}
                            <div class="row mb-1 ms-0 me-0">
                                <div class="col-6 pe-1 ps-1">
                                    <ul class="list-unstyled">
                                        <li>
                                            <a href="/logout">
                                                <i data-acorn-icon="logout" class="me-2" data-acorn-size="17"></i>
                                                <span class="align-middle">Logout</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* User Menu End */}

                    {/* Icons Menu Start */}
                    <ul class="list-unstyled list-inline text-center menu-icons">
                        
                    </ul>
                    {/* Icons Menu End */}

                    {/* Menu Start */}
                    <div class="menu-container flex-grow-1">
                        <ul id="menu" class="menu">
                            {/*<li>
                                <a href="Blog.html">
                                    <i data-acorn-icon="file-text" class="icon" data-acorn-size="18"></i>
                                    <span class="label">Blog</span>
                                </a>
                            </li>
                            <li>
                                <a href="Upgrade.html">
                                    <i data-acorn-icon="trend-up" class="icon" data-acorn-size="18"></i>
                                    <span class="label">Upgrade</span>
                                </a>
                            </li>
                            <li>
                                <a href="Community.html">
                                    <i data-acorn-icon="messages" class="icon" data-acorn-size="18"></i>
                                    <span class="label">Community</span>
                                </a>
    </li>*/}
                        </ul>
                    </div>
                    {/* Menu End */}

                    {/* Mobile Buttons Start */}
                    <div class="mobile-buttons-container">
                        {/* Menu Button Start */}
                        <a href="#" id="mobileMenuButton" class="menu-button">
                            <i data-acorn-icon="menu"></i>
                        </a>
                        {/* Menu Button End */}
                    </div>
                    {/* Mobile Buttons End */}
                </div>
                <div class="nav-shadow"></div>
            </div>
        </>
    )
}

export default Header;