import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Cookies from 'js-cookie';

import { TRANSLATIONS_GR } from "./gr/translations";
import { TRANSLATIONS_EN } from "./en/translations";
 
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     de: {
       translation: TRANSLATIONS_GR
     }
   }
 });

var lang = Cookies.get("_lang");
if (lang == "" || lang == null) {
    lang = "en";
}
 
i18n.changeLanguage(lang);

export default i18n;