export const TRANSLATIONS_GR = {
    employer_login: "Arbeitgeber-Login",
    register_now: "Jetzt registrieren",
    welcome:"Herzlich willkommen",
    lets:"Lass uns anfangen!",
    credential:"Bitte verwenden Sie Ihre Zugangsdaten, um sich anzumelden.",
    login:"Anmeldung",
    enter_email:"Bitte geben Sie eine gültige Email Adresse an",
    enter_password:"Bitte gültiges Passwort eingeben",
    invalid_login:"Ungültige E-Mail/Passwort",
    enquiries:"ANFRAGEN",
    all:"Alle",
    generate:"Generieren",
    created:"Erstellt",
    results:"Ergebnisse (Anzahl der Kandidaten)",
    actions:"Aktionen",
    view_details:"Details anzeigen",
    add_new:"Neue hinzufügen",
    by_candidate_type: "Nach Kandidatentyp",
    Nurse:"Krankenpfleger/Krankenschwester",
    registered_nurse:"Staatlich geprüfte Krankenschwester",
    current_student: "Aktueller Schüler",
    no_nursing_degree:"Kein Pflegegrad",
    by_candidate_language:"Nach Kandidatensprache",
    not_yet_enrolled:"Noch nicht eingeschrieben",
    a1_entrolled:"A1 eingeschrieben",
    complete:"Vollständig",
    by_candidate_documents: "Nach Kandidatenunterlagen",
    translation_processing:"Übersetzungsverarbeitung",
    translation_completed:"Übersetzung abgeschlossen",
    by_candidate_nursing_degree:"Nach Abschluss des Krankenpflegekandidaten",
    bsc_nursing_4_years_course:"B.Sc Krankenpflege 4 Jahre Ausbildung",
    msc_nursing_2_years_course: "M.Sc Pflege 2 Jahre Kurs",
    diploma_in:"Diplom in Krankenpflege/GNM 3,5-jähriger Kurs",
    post_basic:"Post Basic B.Sc 2 Jahre Kurs",
    candiate_gender:"Nach Geschlecht des Kandidaten",
    male:"Männlich",
    female:"Weiblich",
    transgender:"Transgender",
    success_generated:"Ihre Anfragen wurden erfolgreich erstellt, bitte überprüfen Sie Ihren Anfragebereich",
    success:"Erfolg",
    enquiry_details:"Anfragedetails",
    search_details:"Suchdetails",
    single:"Single",
    married:"Verheiratet",
    widowed:"Verwitwet",
    divorced:"Geschieden",
    candidate_details:"Kandidatendetails",
    candidate_biodata: "Kandidaten-Biodaten",
    express_interest:"Interesse bekunden",
    delete_list:"Aus Ergebnis löschen",
    success_mark: "Erfolgreich als interessiert gekennzeichnete Kandidaten",
    success_schedule: "Erfolgreich eingeplant",
    already_marked: "Sie haben diesen Kandidaten bereits als interessiert markiert",
    info:"Info",
    enq_id: "Anfrage-ID",
    interested_candidates: "Interessierte Kandidaten",
    interview_candidates: "Vorstellungsgespräch geplant",
    interview_scheduled_candidates: "Geplante Kandidaten interviewen",
    recommended_candidates: "Empfohlene Kandidaten",
    enquiries: "Anfragen",
    mark_as_interest: "Auswahlliste",
    success_saved: "Erfolgreich gespeichert",
    got_job: "Der Kandidat hat einen neuen Job bekommen",
    generate_new_enquiry: "Neue Anfrage generieren",
    no_data_found: "Keine Daten gefunden",
    candidates: "Kandidatinnen",
    selected: "Ausgewählt",

    candidate_dob : "Geburtsdatum",
    candidate_family_status : "Familienstand",
    candidate_language_level: "Sprachniveau",
    candidate_gender: "GeschlechtGeschlecht",
    date: "Datum",
    rejected: "Abgelehnt",
    not_attend: "Nicht teilnehmen",
    schedule: "Zeitplan",
    schedule_interview: "Vorstellungsgespräch vereinbaren",
    re_schedule: "Neu planen",
    update_interview_status:"Interview status aktualisieren",

    candidate_cancelled: "Kandidat abgesagt",
    other:"Andere",
    interview_status: "Interview status",
    enquiry_details: "Anfrage details",

    interview_date: "Interview Datum",
    interview_url: "Interview-URL"
}