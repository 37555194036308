import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from '../Loader';
import { get, post, put, deleteM } from "../../utility/Adapter";
import { withParams } from "../HOC";

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import DataTable, { createTheme } from 'react-data-table-component';

var moment = require('moment');

const AffiliatesDetails = (props) => {

    const [loading, setLoading] = useState(false);
    const [candidates, setCandidates] = useState([]);

    const _loadAffiliates = () => {
        setLoading(true);
        get('affiliators/candidates/' + props.params.id).then(async function (response) {
            setCandidates(response.data.data);
            setLoading(false);

            var table = window.$('#datatableRows').DataTable();
            table.destroy();

            setTimeout(function () {

                var uninitialized = window.$('#datatableRows').filter(function () {
                    return !window.$.fn.DataTable.fnIsDataTable(this);
                });

                uninitialized.each(function () {
                    window.$(this).dataTable(
                        {
                            dom: 'Bfrtip',
                            buttons: [
                                {
                                    extend: 'excel',
                                    text: 'Export Excel',
                                    className: 'btn btn-light',
                                    exportOptions: {
                                        columns: 'th:not(:last-child)'
                                    }
                                },
                                {
                                    extend: 'csv',
                                    text: 'Export Csv',
                                    className: 'btn btn-light',
                                    exportOptions: {
                                        columns: 'th:not(:last-child)'
                                    }
                                },

                            ]
                        }
                    );
                });
            }, 100);

        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    }

    function replaceString(str) {

        if (str == undefined) {
            return "";
        }

        return str.replace("_", " ");
    }

    useEffect(function () {
        _loadAffiliates();
    }, []);


    return (
        <>
            <Header />
            <Loader loading={loading} />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        <SideBar />

                        <div class="col">

                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container mb-5">
                                <div class="row g-0">
                                    <div class="col-lg-12 mb-2 mb-md-0 me-auto">

                                        <h1 class="mb-0 pb-0 display-4" id="title">
                                            Affiliator Candidate Details
                                        </h1>

                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}

                            <div class="data-table-rows slim mt-5">
                                <div class="data-table-responsive-wrapper">
                                    <table id="datatableRows" class="data-table nowrap hover">
                                        <thead>
                                            <tr>
                                                <th class="text-muted text-small text-uppercase">#</th>
                                                <th class="text-muted text-small text-uppercase">ID</th>
                                                <th class="text-muted text-small text-uppercase">Name</th>
                                                <th class="text-muted text-small text-uppercase">Phone</th>
                                                <th class="text-muted text-small text-uppercase">Email</th>
                                                <th class="text-muted text-small text-uppercase">Type</th>
                                                <th class="text-muted text-small text-uppercase">Candidate Status</th>
                                                <th class="text-muted text-small text-uppercase">Created</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {candidates.map((c, k) =>
                                                <tr>
                                                    <td>{k + 1}</td>
                                                    <td>HNC{c.id}</td>
                                                    <td>
                                                        <a href={"/candidate/" + c._id}>
                                                            <span class="text-capitalize" id="contactName">
                                                                {c.candidate_first_name +
                                                                    " " +
                                                                    c.candidate_last_name}
                                                            </span>
                                                        </a>
                                                        <div>{c.candidate_interview_status == "selected" ? " [Got Placement] " : ""}</div>
                                                    </td>
                                                    <td>{c.candidate_phone}</td>
                                                    <td><div style={{ width: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{c.candidate_email}</div></td>
                                                    {/*<td>{c.candidate_licence == "Y" ? "Yes" : "No"}</td>*/}
                                                    <td>{replaceString(c.candidate_degree)}</td>
                                                    <td className="text-uppercase">{c.candidate_affiliator_status}</td>
                                                    <td>{moment(c.createdAt).format('lll')}</td>

                                                </tr>
                                            )}
                                        </tbody>

                                    </table>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </main>

        </>
    );
}

export default withParams(AffiliatesDetails);

