import React, { useState, useHook, useEffect, useRef } from 'react';
import { get, post, put, deleteM } from "../../translator/utility/Adapter";

import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';

import Cookies from 'js-cookie';
import { withParams } from "../../HOC";

const TranslatorDocuments = (props) => {
    const [documents, setDocuments] = useState([]);

    useEffect(function () {
        _loadDocuments();
    }, []);

    const _loadDocuments = () => {
        var raw = JSON.stringify({
            "type": props.params.type,
        });

        post('translator/documents', raw).then(async function (response) {
            setDocuments(response.data.data);
            var table = window.$('#datatableRows').DataTable();
            table.destroy();

            setTimeout(function () {
                var uninitialized = window.$('#datatableRows').filter(function () {
                    return !window.$.fn.DataTable.fnIsDataTable(this);
                });

                uninitialized.each(function () {
                    window.$(this).dataTable(
                        {
                            dom: 'Bfrtip',
                            buttons: [
                                {
                                    extend: 'excel',
                                    text: 'Export Excel',
                                    className: 'btn btn-light',
                                    exportOptions: {
                                        columns: 'th:not(:last-child)'
                                    }
                                },
                                {
                                    extend: 'csv',
                                    text: 'Export Csv',
                                    className: 'btn btn-light',
                                    exportOptions: {
                                        columns: 'th:not(:last-child)'
                                    }
                                },

                            ]
                        }
                    );
                });
            }, 100);
        }).catch(function (error) {
        });
    }


    return (
        <>
            <Header />

            <main>
                <div className="container-fluid">
                    <div className="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        {/* Page Content Start */}
                        <div className="col">
                            {/* Title and Top Buttons Start */}
                            <div className="page-title-container">
                                <div className="row g-0">
                                    <div className="col-auto mb-2 mb-md-0 me-auto">
                                        <div className="w-auto sw-md-30">
                                            <h1 className="mb-0 pb-0 display-4 text-capitalize" id="title">{props.params.type} Documents</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}

                            <h2 class="text-small text-uppercase">Translation Details</h2>
                            <div className='row'>
                                <div class="col-12 col-sm-12 col-lg-12">
                                    <div class="">
                                        <div class="">
                                            <div class="data-table-rows slim">
                                                <div class="data-table-responsive-wrapper">
                                                    <table id="datatableRows" class="data-table nowrap hover" style={{width: '100%'}}>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Candidate</th>
                                                                <th scope="col">Translation Amount</th>
                                                                <th scope="col">Translation Days</th>
                                                                <th scope="col">Created</th>
                                                                <th scope="col">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                documents.map((d, k) =>
                                                                    <tr>
                                                                        <th scope="row">
                                                                            <a href={"#"} className='text-capitalize'>
                                                                                {d.candidate_first_name} {d.candidate_last_name}
                                                                            </a>
                                                                        </th>
                                                                        <td>
                                                                            {d.amount}
                                                                        </td>
                                                                        <td>
                                                                            {d.days}
                                                                        </td>
                                                                        <td>{d.createdAt}</td>
                                                                        <td>
                                                                            <a title="View Documents" style={{ color: 'rgb(0, 0, 0)' }} href={"/translator/candidate/documents/" + d.candidate_id}><span class="badge bg-outline-primary group" id="contactGroup">View Documents</span></a>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }

                                                            {
                                                                documents.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={5} align="center">
                                                                        <span className='text-small'>
                                                                            No Data Found!
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default withParams(TranslatorDocuments);
