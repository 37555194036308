import React, { useState, useHook, useEffect, useRef } from "react";
import { get, post, put, deleteM } from "../utility/Adapter";

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader"

import { useTranslation } from "react-i18next";
import "../translations/i18n";

const NotAttend = () => {
    const [loading, setLoading] = useState(false);
    const [candidates, setCandidates] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        _loadDetails();
    }, []);

    const _loadDetails = () => {
        setLoading(true);
        get("employer/not_attend")
            .then(async function (response) {
                setCandidates(response.data.data);
                setLoading(false);

                setTimeout(function () {

                    var uninitialized = window.$('#datatableRows').filter(function () {
                        return !window.$.fn.DataTable.fnIsDataTable(this);
                    });

                    uninitialized.each(function () {
                        window.$(this).dataTable(
                            {
                                dom: 'Bfrtip',
                                buttons: [
                                    {
                                        extend: 'excel',
                                        text: 'Export Excel',
                                        className: 'btn btn-light',
                                        exportOptions: {
                                            columns: 'th:not(:last-child)'
                                        }
                                    },
                                    {
                                        extend: 'csv',
                                        text: 'Export Csv',
                                        className: 'btn btn-light',
                                        exportOptions: {
                                            columns: 'th:not(:last-child)'
                                        }
                                    },

                                ]
                            }
                        );
                    });
                }, 100)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    var languageLevels = {
        NOT_ENTROLLED: t("not_yet_enrolled"),
        A1_ENTROLLED: t("a1_entrolled"),
        A1_COMPLETE: "A2 " + t("complete"),
        B1_COMPLETE: "B1 " + t("complete"),
        B2_COMPLETE: "B2 " + t("complete"),
        C1_COMPLETE: "C1 " + t("complete"),
        C2_COMPLETE: "C2 " + t("complete"),
    };

    var languageLevelsId = [
        { id: "NOT_ENTROLLED", val: t("not_yet_enrolled") },
        { id: "A1_ENTROLLED", val: t("a1_entrolled") },
        { id: "A1_COMPLETE", val: "A2 " + t("complete") },
        { id: "B1_COMPLETE", val: "B1 " + t("complete") },
        { id: "B2_COMPLETE", val: "B2 " + t("complete") },
        { id: "C1_COMPLETE", val: "C1 " + t("complete") },
        { id: "C2_COMPLETE", val: "C2 " + t("complete") },
    ];

    var candidateTypesAll =
    {
        GNM: t("GNM"),
        NURSE: t("Nurse"),
        REGISTERED_NURSE: t("registered_nurse"),
        STUDENT: t("current_student"),
        NONE: t("no_nursing_degree"),
    };

    var candidateTypes = [
        {
            id: "GNM",
            val: t("GNM"),
        },
        {
            id: "NURSE",
            val: t("Nurse"),
        },
        {
            id: "REGISTERED_NURSE",
            val: t("registered_nurse"),
        },
        {
            id: "STUDENT",
            val: t("current_student"),
        },
        {
            id: "NONE",
            val: t("no_nursing_degree"),
        },
    ];

    var gender = {
        m: t("male"),
        f: t("female"),
        t: t("transgender")
    }

    var family_status = {
        s: t("single"),
        m: t("married"),
        w: t("widowed"),
        d: t("divorced"),
    }

    return (
        <>
            <Header />
            <Loader loading={loading} />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        <SideBar />

                        <div class="col">
                            <div class="page-title-container mb-3">
                                <div class="row">
                                    <div class="col mb-2">
                                        <h1 class="mb-2 pb-0 display-4" id="title">
                                            Not Attend Candidates
                                        </h1>
                                        <div class="text-muted font-heading text-small"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="data-table-rows slim">
                                <div class="data-table-responsive-wrapper">
                                    <table id="datatableRows" class="data-table nowrap hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                {/*<th>{t("enq_id")}</th>*/}
                                                <th>{t("Id")}</th>
                                                <th>{t("candidate_dob")}</th>
                                                <th>{t("candidate_family_status")}</th>
                                                <th>{t("candidate_language_level")}</th>
                                                <th>{t("candidate_gender")}</th>
                                                <th>{t("date")}</th>
                                                <th>{t("actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                candidates.map((c, k) =>
                                                    <tr>
                                                        <td>{k + 1}</td>
                                                        <td>HNC{c.candidate.id}</td>
                                                        <td>{c.candidate.candidate_dob}</td>
                                                        <td>{family_status[c.candidate.candidate_family_status]}</td>
                                                        <td>{languageLevels[c.candidate.candidate_language_level]}</td>
                                                        <td>{gender[c.candidate.candidate_gender]}</td>
                                                        <td>{c.date}</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm btn-outline-primary btn-icon btn-icon-start me-2" onClick={() => window.location = "/employer/enquiry/candidate/" + c.candidate._id}>
                                                                <span>{t("view_details")}</span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default NotAttend;