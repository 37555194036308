import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader";
import { get, post, put, deleteM } from "../../utility/Adapter";
import { withParams } from "../HOC";

const Enquiries = (props) => {

    const [loading, setLoading] = useState(false);
    const [enquiries, setEnquiries] = useState([]);

    useEffect(function () {
        _loadEnquiries();
      }, []);
    
      const _loadEnquiries = () => {
        get("admin/employer/enquiries/" + props.params.id)
          .then(async function (response) {
            setEnquiries(response.data.data);
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      };

    return (
        <>
            <Header />
            <Loader loading={loading} />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        <SideBar />

                        <div class="col">
                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">
                                                Employer Enquiries
                                            </h1>
                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>
                                </div>
                            </div>

                            <div className="card mt-5 mb-5">
                                <div className="card-body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Enquiry Date</th>
                                                <th>Enquiry Results</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                enquiries.map((e, k) =>
                                                    <tr>
                                                        <td>{k+1}</td>
                                                        <td>{e.created}</td>
                                                        <td>{e.results}</td>
                                                        <td>
                                                        <button type="button" class="btn btn-primary mb-1" onClick={() => window.location="/employer/enquiry_details/" + e.id}>View Details</button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default withParams(Enquiries);
