export const TRANSLATIONS_EN = {
    employer_login: "Employer Login",
    register_now: "Register Now",
    welcome:"Welcome",
    lets:"let's get started!",
    credential:"Please use your credentials to login.",
    login:"Login",
    enter_email:"Please enter valid email address",
    enter_password:"Please enter valid password",
    invalid_login:"Invalid email/password",
    enquiries:"ENQUIRIES",
    all:"All",
    generate:"Generate",
    created:"Created",
    results:"Results (No of candidates)",
    actions:"Actions",
    view_details:"View Details",
    add_new:"Add New",
    by_candidate_type: "By Candidate Type",
    Nurse:"Nurse",
    registered_nurse:"Registered Nurse",
    current_student: "Current Student",
    no_nursing_degree:"No Nursing Degree",
    by_candidate_language:"By Candidate Language",
    not_yet_enrolled:"Not yet enrolled",
    a1_entrolled:"A1 Entrolled",
    complete:"Complete",
    by_candidate_documents: "By Candidate Documents",
    translation_processing:"Translation Processing",
    translation_completed:"Translation Completed",
    by_candidate_nursing_degree:"By Candidate Nursing Degree",
    bsc_nursing_4_years_course:"B.Sc Nursing 4 years course",
    msc_nursing_2_years_course: "M.Sc Nursing 2 years course",
    diploma_in:"Diploma in Nursing/GNM 3.5 years course",
    post_basic:"Post Basic B.Sc 2 years course",
    candiate_gender:"By Candidate Gender",
    male:"Male",
    female:"Female",
    transgender:"Transgender",
    success_generated:"Successfully generated your enquiries, please check your enquiry all section",
    success:"Success",
    enquiry_details:"Enquiry Details",
    search_details:"Search Details",
    single:"Single",
    married:"Married",
    widowed:"Widowed",
    divorced:"Divorced",
    candidate_details:"Candidate Details",
    candidate_biodata: "Candidate Biodata",
    express_interest:"Express Interest",
    delete_list:"Delete from result",
    success_mark: "Successfully candidates marked as interested",
    success_schedule: "Successfully scheduled",
    already_marked: "You have already marked this candidate as interested",
    info:"Info",
    enq_id: "Enquiry Id",
    interested_candidates: "Interested Candidates",
    interview_candidates: "Interview Scheduled",
    interview_scheduled_candidates: "Interview Scheduled Candidates",
    recommended_candidates: "Recommended Candidates",
    enquiries: "Enquiries",
    mark_as_interest: "Shortlist",
    success_saved: "Successfully saved",
    got_job: "The candidate got a new placement",
    generate_new_enquiry: "Generate new enquiry",
    no_data_found: "No Data Found",
    candidates: "Candidates",
    selected: "Selected",

    candidate_dob : "Date of birth",
    candidate_family_status : "Family Status",
    candidate_language_level: "Language Level",
    candidate_gender: "Gender",
    date: "Date",
    rejected: "Rejected",
    not_attend: "Not Attend",
    schedule: "Schedule",
    schedule_interview: "Schedule Interview",
    re_schedule: "Re Schedule",
    update_interview_status:"Update Interview Status",

    candidate_cancelled: "Candidate Cancelled",
    other:"Other",
    interview_status: "Interview Status",
    enquiry_details: "Enquiry Details",

    interview_date: "Interview Date",
    interview_url: "Interview Url"
}