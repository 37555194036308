import React, { useState, useHook, useEffect, useRef } from 'react';
import {
    Link
} from "react-router-dom";

const SideBar = () => {
    return (
        <>
            <div class="col-auto d-none d-lg-flex">
                <ul class="sw-25 side-menu mb-0 primary" id="menuSide">
                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <i data-acorn-icon="home" class="icon" data-acorn-size="18"></i>
                            <span class="label">Candidates</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/translator/candidates">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-user icon d-none"><path d="M10.0179 8C10.9661 8 11.4402 8 11.8802 7.76629C12.1434 7.62648 12.4736 7.32023 12.6328 7.06826C12.8989 6.64708 12.9256 6.29324 12.9789 5.58557C13.0082 5.19763 13.0071 4.81594 12.9751 4.42106C12.9175 3.70801 12.8887 3.35148 12.6289 2.93726C12.4653 2.67644 12.1305 2.36765 11.8573 2.2256C11.4235 2 10.9533 2 10.0129 2V2C9.03627 2 8.54794 2 8.1082 2.23338C7.82774 2.38223 7.49696 2.6954 7.33302 2.96731C7.07596 3.39365 7.05506 3.77571 7.01326 4.53982C6.99635 4.84898 6.99567 5.15116 7.01092 5.45586C7.04931 6.22283 7.06851 6.60631 7.33198 7.03942C7.4922 7.30281 7.8169 7.61166 8.08797 7.75851C8.53371 8 9.02845 8 10.0179 8V8Z"></path><path d="M16.5 17.5L16.583 16.6152C16.7267 15.082 16.7986 14.3154 16.2254 13.2504C16.0456 12.9164 15.5292 12.2901 15.2356 12.0499C14.2994 11.2842 13.7598 11.231 12.6805 11.1245C11.9049 11.048 11.0142 11 10 11C8.98584 11 8.09511 11.048 7.31945 11.1245C6.24021 11.231 5.70059 11.2842 4.76443 12.0499C4.47077 12.2901 3.95441 12.9164 3.77462 13.2504C3.20144 14.3154 3.27331 15.082 3.41705 16.6152L3.5 17.5"></path></svg>
                                    <span class="label">All</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-web-page icon d-none"><path d="M14.5 8C15.9045 8 16.6067 8 17.1111 8.33706 17.3295 8.48298 17.517 8.67048 17.6629 8.88886 18 9.39331 18 10.0955 18 11.5L18 14.5C18 15.9045 18 16.6067 17.6629 17.1111 17.517 17.3295 17.3295 17.517 17.1111 17.6629 16.6067 18 15.9045 18 14.5 18L5.5 18C4.09554 18 3.39331 18 2.88886 17.6629 2.67048 17.517 2.48298 17.3295 2.33706 17.1111 2 16.6067 2 15.9045 2 14.5L2 11.5C2 10.0955 2 9.39331 2.33706 8.88886 2.48298 8.67048 2.67048 8.48298 2.88886 8.33706 3.39331 8 4.09554 8 5.5 8L14.5 8zM15 2C15.9319 2 16.3978 2 16.7654 2.15224 17.2554 2.35523 17.6448 2.74458 17.8478 3.23463 18 3.60218 18 4.06812 18 5V5C18 5.93188 18 6.39783 17.8478 6.76537 17.6448 7.25542 17.2554 7.64477 16.7654 7.84776 16.3978 8 15.9319 8 15 8L5 8C4.06812 8 3.60217 8 3.23463 7.84776 2.74458 7.64477 2.35523 7.25542 2.15224 6.76537 2 6.39782 2 5.93188 2 5V5C2 4.06812 2 3.60217 2.15224 3.23463 2.35523 2.74458 2.74458 2.35523 3.23463 2.15224 3.60218 2 4.06812 2 5 2L15 2z"></path><path d="M5 11H15M12 15H15M5 5H6"></path><path d="M6.75 18C6.04777 18 5.69665 18 5.44443 17.8315C5.33524 17.7585 5.24149 17.6648 5.16853 17.5556C5 17.3033 5 16.9522 5 16.25L5 15.75C5 15.0478 5 14.6967 5.16853 14.4444C5.24149 14.3352 5.33524 14.2415 5.44443 14.1685C5.69665 14 6.04777 14 6.75 14L7.25 14C7.95223 14 8.30335 14 8.55557 14.1685C8.66476 14.2415 8.75851 14.3352 8.83147 14.4444C9 14.6967 9 15.0478 9 15.75L9 16.25C9 16.9522 9 17.3033 8.83147 17.5556C8.75851 17.6648 8.66476 17.7585 8.55557 17.8315C8.30335 18 7.95223 18 7.25 18L6.75 18Z"></path></svg>
                            <span class="label">Translations</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/translator/documents/waiting">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-web-page icon d-none"><path d="M14.5 8C15.9045 8 16.6067 8 17.1111 8.33706 17.3295 8.48298 17.517 8.67048 17.6629 8.88886 18 9.39331 18 10.0955 18 11.5L18 14.5C18 15.9045 18 16.6067 17.6629 17.1111 17.517 17.3295 17.3295 17.517 17.1111 17.6629 16.6067 18 15.9045 18 14.5 18L5.5 18C4.09554 18 3.39331 18 2.88886 17.6629 2.67048 17.517 2.48298 17.3295 2.33706 17.1111 2 16.6067 2 15.9045 2 14.5L2 11.5C2 10.0955 2 9.39331 2.33706 8.88886 2.48298 8.67048 2.67048 8.48298 2.88886 8.33706 3.39331 8 4.09554 8 5.5 8L14.5 8zM15 2C15.9319 2 16.3978 2 16.7654 2.15224 17.2554 2.35523 17.6448 2.74458 17.8478 3.23463 18 3.60218 18 4.06812 18 5V5C18 5.93188 18 6.39783 17.8478 6.76537 17.6448 7.25542 17.2554 7.64477 16.7654 7.84776 16.3978 8 15.9319 8 15 8L5 8C4.06812 8 3.60217 8 3.23463 7.84776 2.74458 7.64477 2.35523 7.25542 2.15224 6.76537 2 6.39782 2 5.93188 2 5V5C2 4.06812 2 3.60217 2.15224 3.23463 2.35523 2.74458 2.74458 2.35523 3.23463 2.15224 3.60218 2 4.06812 2 5 2L15 2z"></path><path d="M5 11H15M12 15H15M5 5H6"></path><path d="M6.75 18C6.04777 18 5.69665 18 5.44443 17.8315C5.33524 17.7585 5.24149 17.6648 5.16853 17.5556C5 17.3033 5 16.9522 5 16.25L5 15.75C5 15.0478 5 14.6967 5.16853 14.4444C5.24149 14.3352 5.33524 14.2415 5.44443 14.1685C5.69665 14 6.04777 14 6.75 14L7.25 14C7.95223 14 8.30335 14 8.55557 14.1685C8.66476 14.2415 8.75851 14.3352 8.83147 14.4444C9 14.6967 9 15.0478 9 15.75L9 16.25C9 16.9522 9 17.3033 8.83147 17.5556C8.75851 17.6648 8.66476 17.7585 8.55557 17.8315C8.30335 18 7.95223 18 7.25 18L6.75 18Z"></path></svg>
                                    <span class="label">Waiting Admin</span>
                                </a>
                            </li>

                            <li>
                                <a href="/translator/documents/pending">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-web-page icon d-none"><path d="M14.5 8C15.9045 8 16.6067 8 17.1111 8.33706 17.3295 8.48298 17.517 8.67048 17.6629 8.88886 18 9.39331 18 10.0955 18 11.5L18 14.5C18 15.9045 18 16.6067 17.6629 17.1111 17.517 17.3295 17.3295 17.517 17.1111 17.6629 16.6067 18 15.9045 18 14.5 18L5.5 18C4.09554 18 3.39331 18 2.88886 17.6629 2.67048 17.517 2.48298 17.3295 2.33706 17.1111 2 16.6067 2 15.9045 2 14.5L2 11.5C2 10.0955 2 9.39331 2.33706 8.88886 2.48298 8.67048 2.67048 8.48298 2.88886 8.33706 3.39331 8 4.09554 8 5.5 8L14.5 8zM15 2C15.9319 2 16.3978 2 16.7654 2.15224 17.2554 2.35523 17.6448 2.74458 17.8478 3.23463 18 3.60218 18 4.06812 18 5V5C18 5.93188 18 6.39783 17.8478 6.76537 17.6448 7.25542 17.2554 7.64477 16.7654 7.84776 16.3978 8 15.9319 8 15 8L5 8C4.06812 8 3.60217 8 3.23463 7.84776 2.74458 7.64477 2.35523 7.25542 2.15224 6.76537 2 6.39782 2 5.93188 2 5V5C2 4.06812 2 3.60217 2.15224 3.23463 2.35523 2.74458 2.74458 2.35523 3.23463 2.15224 3.60218 2 4.06812 2 5 2L15 2z"></path><path d="M5 11H15M12 15H15M5 5H6"></path><path d="M6.75 18C6.04777 18 5.69665 18 5.44443 17.8315C5.33524 17.7585 5.24149 17.6648 5.16853 17.5556C5 17.3033 5 16.9522 5 16.25L5 15.75C5 15.0478 5 14.6967 5.16853 14.4444C5.24149 14.3352 5.33524 14.2415 5.44443 14.1685C5.69665 14 6.04777 14 6.75 14L7.25 14C7.95223 14 8.30335 14 8.55557 14.1685C8.66476 14.2415 8.75851 14.3352 8.83147 14.4444C9 14.6967 9 15.0478 9 15.75L9 16.25C9 16.9522 9 17.3033 8.83147 17.5556C8.75851 17.6648 8.66476 17.7585 8.55557 17.8315C8.30335 18 7.95223 18 7.25 18L6.75 18Z"></path></svg>
                                    <span class="label">Pending</span>
                                </a>
                            </li>

                            <li>
                                <a href="/translator/documents/ongoing">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-web-page icon d-none"><path d="M14.5 8C15.9045 8 16.6067 8 17.1111 8.33706 17.3295 8.48298 17.517 8.67048 17.6629 8.88886 18 9.39331 18 10.0955 18 11.5L18 14.5C18 15.9045 18 16.6067 17.6629 17.1111 17.517 17.3295 17.3295 17.517 17.1111 17.6629 16.6067 18 15.9045 18 14.5 18L5.5 18C4.09554 18 3.39331 18 2.88886 17.6629 2.67048 17.517 2.48298 17.3295 2.33706 17.1111 2 16.6067 2 15.9045 2 14.5L2 11.5C2 10.0955 2 9.39331 2.33706 8.88886 2.48298 8.67048 2.67048 8.48298 2.88886 8.33706 3.39331 8 4.09554 8 5.5 8L14.5 8zM15 2C15.9319 2 16.3978 2 16.7654 2.15224 17.2554 2.35523 17.6448 2.74458 17.8478 3.23463 18 3.60218 18 4.06812 18 5V5C18 5.93188 18 6.39783 17.8478 6.76537 17.6448 7.25542 17.2554 7.64477 16.7654 7.84776 16.3978 8 15.9319 8 15 8L5 8C4.06812 8 3.60217 8 3.23463 7.84776 2.74458 7.64477 2.35523 7.25542 2.15224 6.76537 2 6.39782 2 5.93188 2 5V5C2 4.06812 2 3.60217 2.15224 3.23463 2.35523 2.74458 2.74458 2.35523 3.23463 2.15224 3.60218 2 4.06812 2 5 2L15 2z"></path><path d="M5 11H15M12 15H15M5 5H6"></path><path d="M6.75 18C6.04777 18 5.69665 18 5.44443 17.8315C5.33524 17.7585 5.24149 17.6648 5.16853 17.5556C5 17.3033 5 16.9522 5 16.25L5 15.75C5 15.0478 5 14.6967 5.16853 14.4444C5.24149 14.3352 5.33524 14.2415 5.44443 14.1685C5.69665 14 6.04777 14 6.75 14L7.25 14C7.95223 14 8.30335 14 8.55557 14.1685C8.66476 14.2415 8.75851 14.3352 8.83147 14.4444C9 14.6967 9 15.0478 9 15.75L9 16.25C9 16.9522 9 17.3033 8.83147 17.5556C8.75851 17.6648 8.66476 17.7585 8.55557 17.8315C8.30335 18 7.95223 18 7.25 18L6.75 18Z"></path></svg>
                                    <span class="label">Ongoing</span>
                                </a>
                            </li>

                            <li>
                                <a href="/translator/documents/review">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-web-page icon d-none"><path d="M14.5 8C15.9045 8 16.6067 8 17.1111 8.33706 17.3295 8.48298 17.517 8.67048 17.6629 8.88886 18 9.39331 18 10.0955 18 11.5L18 14.5C18 15.9045 18 16.6067 17.6629 17.1111 17.517 17.3295 17.3295 17.517 17.1111 17.6629 16.6067 18 15.9045 18 14.5 18L5.5 18C4.09554 18 3.39331 18 2.88886 17.6629 2.67048 17.517 2.48298 17.3295 2.33706 17.1111 2 16.6067 2 15.9045 2 14.5L2 11.5C2 10.0955 2 9.39331 2.33706 8.88886 2.48298 8.67048 2.67048 8.48298 2.88886 8.33706 3.39331 8 4.09554 8 5.5 8L14.5 8zM15 2C15.9319 2 16.3978 2 16.7654 2.15224 17.2554 2.35523 17.6448 2.74458 17.8478 3.23463 18 3.60218 18 4.06812 18 5V5C18 5.93188 18 6.39783 17.8478 6.76537 17.6448 7.25542 17.2554 7.64477 16.7654 7.84776 16.3978 8 15.9319 8 15 8L5 8C4.06812 8 3.60217 8 3.23463 7.84776 2.74458 7.64477 2.35523 7.25542 2.15224 6.76537 2 6.39782 2 5.93188 2 5V5C2 4.06812 2 3.60217 2.15224 3.23463 2.35523 2.74458 2.74458 2.35523 3.23463 2.15224 3.60218 2 4.06812 2 5 2L15 2z"></path><path d="M5 11H15M12 15H15M5 5H6"></path><path d="M6.75 18C6.04777 18 5.69665 18 5.44443 17.8315C5.33524 17.7585 5.24149 17.6648 5.16853 17.5556C5 17.3033 5 16.9522 5 16.25L5 15.75C5 15.0478 5 14.6967 5.16853 14.4444C5.24149 14.3352 5.33524 14.2415 5.44443 14.1685C5.69665 14 6.04777 14 6.75 14L7.25 14C7.95223 14 8.30335 14 8.55557 14.1685C8.66476 14.2415 8.75851 14.3352 8.83147 14.4444C9 14.6967 9 15.0478 9 15.75L9 16.25C9 16.9522 9 17.3033 8.83147 17.5556C8.75851 17.6648 8.66476 17.7585 8.55557 17.8315C8.30335 18 7.95223 18 7.25 18L6.75 18Z"></path></svg>
                                    <span class="label">Under Review</span>
                                </a>
                            </li>

                            <li>
                                <a href="/translator/documents/completed">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-web-page icon d-none"><path d="M14.5 8C15.9045 8 16.6067 8 17.1111 8.33706 17.3295 8.48298 17.517 8.67048 17.6629 8.88886 18 9.39331 18 10.0955 18 11.5L18 14.5C18 15.9045 18 16.6067 17.6629 17.1111 17.517 17.3295 17.3295 17.517 17.1111 17.6629 16.6067 18 15.9045 18 14.5 18L5.5 18C4.09554 18 3.39331 18 2.88886 17.6629 2.67048 17.517 2.48298 17.3295 2.33706 17.1111 2 16.6067 2 15.9045 2 14.5L2 11.5C2 10.0955 2 9.39331 2.33706 8.88886 2.48298 8.67048 2.67048 8.48298 2.88886 8.33706 3.39331 8 4.09554 8 5.5 8L14.5 8zM15 2C15.9319 2 16.3978 2 16.7654 2.15224 17.2554 2.35523 17.6448 2.74458 17.8478 3.23463 18 3.60218 18 4.06812 18 5V5C18 5.93188 18 6.39783 17.8478 6.76537 17.6448 7.25542 17.2554 7.64477 16.7654 7.84776 16.3978 8 15.9319 8 15 8L5 8C4.06812 8 3.60217 8 3.23463 7.84776 2.74458 7.64477 2.35523 7.25542 2.15224 6.76537 2 6.39782 2 5.93188 2 5V5C2 4.06812 2 3.60217 2.15224 3.23463 2.35523 2.74458 2.74458 2.35523 3.23463 2.15224 3.60218 2 4.06812 2 5 2L15 2z"></path><path d="M5 11H15M12 15H15M5 5H6"></path><path d="M6.75 18C6.04777 18 5.69665 18 5.44443 17.8315C5.33524 17.7585 5.24149 17.6648 5.16853 17.5556C5 17.3033 5 16.9522 5 16.25L5 15.75C5 15.0478 5 14.6967 5.16853 14.4444C5.24149 14.3352 5.33524 14.2415 5.44443 14.1685C5.69665 14 6.04777 14 6.75 14L7.25 14C7.95223 14 8.30335 14 8.55557 14.1685C8.66476 14.2415 8.75851 14.3352 8.83147 14.4444C9 14.6967 9 15.0478 9 15.75L9 16.25C9 16.9522 9 17.3033 8.83147 17.5556C8.75851 17.6648 8.66476 17.7585 8.55557 17.8315C8.30335 18 7.95223 18 7.25 18L6.75 18Z"></path></svg>
                                    <span class="label">Completed</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#" data-bs-target="#dashboard">

                            <span class="label">My Account</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/translator/myaccount">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-cook-hat icon d-none"><path d="M6 6C6 3.79086 7.5454 2 10 2 12.4546 2 14 3.79086 14 6M4 11C3.33333 10.6667 2 9.4 2 7 2 4 5 4 6 4M16 11C16.6667 10.6667 18 9.4 18 7 18 4 15 4 14 4"></path><path d="M14.25 11H5.75C5.04777 11 4.69665 11 4.44443 11.1685C4.33524 11.2415 4.24149 11.3352 4.16853 11.4444C4 11.6967 4 12.0478 4 12.75V15.25C4 15.9522 4 16.3033 4.16853 16.5556C4.24149 16.6648 4.33524 16.7585 4.44443 16.8315C4.69665 17 5.04777 17 5.75 17H14.25C14.9522 17 15.3033 17 15.5556 16.8315C15.6648 16.7585 15.7585 16.6648 15.8315 16.5556C16 16.3033 16 15.9522 16 15.25V12.75C16 12.0478 16 11.6967 15.8315 11.4444C15.7585 11.3352 15.6648 11.2415 15.5556 11.1685C15.3033 11 14.9522 11 14.25 11Z"></path></svg>
                                    <span class="label">My Profile</span>
                                </a>
                            </li>

                            <li>
                                <a href="/translator/logout">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-logout me-2"><path d="M7 15 2.35355 10.3536C2.15829 10.1583 2.15829 9.84171 2.35355 9.64645L7 5M3 10H13M12 18 14.5 18C15.9045 18 16.6067 18 17.1111 17.6629 17.3295 17.517 17.517 17.3295 17.6629 17.1111 18 16.6067 18 15.9045 18 14.5L18 5.5C18 4.09554 18 3.39331 17.6629 2.88886 17.517 2.67048 17.3295 2.48298 17.1111 2.33706 16.6067 2 15.9045 2 14.5 2L12 2"></path></svg>
                                    <span class="align-middle">Logout</span>
                                </a>
                            </li>


                        </ul>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default SideBar;