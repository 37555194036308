import React, { useState, useHook, useEffect, useRef } from "react";
import { get, post, put, deleteM } from "../../employer/utility/Adapter";

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader";

import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { withParams } from "../../HOC";

import '../../../custom_tab.css';

const Details = (props) => {

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState([]);
    const [enquiry, setEnquiry] = useState([]);
    const [step, setStep] = useState(0);
    const [candidate, setCandidate] = useState(0);
    const [notes, setNotes] = useState([]);
    const { t } = useTranslation();

    var languageLevels = {
        NOT_ENTROLLED: t("not_yet_enrolled"),
        A1_ENTROLLED: t("a1_entrolled"),
        A1_COMPLETE: "A2 " + t("complete"),
        B1_COMPLETE: "B1 " + t("complete"),
        B2_COMPLETE: "B2 " + t("complete"),
        C1_COMPLETE: "C1 " + t("complete"),
        C2_COMPLETE: "C2 " + t("complete"),
    };

    var languageLevelsId = [
        { id: "NOT_ENTROLLED", val: t("not_yet_enrolled") },
        { id: "A1_ENTROLLED", val: t("a1_entrolled") },
        { id: "A1_COMPLETE", val: "A2 " + t("complete") },
        { id: "B1_COMPLETE", val: "B1 " + t("complete") },
        { id: "B2_COMPLETE", val: "B2 " + t("complete") },
        { id: "C1_COMPLETE", val: "C1 " + t("complete") },
        { id: "C2_COMPLETE", val: "C2 " + t("complete") },
    ];

    var candidateTypesAll =
    {
        GNM: t("GNM"),
        NURSE: t("Nurse"),
        REGISTERED_NURSE: t("registered_nurse"),
        STUDENT: t("current_student"),
        NONE: t("no_nursing_degree"),
    };

    var candidateTypes = [
        {
            id: "GNM",
            val: t("GNM"),
        },
        {
            id: "NURSE",
            val: t("Nurse"),
        },
        {
            id: "REGISTERED_NURSE",
            val: t("registered_nurse"),
        },
        {
            id: "STUDENT",
            val: t("current_student"),
        },
        {
            id: "NONE",
            val: t("no_nursing_degree"),
        },
    ];

    var gender = {
        m: t("male"),
        f: t("female"),
        t: t("transgender")
    }

    var family_status = {
        s: t("single"),
        m: t("married"),
        w: t("widowed"),
        d: t("divorced"),
    }

    var nc = {
        1: t("bsc_nursing_4_years_course"),
        2: t("msc_nursing_2_years_course"),
        3: t("diploma_in")
    }

    useEffect(() => {
        _loadDetails();

        //window.jQuery('#datePickerBasic').datepicker();

        /*success({
            title: "Button Clicked",
            text:
              "You have clicked the button. You may now complete the process of reading the notice.",
            modules: new Map([
              [
                Confirm,
                {
                  confirm: true,
                  buttons: [
                    {
                      text: "Ok",
                      primary: true,
                      click: notice => {
                        notice.close();
                      }
                    }
                  ]
                }
              ]
            ])
          });*/


    }, []);

    const _loadDetails = () => {
        setLoading(true);
        get("employer/enquiry/" + props.params.id)
            .then(async function (response) {
                setResult(response.data.result);
                setStep(response.data.data.step);
                setEnquiry(JSON.parse(response.data.data.enquiry));
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const _markAsInterest = (candidate_id) => {
        setLoading(true);

        var raw = JSON.stringify({
            "candidate_id": candidate_id,
            "enquiry_id": props.params.id,
        });

        setLoading(true);
        post('employer/mark_interested', raw).then(async function (response) {
            setLoading(false);
            _loadDetails();
            
            success({
                title: t("success"),
                text:
                    t("success_mark"),
                modules: new Map([
                    [
                        Confirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Ok",
                                    primary: true,
                                    click: notice => {
                                        notice.close();
                                       
                                    }
                                }
                            ]
                        }
                    ]
                ])
            });

        }).catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }

    const _removeAsInterest = (candidate_id) => {
        setLoading(true);

        var raw = JSON.stringify({
            "candidate_id": candidate_id,
            "enquiry_id": props.params.id,
        });

        setLoading(true);
        post('employer/mark_interested', raw).then(async function (response) {
            setLoading(false);
            _loadDetails();

            success({
                title: t("success"),
                text:
                    t("success_mark"),
                modules: new Map([
                    [
                        Confirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Ok",
                                    primary: true,
                                    click: notice => {
                                        notice.close();
                                        
                                    }
                                }
                            ]
                        }
                    ]
                ])
            });

        }).catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }

    const _scheduleModal = (candidate) => {
        setCandidate(candidate);
        window.jQuery("#exampleModal").modal('toggle');
    }

    const _schedule = () => {

        var datePickerBasic = document.getElementById("datePickerBasic").value;
        var link = document.getElementById("link").value;

        if (datePickerBasic == "") {
            alert("Please select date");
            return;
        }

        if (link == "")
        {
            alert("Please enter meeting link");
            return;
        }

        setLoading(true);

        var raw = JSON.stringify({
            "candidate_id": candidate,
            "enquiry_id": props.params.id,
            "date": datePickerBasic,
            "url": link
        });

        setLoading(true);
        post('employer/schedule_interview', raw).then(async function (response) {
            setLoading(false);
            window.jQuery("#exampleModal").modal('toggle');
            document.getElementById("datePickerBasic").value = '';
            document.getElementById("link").value = '';
            _loadDetails();

            success({
                title: t("success"),
                text:
                    t("success_schedule"),
                modules: new Map([
                    [
                        Confirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Ok",
                                    primary: true,
                                    click: notice => {
                                        notice.close();
                                        
                                    }
                                }
                            ]
                        }
                    ]
                ])
            });

        }).catch(function (error) {
            setLoading(false);
            window.jQuery("#exampleModal").modal('toggle');
            info({
                title: t("Failed"),
                text:
                    "The candidate got a new placement",
                modules: new Map([
                    [
                        Confirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Ok",
                                    primary: true,
                                    click: notice => {
                                        notice.close();
                                        
                                    }
                                }
                            ]
                        }
                    ]
                ])
            });
        });
    }

    const _istatus = (candidate) => {
        setCandidate(candidate);
        window.$("#istatus").modal("toggle");
    }

    const _saveStatus = () => {
        var interview_status = document.getElementById("interview_status").value;
        var note = document.getElementById("note").value;

        if (interview_status == "" || note == "") {
            alert("Please select interview status or enter note");
            return;
        }

        setLoading(true);

        var raw = JSON.stringify({
            "interview_status": interview_status,
            "enquiry_id": props.params.id,
            "candidate_id": candidate,
            "note": note
        });

        setLoading(true);
        post('employer/interview_status', raw).then(async function (response) {
            setLoading(false);
            window.jQuery("#istatus").modal('toggle');
            document.getElementById("interview_status").value = '';
            document.getElementById("note").value = '';
            _loadDetails();

            success({
                title: t("success"),
                text:
                    t("success_saved"),
                modules: new Map([
                    [
                        Confirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Ok",
                                    primary: true,
                                    click: notice => {
                                        notice.close();

                                    }
                                }
                            ]
                        }
                    ]
                ])
            });

        }).catch(function (error) {
            setLoading(false);

            info({
                title: t("Error"),
                text:
                    "The candidate got a new placement",
                modules: new Map([
                    [
                        Confirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Ok",
                                    primary: true,
                                    click: notice => {
                                        notice.close();

                                    }
                                }
                            ]
                        }
                    ]
                ])
            });
        });
    }

    const _viewNotes = (candidateId) => {
        setLoading(true);
        get("employer/notes/" + candidateId)
            .then(async function (response) {
                setNotes(response.data.data);
                setLoading(false);
                window.$("#semiFullExample").modal("toggle");

                var table = window.$('#datatableRows').DataTable();
                table.destroy();
                setTimeout(function () {

                    var uninitialized = window.$('#datatableRows').filter(function () {
                        return !window.$.fn.DataTable.fnIsDataTable(this);
                    });

                    uninitialized.each(function () {
                        window.$(this).dataTable(
                            {
                                dom: 'Bfrtip',
                                buttons: [
                                    {
                                        extend: 'excel',
                                        text: 'Export Excel',
                                        className: 'btn btn-light',
                                        exportOptions: {
                                            columns: 'th:not(:last-child)'
                                        }
                                    },
                                    {
                                        extend: 'csv',
                                        text: 'Export Csv',
                                        className: 'btn btn-light',
                                        exportOptions: {
                                            columns: 'th:not(:last-child)'
                                        }
                                    },

                                ]
                            }
                        );
                    });
                }, 100)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    return (
        <>
            <Header />
            <Loader loading={loading} />
            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />

                        <div class="col">
                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container mb-3">
                                <div class="row">
                                    <div class="col mb-2">
                                        <h1 class="mb-2 pb-0 display-4" id="title">
                                            &nbsp;{t("enquiry_details")}
                                        </h1>
                                        <div class="text-muted font-heading text-small"></div>
                                    </div>

                                </div>
                            </div>
                            {/* Title and Top Buttons End 

                            <h3 className="mt-5 me-5">{t("search_details")}</h3>
                            <div class="card mb-5">
                                <div class="card-body">

                                    {
                                        enquiry.filterType != undefined && enquiry.filterType != "" &&
                                        <>
                                            <h4>{t("by_candidate_type")}</h4>
                                            <hr/><br />
                                            {enquiry.filterType}
                                        </> 
                                    }

                                    {
                                        enquiry.filterLanguage != undefined && enquiry.filterLanguage.length > 0 &&
                                        <>
                                            <br /><br />
                                            <h4>{t("by_candidate_language")}</h4>
                                            <hr/><br />
                                            {
                                                JSON.parse(enquiry.filterLanguage).map((l, k) => 
                                                    <>
                                                        {l}
                                                    </>
                                                )
                                    }
                                        </>
                                    }

                                    {
                                        enquiry.filterDegree != undefined && enquiry.filterDegree != "" &&
                                        <>
                                            <br /><br />
                                                <h4>{t("by_candidate_nursing_degree")}</h4>
                                                <br />
                                                <span style={{ textTransform: "capitalize" }}>{nc[enquiry.filterDegree]}</span>
                                            <br />
                                        </>
                                    }

{
                                        enquiry.filterGender != undefined && enquiry.filterGender != "" &&
                                        <>
                                            <br /><br />
                                            <h4>By Candidate Gender</h4>
                                            <br />

                                            <span style={{ textTransform: "capitalize" }}>{enquiry.filterGender}</span>
                                        </>
                                    }
                                </div>
                            </div>*/}


                            <div class="modal fade" id="istatus" tabindex="-1" role="dialog" aria-labelledby="exampleistatus" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleistatus">Update {t('interview_status')}</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className="row">
                                                <div class="col-12 col-sm-12 col-xl-12">
                                                    <label class="form-label">{t('interview_status')}</label>
                                                    <select class="form-control" id="interview_status">
                                                        <option value={"not_attend"}>{t('not_attend')}</option>
                                                        <option value={"candidate_cancelled"}>{t('candidate_cancelled')}</option>
                                                        <option value={"rejected"}>{t('rejected')}</option>
                                                        <option value={"selected"}>{t('selected')}</option>
                                                        <option value={"other"}>{t('other')}</option>
                                                    </select>
                                                </div>

                                                <div class="col-12 col-sm-12 col-xl-12 mt-3">
                                                    <label class="form-label">Note</label>
                                                    <textarea class="form-control" id="note" style={{ height: 150 }}></textarea>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary" onClick={() => _saveStatus()}>Save changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelDefault" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabelDefault">{t('schedule_interview')}</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className="row">
                                                <div class="col-12 col-sm-12 col-xl-12">
                                                    <label class="form-label">{t('date')}</label>
                                                    <input type="datetime-local" class="form-control" id="datePickerBasic" />
                                                </div>

                                                <div class="col-12 col-sm-12 col-xl-12">
                                                    <label class="form-label">Link</label>
                                                    <textarea class="form-control" id="link" style={{ height: 150 }}></textarea>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary" onClick={() => _schedule()}>{t("schedule")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="semiFullExample" tabindex="-1" role="dialog" aria-hidden="true">
                                <div class="modal-dialog modal-semi-full modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Notes</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="data-table-rows slim">
                                                <div class="data-table-responsive-wrapper">
                                                    <table id="datatableRows" class="data-table nowrap hover">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Status</th>
                                                                <th>Note</th>
                                                                <th>Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                notes.map((n, k) =>
                                                                    <tr>
                                                                        <td>{k + 1}</td>
                                                                        <td>{t(n.interview_status)}</td>
                                                                        <td>{n.interview_note}</td>
                                                                        <td>{n.createdAt}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*<ul class="nav nav-tabs nav-tabs-title nav-tabs-line-title responsive-tabs" id="lineTitleTabsContainer" role="tablist" style={{ marginTop: '5rem' }}>
                                <li class="nav-item" role="presentation">
                                    <a class={step == 0 ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#firstLineTitleTab" role="tab" aria-selected="true">Enquiry Results</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class={step == 1 ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#secondLineTitleTab" role="tab" aria-selected="false">Interested Candidates</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class={step == 2 ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#thirdLineTitleTab" role="tab" aria-selected="false">Interview Scheduled</a>
                                </li>
                                <li class="nav-item dropdown ms-auto pe-0 d-none responsive-tab-dropdown">
                                    <a class="btn btn-icon btn-icon-only btn-background pt-0 bg-transparent pe-0" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-more-horizontal undefined"><path d="M9 10C9 9.44772 9.44772 9 10 9V9C10.5523 9 11 9.44772 11 10V10C11 10.5523 10.5523 11 10 11V11C9.44772 11 9 10.5523 9 10V10zM2 10C2 9.44772 2.44772 9 3 9V9C3.55228 9 4 9.44772 4 10V10C4 10.5523 3.55228 11 3 11V11C2.44772 11 2 10.5523 2 10V10zM16 10C16 9.44772 16.4477 9 17 9V9C17.5523 9 18 9.44772 18 10V10C18 10.5523 17.5523 11 17 11V11C16.4477 11 16 10.5523 16 10V10z"></path></svg>
                                    </a>
                                    <ul class="dropdown-menu mt-2 dropdown-menu-end"></ul>
                                </li>
                        </ul>*/}
                            <div className="row">
                                <div className="col-md-12 ms-5">
                                    <div class="d-flex flex-row bd-highlight mb-3">
                                        <div class="p-2 bd-highlight me-5" onClick={() => setStep(0)}>
                                            <aside className={step == 0 ? 'arrow' : 'arrow_in'}>{t('enquiry_details')}</aside>
                                        </div>
                                        <div class="p-2 bd-highlight me-5" onClick={() => setStep(1)}>
                                            <aside className={step == 1 ? 'arrow' : 'arrow_in'}>{t('interested_candidates')}</aside>
                                        </div>
                                        <div class="p-2 bd-highlight me-5" onClick={() => setStep(2)}>
                                            <aside className={step == 2 ? 'arrow' : 'arrow_in'}>{t('interview_scheduled_candidates')}</aside>
                                        </div>
                                        <div class="p-2 bd-highlight me-5" onClick={() => setStep(3)}>
                                            <aside className={step == 3 ? 'arrow' : 'arrow_in'}>{t('selected')}</aside>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="mb-5">
                                <div class="card-body p-0 pt-5">
                                    <div class="tab-content">

                                        <div class={step == 0 ? "tab-pane show active" : "tab-pane hide"} id="firstLineTitleTab" role="tabpanel">
                                            <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4 g-4 mb-5">
                                                {
                                                    result.map((r, k) =>
                                                        <div class="col">
                                                            <div class="card h-100">
                                                                <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                                                                    <img src={r.candidate_image} class="card-img-top sh-30" alt="card image" style={{ objectFit: 'contain' }} />
                                                                </a>
                                                                <div class="card-body">
                                                                    <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>HNC{r.id}</a>
                                                                    <div class="text-muted mb-1">{candidateTypesAll[r.candidate_degree]}</div>

                                                                    <div class="row g-0 align-items-center mb-2">
                                                                        <div class="col pt-4">
                                                                            <div class="d-flex align-items-center lh-1-25">{languageLevels[r.candidate_language_level]}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row g-0 align-items-center mb-2">

                                                                        <div class="col">
                                                                            <div class="d-flex align-items-center lh-1-25">{nc[r.candidate_nursing_course]}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row g-0 align-items-center mb-2">

                                                                        <div class="col">
                                                                            <div class="d-flex align-items-center lh-1-25">{r.candidate_dob}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row g-0 align-items-center mb-2 mt-4">
                                                                        <div className="col-sm-12">
                                                                            <button type="button" class="mb-3 w-100 btn btn-sm btn-outline-primary btn-icon btn-icon-start me-2" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                                                                                <span>{t("view_details")}</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                            {
                                                                                (r.candidate_type == undefined || r.candidate_type == 0 || r.candidate_type == null) &&
                                                                                <button class="w-100 btn btn-sm btn-icon-start btn-outline-quaternary mb-1" type="button" onClick={() => _markAsInterest(r._id)}>
                                                                                    <span>Mark As Interest</span>
                                                                                </button>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                }


                                            </div>
                                        </div>
                                        <div class={step == 1 ? "tab-pane show active" : "tab-pane hide"} id="secondLineTitleTab" role="tabpanel">
                                            <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4 g-4 mb-5">
                                                {
                                                    result.map((r, k) =>
                                                        <>
                                                            {
                                                                r.candidate_type == 1 ?
                                                                    <div class="col">
                                                                        <div class="card h-100">
                                                                            <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                                                                                <img src={r.candidate_image} class="card-img-top sh-30" alt="card image" style={{ objectFit: 'contain' }} />

                                                                            </a>
                                                                            <div class="card-body">
                                                                                <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>HNC{r.id}</a>
                                                                                <div class="text-muted mb-1">{candidateTypesAll[r.candidate_degree]}</div>

                                                                                <div class="row g-0 align-items-center mb-2">
                                                                                    <div class="col pt-4">
                                                                                        <div class="d-flex align-items-center lh-1-25">{languageLevels[r.candidate_language_level]}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row g-0 align-items-center mb-2">

                                                                                    <div class="col">
                                                                                        <div class="d-flex align-items-center lh-1-25">{nc[r.candidate_nursing_course]}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row g-0 align-items-center mb-2">
                                                                                    <div class="col">
                                                                                        <div class="d-flex align-items-center lh-1-25">{r.candidate_dob}</div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="row g-0 align-items-center mb-2 mt-4">
                                                                                    <div className="col-12">
                                                                                        <button type="button" class="mb-3 w-100 btn btn-sm btn-outline-primary btn-icon btn-icon-start me-2" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                                                                                            <span>{t("view_details")}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        {/*
                                                                                            (r.candidate_type == 1) &&
                                                                                            <button class="btn btn-sm btn-icon-start btn-outline-quaternary me-2" type="button" onClick={() => _removeAsInterest(r._id)}>
                                                                                                <span>Remove</span>
                                                                                            </button>
                                                            */}
                                                                                    </div>
                                                                                    {
                                                                                        r.candidate_date == undefined &&
                                                                                        <div className="col-12">
                                                                                            <button type="button" class="w-100 btn btn-sm btn-outline-primary btn-icon btn-icon-start me-2" onClick={() => _scheduleModal(r._id)}>
                                                                                                <span>{t("schedule")}</span>
                                                                                            </button>
                                                                                        </div>
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="col"></div>
                                                            }
                                                        </>

                                                    )
                                                }


                                            </div>
                                        </div>
                                        <div class={step == 2 ? "tab-pane show active" : "tab-pane hide"} id="thirdLineTitleTab" role="tabpanel">
                                            <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4 g-4 mb-5">
                                                {
                                                    result.map((r, k) =>
                                                        <>
                                                            {
                                                                r.candidate_date != undefined ?
                                                                    <div class="col">
                                                                        <div class="card h-100">
                                                                            <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                                                                                <img src={r.candidate_image} class="card-img-top sh-30" alt="card image" style={{ objectFit: 'contain' }} />

                                                                                {
                                                                                    r.candidate_interview_status != undefined && r.candidate_interview_status != "" &&
                                                                                    <aside class='sheer'>
                                                                                        {r.candidate_interview_status}
                                                                                    </aside>
                                                                                }


                                                                            </a>
                                                                            <div class="card-body">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>HNC{r.id}</a>
                                                                                    </div>
                                                                                    <div className="col-md-6 text-end">
                                                                                        <a href="#" onClick={() => _viewNotes(r._id)}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-message d-inline-block text-primary me-2"><path d="M14.5 2C15.9045 2 16.6067 2 17.1111 2.33706C17.3295 2.48298 17.517 2.67048 17.6629 2.88886C18 3.39331 18 4.09554 18 5.5L18 10.5C18 11.9045 18 12.6067 17.6629 13.1111C17.517 13.3295 17.3295 13.517 17.1111 13.6629C16.6067 14 15.9045 14 14.5 14L10.4497 14C9.83775 14 9.53176 14 9.24786 14.0861C9.12249 14.1241 9.00117 14.1744 8.88563 14.2362C8.62399 14.376 8.40762 14.5924 7.97487 15.0251L5.74686 17.2531C5.47773 17.5223 5.34317 17.6568 5.2255 17.6452C5.17629 17.6404 5.12962 17.6211 5.0914 17.5897C5 17.5147 5 17.3244 5 16.9438L5 14.6C5 14.5071 5 14.4606 4.99384 14.4218C4.95996 14.2078 4.79216 14.04 4.57822 14.0062C4.53935 14 4.4929 14 4.4 14V14C4.0284 14 3.8426 14 3.68713 13.9754C2.83135 13.8398 2.16017 13.1687 2.02462 12.3129C2 12.1574 2 11.9716 2 11.6L2 5.5C2 4.09554 2 3.39331 2.33706 2.88886C2.48298 2.67048 2.67048 2.48298 2.88886 2.33706C3.39331 2 4.09554 2 5.5 2L14.5 2Z"></path></svg>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="text-muted mb-1">{candidateTypesAll[r.candidate_degree]}</div>

                                                                                <div class="row g-0 align-items-center mb-2">
                                                                                    <div class="col pt-4">
                                                                                        <div class="d-flex align-items-center lh-1-25">{languageLevels[r.candidate_language_level]}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row g-0 align-items-center mb-2">

                                                                                    <div class="col">
                                                                                        <div class="d-flex align-items-center lh-1-25">{nc[r.candidate_nursing_course]}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row g-0 align-items-center mb-2">
                                                                                    <div class="col">
                                                                                        <div class="d-flex align-items-center lh-1-25">{r.candidate_dob}</div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="text-muted mb-1 mt-4">{t('interview_date')}</div>
                                                                                <div class="mb-1">{r.candidate_date}</div>

                                                                                <div class="text-muted mb-1">{t('interview_url')}</div>
                                                                                <div class="mb-1">{r.candidate_url}</div>

                                                                                <div class="row g-0 align-items-center mb-2 mt-4">
                                                                                    <div className="col-sm-12 text-center">
                                                                                        <button type="button" class="mb-3 w-100 btn btn-sm btn-outline-primary btn-icon btn-icon-start me-2" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                                                                                            <span>{t("view_details")}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    {
                                                                                        r.candidate_interview_status != "selected" &&
                                                                                        <div className="col-sm-12 text-center">
                                                                                            <button type="button" class="w-100 btn btn-sm btn-outline-primary btn-icon btn-icon-start me-2" onClick={() => _scheduleModal(r._id)}>
                                                                                                <span>{t('re_schedule')}</span>
                                                                                            </button>
                                                                                        </div>
                                                                                    }

                                                                                </div>
                                                                                {
                                                                                    r.candidate_interview_status != "selected" &&
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12 text-center mt-5">
                                                                                            <button type="button" class="btn btn-foreground hover-outline mb-1" onClick={() => _istatus(r._id)}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-message d-inline-block text-primary me-2"><path d="M14.5 2C15.9045 2 16.6067 2 17.1111 2.33706C17.3295 2.48298 17.517 2.67048 17.6629 2.88886C18 3.39331 18 4.09554 18 5.5L18 10.5C18 11.9045 18 12.6067 17.6629 13.1111C17.517 13.3295 17.3295 13.517 17.1111 13.6629C16.6067 14 15.9045 14 14.5 14L10.4497 14C9.83775 14 9.53176 14 9.24786 14.0861C9.12249 14.1241 9.00117 14.1744 8.88563 14.2362C8.62399 14.376 8.40762 14.5924 7.97487 15.0251L5.74686 17.2531C5.47773 17.5223 5.34317 17.6568 5.2255 17.6452C5.17629 17.6404 5.12962 17.6211 5.0914 17.5897C5 17.5147 5 17.3244 5 16.9438L5 14.6C5 14.5071 5 14.4606 4.99384 14.4218C4.95996 14.2078 4.79216 14.04 4.57822 14.0062C4.53935 14 4.4929 14 4.4 14V14C4.0284 14 3.8426 14 3.68713 13.9754C2.83135 13.8398 2.16017 13.1687 2.02462 12.3129C2 12.1574 2 11.9716 2 11.6L2 5.5C2 4.09554 2 3.39331 2.33706 2.88886C2.48298 2.67048 2.67048 2.48298 2.88886 2.33706C3.39331 2 4.09554 2 5.5 2L14.5 2Z"></path></svg>
                                                                                                {t('update_interview_status')}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="col"></div>
                                                            }
                                                        </>

                                                    )
                                                }


                                            </div>
                                        </div>

                                        <div class={step == 3 ? "tab-pane show active" : "tab-pane hide"} id="thirdLineTitleTab" role="tabpanel">
                                            <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4 g-4 mb-5">
                                                {
                                                    result.map((r, k) =>
                                                        <>
                                                            {
                                                                r.candidate_interview_status == 'selected' ?
                                                                    <div class="col">
                                                                        <div class="card h-100">
                                                                            <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                                                                                <img src={r.candidate_image} class="card-img-top sh-30" alt="card image" style={{ objectFit: 'contain' }} />




                                                                            </a>
                                                                            <div class="card-body">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <a href="#" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>HNC{r.id}</a>
                                                                                    </div>
                                                                                    <div className="col-md-6 text-end">
                                                                                        <a href="#" onClick={() => _viewNotes(r._id)}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-message d-inline-block text-primary me-2"><path d="M14.5 2C15.9045 2 16.6067 2 17.1111 2.33706C17.3295 2.48298 17.517 2.67048 17.6629 2.88886C18 3.39331 18 4.09554 18 5.5L18 10.5C18 11.9045 18 12.6067 17.6629 13.1111C17.517 13.3295 17.3295 13.517 17.1111 13.6629C16.6067 14 15.9045 14 14.5 14L10.4497 14C9.83775 14 9.53176 14 9.24786 14.0861C9.12249 14.1241 9.00117 14.1744 8.88563 14.2362C8.62399 14.376 8.40762 14.5924 7.97487 15.0251L5.74686 17.2531C5.47773 17.5223 5.34317 17.6568 5.2255 17.6452C5.17629 17.6404 5.12962 17.6211 5.0914 17.5897C5 17.5147 5 17.3244 5 16.9438L5 14.6C5 14.5071 5 14.4606 4.99384 14.4218C4.95996 14.2078 4.79216 14.04 4.57822 14.0062C4.53935 14 4.4929 14 4.4 14V14C4.0284 14 3.8426 14 3.68713 13.9754C2.83135 13.8398 2.16017 13.1687 2.02462 12.3129C2 12.1574 2 11.9716 2 11.6L2 5.5C2 4.09554 2 3.39331 2.33706 2.88886C2.48298 2.67048 2.67048 2.48298 2.88886 2.33706C3.39331 2 4.09554 2 5.5 2L14.5 2Z"></path></svg>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="text-muted mb-1">{candidateTypesAll[r.candidate_degree]}</div>

                                                                                <div class="row g-0 align-items-center mb-2">
                                                                                    <div class="col pt-4">
                                                                                        <div class="d-flex align-items-center lh-1-25">{languageLevels[r.candidate_language_level]}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row g-0 align-items-center mb-2">

                                                                                    <div class="col">
                                                                                        <div class="d-flex align-items-center lh-1-25">{nc[r.candidate_nursing_course]}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row g-0 align-items-center mb-2">
                                                                                    <div class="col">
                                                                                        <div class="d-flex align-items-center lh-1-25">{r.candidate_dob}</div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="text-muted mb-1 mt-4">{t('interview_date')}</div>
                                                                                <div class="mb-1">{r.candidate_date}</div>

                                                                                <div class="text-muted mb-1">{t('interview_url')}</div>
                                                                                <div class="mb-1">{r.candidate_url}</div>

                                                                                <div class="row g-0 align-items-center mb-2 mt-4">
                                                                                    <div className="col text-center">
                                                                                        <button type="button" class="w-100 btn btn-sm btn-outline-primary btn-icon btn-icon-start me-2" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>
                                                                                            <span>{t("view_details")}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="col"></div>
                                                            }
                                                        </>

                                                    )
                                                }


                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            {/*<h3 className="mt-5 me-5">{t("results")}</h3>
                            <div class="card mb-5">
                                <div class="card-body">
                                    <table className="table">
                                        <thead>
                                            <th>#</th>
                                            <th>Type</th>
                                            <th>DOB</th>
                                            <th>Gender</th>
                                            <th>Experience</th>
                                            <th>Family Status</th>
                                            <th>Language Level</th>
                                            <th>Nursing Course</th>
                                            <th>Actions</th>
                                        </thead>
                                        <tbody>
                                            {
                                                result.map((r, k) =>
                                                    <tr>
                                                        <td>{k + 1}</td>
                                                        <td>{candidateTypesAll[r.candidate_degree]}</td>
                                                        <td>{r.candidate_dob}</td>
                                                        <td>{gender[r.candidate_gender]}</td>
                                                        <td>{r.candidate_experience}</td>
                                                        <td>{family_status[r.candidate_family_status]}</td>
                                                        <td>{languageLevels[r.candidate_language_level]}</td>
                                                        <td>{nc[r.candidate_nursing_course]}</td>
                                                        <td><td><button type="button" class="btn btn-primary btn-sm mb-1" onClick={() => window.location = "/employer/enquiry/candidate/" + r._id}>{t("view_details")}</button></td></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                        </div>*/}
                        </div>




                    </div>
                </div>
            </main>
        </>
    )
}

export default withParams(Details);
