import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader";
import { get, post, put, deleteM } from "../../utility/Adapter";
import { withParams } from "../HOC";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

const SelectedDetails = (props) => {
    const [loading, setLoading] = useState(false);
    const [candidates, setCandidates] = useState([]);
    const [notes, setNotes] = useState([]);
    const [employer, setEmployer] = useState([]);

    useEffect(function () {
        _loadDetails(1);
    }, []);

    var status = {
        selected: "Selected",
        not_attend: "Not Attend",
        candidate_cancelled : "Candidate Cancelled",
        rejected: "Rejected",
        other: "Other"
    };

    const _loadDetails = () => {
        setLoading(true);
        get("admin/employer/selected/" + props.params.employer_id + "/" + props.params.candidate_id)
            .then(async function (response) {
                setCandidates(response.data.candidate);
                setNotes(response.data.data);
                setEmployer(response.data.employer);
                setLoading(false);

                var table = window.$('#datatableRows').DataTable();
                table.destroy();

                table = window.$('#CandidateRows').DataTable();
                table.destroy();

                table = window.$('#EmployerRows').DataTable();
                table.destroy();

                setTimeout(function () {

                    var uninitialized = window.$(['#datatableRows', '#CandidateRows', '#EmployerRows']).filter(function () {
                        return !window.$.fn.DataTable.fnIsDataTable(this);
                    });

                    uninitialized.each(function () {
                        window.$(this).dataTable(
                            {
                                dom: 'Bfrtip',
                                buttons: [
                                    {
                                        extend: 'excel',
                                        text: 'Export Excel',
                                        className: 'btn btn-light',
                                        exportOptions: {
                                            columns: 'th:not(:last-child)'
                                        }
                                    },
                                    {
                                        extend: 'csv',
                                        text: 'Export Csv',
                                        className: 'btn btn-light',
                                        exportOptions: {
                                            columns: 'th:not(:last-child)'
                                        }
                                    },

                                ]
                            }
                        );
                    });
                }, 100);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    return (
        <>
            <Header />
            <Loader loading={loading} />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        <SideBar />
                        <div class="col">
                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">
                                                Selected Candidate Details
                                            </h1>
                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>
                                </div>
                            </div>

                            <h3 className="mt-5">Employer Notes</h3>
                            <div class="data-table-rows slim">
                                <div class="data-table-responsive-wrapper">
                                    <table id="datatableRows" class="data-table nowrap hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Note</th>
                                                <th scope="col">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                notes.map((n, k) => 
                                                    <tr key={k}>
                                                        <td>{k+1}</td>
                                                        <td>{status[n.interview_status]}</td>
                                                        <td>{n.interview_note}</td>
                                                        <td>{n.createdAt}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <h3 className="mt-5">Candidate Details</h3>
                            <div class="data-table-rows slim">
                                <div class="data-table-responsive-wrapper">
                                    <table id="CandidateRows" class="data-table nowrap hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Id</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Mobile</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                        <tr>
                                            <td>1</td>
                                            <td>HNC{candidates.id}</td>
                                            <td class="text-capitalize">
                                                <a href={"/candidate/" + candidates.candidate_id}>
                                                {candidates.candidate_first_name + " " + candidates.candidate_last_name}
                                                </a>
                                                </td>

                                            <td>{candidates.candidate_email}</td>
                                            <td>{candidates.candidate_phone}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <h3 className="mt-5">Employer Details</h3>
                            <div class="data-table-rows slim">
                                <div class="data-table-responsive-wrapper">
                                    <table id="EmployerRows" class="data-table nowrap hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Agency</th>
                                                <th scope="col">Mobile</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                        <tr>
                                            <td>1</td>
                                            <td class="text-capitalize">{employer.employer_name}</td>
                                            <td class="text-capitalize">{employer.employer_agency}</td>
                                            <td>{employer.employer_phone}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default withParams(SelectedDetails);