import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import { get, post, put, deleteM } from "../../utility/Adapter";
import DataTable from "react-data-table-component";
import { withParams } from "../HOC";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

var moment = require('moment');

const Campaigns = () => {

    const [candidates, setCandidates] = useState([]);

    useEffect(function () {
        _loadCandidates(1);
    }, []);

    const _loadCandidates = (page) => {
        get("admin/candidates/campaigns/" + page)
            .then(async function (response) {
                setCandidates(response.data.data);
                //setTotalRows(response.data.totalRows);
                //setPaginationPerPage(response.data.paginationPerPage);
                //setPending(false);

                setTimeout(function () {
                    window.$("#datatableRows").dataTable(
                      {
                        dom: 'Bfrtip',
                        buttons: [
                          {
                            extend: 'excel',
                            text: 'Export Excel',
                            className: 'btn btn-light',
                            exportOptions: {
                              columns: 'th:not(:last-child)'
                            }
                          },
                          {
                            extend: 'csv',
                            text: 'Export Csv',
                            className: 'btn btn-light',
                            exportOptions: {
                              columns: 'th:not(:last-child)'
                            }
                          },
                          
                        ]
                      }
                    );
                  }, 100)
            })
            .catch(function (error) {
                console.log(error);
            });
    };


    return (
        <>
            <Header />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        <div class="col">
                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">
                                                Campaigns Candidates
                                            </h1>
                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>
                                </div>
                            </div>

                            <div class="data-table-rows slim">
                                <div class="data-table-responsive-wrapper">
                                <table id="datatableRows" class="data-table nowrap hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">City</th>
                                                <th scope="col">Submitted</th>
                                                <th scope="col">Documents File </th>
                                                <th scope="col">Documents File </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {candidates.map((c, k) => (
                                                <tr>
                                                    <th scope="row">{k + 1}</th>
                                                    <td>
                                                        {c.candidate_name}
                                                    </td>
                                                    <td>{c.candidate_phone}</td>
                                                    <td>{c.candidate_email}</td>
                                                    <td>{c.candidate_city}</td>
                                                    <td>{moment(c.createdAt).format('lll')}</td>
                                                    <td><a target="_blank" href={process.env.REACT_APP_BASE_URL+'documents/'+c.candidate_file1}>{c.candidate_file1}</a></td>
                                                    <td><a target="_blank" href={process.env.REACT_APP_BASE_URL+'documents/'+c.candidate_file1}>{c.candidate_file2}</a></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Campaigns;