import React, { useState, useHook, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';

const Logout = () => {
    useEffect(() => {
        Cookies.remove('hcAffiliator');
        window.location = 'https://hireandcare.de/affiliator';
    }, []);

    return (
        <>
            Please wait...
        </>
    )
}

export default Logout;