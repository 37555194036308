import React, { useState, useHook, useEffect, useRef } from 'react';
import { get, post, put, deleteM } from "../pages/translator/utility/Adapter";
import { withParams } from "../pages/HOC";
import Cookies from 'js-cookie';

const User = (props) => {

    const [u, setU] = useState({
        name: null
    });

    useEffect(function () {
        if (props.employer == true) {
            getEmployerData();
        }
        else {
            getData();
        }
        
    }, []);

    const getEmployerData = () => {
        get('employer/profile')
            .then(async function (response) {
                setU(response.data.data);
            }).catch(function (error) {
                window.location = '/employer/login';
        });
    }

    const getData = () => {
        try {
            const value = Cookies.get('hcTransilator');
            const translatorData = Cookies.get('hcTransilatorData');

            if (value !== undefined && translatorData == undefined) {
                get('translators/profile')
                    .then(async function (response) {
                        setU(response.data.data);
                        Cookies.set('hcTransilatorData', JSON.stringify(response.data.data), { expires: 7 });
                    }).catch(function (error) {
                        window.location = '/translator/login';
                    });
            }
            else {
                setU(JSON.parse(translatorData));
            }
        } catch (e) {
            // error reading value
        }
    }

    return (
        <>
            {
                props.show_name == true &&
                <span className='text-capitalize'>
                    {u.name}
                </span>
            }
        </>
    )
}

export default withParams(User);