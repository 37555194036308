import React, { useState, useHook, useEffect, useRef } from "react";
import logo from '../../images/logo.svg';
import avatar from '../../images/avatar.jpg';
import Loader from '../Loader';
import { get, post, put, deleteM } from "./Adapter";
var moment = require('moment');

const AffiliatorCandidates = () => {


    const [affliator, setAffliator] = useState({});
    const [candidates, setCandidates] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(function () {
        _loadCandidates();
        _loadProfile();
    }, []);

    const _loadProfile = () => {
        setLoading(true);

        get('affiliators/dashboard/profile').then(async function (response) {
            setAffliator(response.data.data);
            setLoading(false);

        });
    }

    const _loadCandidates = () => {
        setLoading(true);
        get('affiliators/dashboard/candidates').then(async function (response) {
            setCandidates(response.data.data);
            setLoading(false);

            var table = window.$('#datatableRows').DataTable();
            table.destroy();

            setTimeout(function () {

                var uninitialized = window.$('#datatableRows').filter(function () {
                    return !window.$.fn.DataTable.fnIsDataTable(this);
                });

                uninitialized.each(function () {
                    window.$(this).dataTable(
                        {
                            dom: 'Bfrtip',
                            buttons: [
                                {
                                    extend: 'excel',
                                    text: 'Export Excel',
                                    className: 'btn btn-light',
                                    exportOptions: {
                                        columns: 'th:not(:last-child)'
                                    }
                                },
                                {
                                    extend: 'csv',
                                    text: 'Export Csv',
                                    className: 'btn btn-light',
                                    exportOptions: {
                                        columns: 'th:not(:last-child)'
                                    }
                                },

                            ]
                        }
                    );
                });
            }, 100);

        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    }


    return (
        <>
            <Loader loading={loading} />
            <div id="nav" class="nav-container d-flex">
                <div class="nav-content d-flex">
                    <div class="logo position-relative" style={{ width: 200 }}>
                        <div class="img" style={{ width: 200 }}>
                            <img src={logo} alt="logo" style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div class="user-container d-flex">
                        <a href="#" class="d-flex user position-relative" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img class="profile" alt="profile" src={avatar} />
                            <div class="name text-capitalize">{affliator.affiliator_name}</div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end user-menu wide">

                            <div class="row mb-1 ms-0 me-0">
                                <div class="col-12 pe-1 ps-1">
                                    <ul class="list-unstyled">
                                        <li>
                                            <a href="/affiliator/logout">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-logout me-2"><path d="M7 15 2.35355 10.3536C2.15829 10.1583 2.15829 9.84171 2.35355 9.64645L7 5M3 10H13M12 18 14.5 18C15.9045 18 16.6067 18 17.1111 17.6629 17.3295 17.517 17.517 17.3295 17.6629 17.1111 18 16.6067 18 15.9045 18 14.5L18 5.5C18 4.09554 18 3.39331 17.6629 2.88886 17.517 2.67048 17.3295 2.48298 17.1111 2.33706 16.6067 2 15.9045 2 14.5 2L12 2"></path></svg>
                                                <span class="align-middle">Logout</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="menu-container flex-grow-1">

                        <ul id="menu" class="menu show">
                            <li class="dropdown">
                                <a href="#" data-href="Dashboards.html" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-home icon"><path d="M4 10V14.5C4 15.9045 4 16.6067 4.33706 17.1111C4.48298 17.3295 4.67048 17.517 4.88886 17.6629C5.39331 18 6.09554 18 7.5 18H12.5C13.9045 18 14.6067 18 15.1111 17.6629C15.3295 17.517 15.517 17.3295 15.6629 17.1111C16 16.6067 16 15.9045 16 14.5V10"></path><path d="M12 18V13.875C12 13.5239 12 13.3483 11.9157 13.2222C11.8793 13.1676 11.8324 13.1207 11.7778 13.0843C11.6517 13 11.4761 13 11.125 13H8.875C8.52388 13 8.34833 13 8.22221 13.0843C8.16762 13.1207 8.12074 13.1676 8.08427 13.2222C8 13.3483 8 13.5239 8 13.875V18"></path><path d="M2 9.5L3.30715 4.59818C3.59125 3.53283 3.73329 3.00016 4.07581 2.63391C4.22529 2.47406 4.4 2.33984 4.59297 2.23658C5.03511 2 5.5864 2 6.68897 2H13.311C14.4136 2 14.9649 2 15.407 2.23658C15.6 2.33984 15.7747 2.47406 15.9242 2.63391C16.2667 3.00016 16.4088 3.53283 16.6928 4.59818L18 9.5"></path><path d="M2 9.5H5.39023C6.0676 9.5 6.40629 9.5 6.71555 9.39638C6.85193 9.35068 6.98299 9.2904 7.10644 9.21659C7.38638 9.04922 7.6068 8.79207 8.04763 8.27777L9.33565 6.77507C9.59815 6.46882 9.7294 6.3157 9.89306 6.27987C9.96352 6.26444 10.0365 6.26444 10.1069 6.27987C10.2706 6.3157 10.4018 6.46882 10.6643 6.77507L11.9524 8.27777C12.3932 8.79207 12.6136 9.04922 12.8936 9.21659C13.017 9.2904 13.1481 9.35068 13.2844 9.39638C13.5937 9.5 13.9324 9.5 14.6098 9.5H18"></path></svg>
                                    <span class="label">Candidates</span>
                                </a>
                                <ul id="dashboards" class="dropdown-menu opacityIn">
                                    <li>
                                        <a href="/affiliator/candidates" class="">
                                            <span class="label">All</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown">
                                <a href="#" data-href="Apps.html" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-screen icon"><path d="M10 15V16V18M8 18H12"></path><path d="M14.5 2C15.9045 2 16.6067 2 17.1111 2.33706C17.3295 2.48298 17.517 2.67048 17.6629 2.88886C18 3.39331 18 4.09554 18 5.5L18 11.5C18 12.9045 18 13.6067 17.6629 14.1111C17.517 14.3295 17.3295 14.517 17.1111 14.6629C16.6067 15 15.9045 15 14.5 15L5.5 15C4.09554 15 3.39331 15 2.88886 14.6629C2.67048 14.517 2.48298 14.3295 2.33706 14.1111C2 13.6067 2 12.9045 2 11.5L2 5.5C2 4.09554 2 3.39331 2.33706 2.88886C2.48298 2.67048 2.67048 2.48298 2.88886 2.33706C3.39331 2 4.09554 2 5.5 2L14.5 2Z"></path><path d="M9 7 7 10M13.2412 7 11.2412 10"></path></svg>
                                    <span class="label">Settings</span>
                                </a>
                                <ul id="apps" class="dropdown-menu opacityIn">
                                    <li>
                                        <a href="/affiliator/registration-url">
                                            <span class="label">Registration Url</span>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="/affiliator/profile">
                                            <span class="label">My Account</span>
                                        </a>
                                    </li>

                                </ul>
                            </li>
                        </ul></div>


                    <div class="mobile-buttons-container">
                        <a href="#" id="scrollSpyButton" class="spy-button" data-bs-toggle="dropdown">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-menu-dropdown undefined"><path d="M2 3 18 3M2 10 18 10M13 15 10.3536 17.6464C10.1583 17.8417 9.84171 17.8417 9.64645 17.6464L7 15"></path></svg>
                        </a>

                        <div class="dropdown-menu dropdown-menu-end" id="scrollSpyDropdown"></div>

                        <a href="#" id="mobileMenuButton" class="menu-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-menu undefined"><path d="M2 3 18 3M2 10 18 10M2 17 18 17"></path></svg>
                        </a>
                    </div>
                </div>
                <div class="nav-shadow"></div>
            </div>

            <main>
                <div className='container'>
                    <div class="page-title-container">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <h1 class="mb-0 pb-0 display-4" id="title">All Candidates</h1>
                                <nav class="breadcrumb-container d-inline-block" aria-label="breadcrumb">
                                    <ul class="breadcrumb pt-0">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Candidates</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div>
                        <section class="scroll-section" id="basic">
                            <div class="card mb-5">
                                <div class="card-body">
                                    <table class="table" id="datatableRows">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">HNC ID</th>
                                                <th scope="col">Comments</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                candidates.map((c, k) =>
                                                    <tr>
                                                        <th scope="row">{k + 1}</th>
                                                        <td>HNC{c.id}</td>
                                                        <td className="text-capitalize">{c.candidate_affiliator_note}</td>
                                                        <td className="text-capitalize">{c.candidate_affiliator_status}</td>
                                                        <td>{moment(c.createdAt).format('lll')}</td>
                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>
                    </div>

                </div>
            </main>

            <footer>
                <div class="footer-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <p class="mb-0 text-muted text-medium">&copy; hireandcare 2024</p>
                            </div>
                            <div class="col-sm-6 d-none d-sm-block">

                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default AffiliatorCandidates;