import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader";
import { get, post, put, deleteM } from "../../utility/Adapter";
import { withParams } from "../HOC";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

const Rejected = () => {
    const [loading, setLoading] = useState(false);
    const [candidates, setCandidates] = useState([]);

    useEffect(function () {
        _loadCandidates(1);
    }, []);

    const _loadCandidates = (page) => {
        setLoading(true);
        get("admin/employer/rejected/" + page)
            .then(async function (response) {
                setCandidates(response.data.data);
                setLoading(false);

                var table = window.$('#datatableRows').DataTable();
                table.destroy();

                setTimeout(function () {

                    var uninitialized = window.$('#datatableRows').filter(function () {
                        return !window.$.fn.DataTable.fnIsDataTable(this);
                    });

                    uninitialized.each(function () {
                        window.$(this).dataTable(
                            {
                                dom: 'Bfrtip',
                                buttons: [
                                    {
                                        extend: 'excel',
                                        text: 'Export Excel',
                                        className: 'btn btn-light',
                                        exportOptions: {
                                            columns: 'th:not(:last-child)'
                                        }
                                    },
                                    {
                                        extend: 'csv',
                                        text: 'Export Csv',
                                        className: 'btn btn-light',
                                        exportOptions: {
                                            columns: 'th:not(:last-child)'
                                        }
                                    },

                                ]
                            }
                        );
                    });
                }, 100);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    var documentStatus = {
        0: "Pending",
        1: "Submitted",
        2: "Waiting Proposal",
        3: "Waiting Payment",
        4: "Translation Progress",
        5: "Translation Under Review",
        6: "Completed",
    };

    var languageLevels = {
        NOT_ENTROLLED: "Not yet enrolled",
        A1_ENTROLLED: "A1 Enrolled",
        A1_COMPLETE: "A2 Complete",
        B1_COMPLETE: "B1 Complete",
        B2_COMPLETE: "B2 Complete",
        C1_COMPLETE: "C1 Complete",
        C2_COMPLETE: "C2 Complete",
    };

    var languageLevelsId = [
        { id: "NOT_ENTROLLED", val: "Not yet enrolled" },
        { id: "A1_ENTROLLED", val: "A1 Enrolled" },
        { id: "A1_COMPLETE", val: "A2 Complete" },
        { id: "B1_COMPLETE", val: "B1 Complete" },
        { id: "B2_COMPLETE", val: "B2 Complete" },
        { id: "C1_COMPLETE", val: "C1 Complete" },
        { id: "C2_COMPLETE", val: "C2 Complete" },
    ];

    
    

    return (
        <>
            <Header />
            <Loader loading={loading} />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        {/* Page Content Start */}
                        <div class="col">
                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">
                                                Rejected Candidates
                                            </h1>
                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>

                                </div>
                            </div>

                            <div class="data-table-rows slim">
                                <div class="data-table-responsive-wrapper">
                                    <table id="datatableRows" class="data-table nowrap hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Employer</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Nursing License</th>
                                                <th scope="col">Language Level</th>
                                                <th scope="col">Note</th>
                                                <th scope="col">Created</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {candidates.map((c, k) => (
                                                <tr>
                                                    <th scope="row">{k + 1}</th>
                                                    <td class="text-capitalize">{c._employer.employer_name}</td>
                                                    <td>
                                                        <a href={"/candidate/" + c.candidate_id}>
                                                            <span class="text-capitalize" id="contactName">
                                                                {c.candidate_first_name +
                                                                    " " +
                                                                    c.candidate_last_name}
                                                            </span>
                                                        </a>
                                                    </td>
                                                    <td>{c.candidate_phone}</td>
                                                    <td>{c.candidate_email}</td>
                                                    <td>{c.candidate_licence == "Y" ? "Yes" : "No"}</td>
                                                    <td>{languageLevels[c.candidate_language_level]}</td>
                                                    <td>{c._note}</td>
                                                    <td>{c.created}</td>
                                                    <td>
                                                        

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Rejected;