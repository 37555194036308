import React, { useState, useHook, useEffect, useRef } from 'react';
import { get, post, put, deleteM } from "../utility/Adapter";

import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import Loader from '../../Loader';

import { alert, notice, info, success, error } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

import Cookies from 'js-cookie';

const MyAccount = () => {


    const [profile, setProfile] = useState(
        {
            name: "",
            email: "",
            phone: "",
            website: ""
        }
    );

    const [loading, setLoading] = useState(false);

    useEffect(function () {
        _loadMyaccount();
    }, []);

    const _loadMyaccount = () => {
        setLoading(true);
        get('translators/profile').then(async function (response) {
            setProfile(response.data.data);
            setLoading(false);

            

        }).catch(function (error) {
            console.log(error);
        });
    }

    const _alert = (text) => {
        alert({
            title: "ERROR",
            text:
                Text,
            modules: new Map([
                [
                    Confirm,
                    {
                        confirm: true,
                        buttons: [
                            {
                                text: "Ok",
                                primary: true,
                                click: notice => {
                                    notice.close();
                                }
                            }
                        ]
                    }
                ]
            ])
        });
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const _save = () => {
        var name = document.getElementById('name').value;
        //var email = document.getElementById('email').value;
        var phone = document.getElementById('phone').value;
        var website = document.getElementById('website').value;

        if (name.length == 0) {
            _alert("Please enter name");
            return false;
        }
        else if (phone.length == 0) {
            _alert("Please enter phone");
            return false;
        }

        var raw = JSON.stringify({
            "name": name,
            "phone": phone,
            "website": website,
        });

        setLoading(true);
        put('translators/profile', raw).then(async function (response) {
            setLoading(false);
            Cookies.remove('hcTransilatorData');

            success({
                title: "SUCCESS",
                text:
                    "Successfully updated",
                modules: new Map([
                    [
                        Confirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Ok",
                                    primary: true,
                                    click: notice => {
                                        notice.close();
                                    }
                                }
                            ]
                        }
                    ]
                ])
            });
            


        }).catch(function (error) {
            setLoading(false);
            console.log(error);
        });
        
    }

    const _changePassword = () => {
        var current = document.getElementById('current_password').value;
        var password = document.getElementById('password').value;
        var confirm = document.getElementById('confirm_password').value;

        if (current.length == 0) {
            _alert("Please enter current password");
            return false;
        }
        else if (password.length == 0) {
            _alert("Please enter new password");
            return false;
        }
        else if (password != confirm) {
            _alert("Password and confirm password do not match");
            return false;
        }

        var raw = JSON.stringify({
            "current": current,
            "password": password,
            "confirm": confirm,
        });

        setLoading(true);
        put('translators/change', raw).then(async function (response) {
            setLoading(false);
            
            success({
                title: "SUCCESS",
                text:
                    "Successfully updated",
                modules: new Map([
                    [
                        Confirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Ok",
                                    primary: true,
                                    click: notice => {
                                        notice.close();
                                    }
                                }
                            ]
                        }
                    ]
                ])
            });
            
            setTimeout(function(){
                Cookies.remove('hcTransilator');
                Cookies.remove('hcTransilatorData');
                window.location = '/translator/login';
            }, 3000)

        }).catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }


    return (
        <>
            <Header />
            <Loader loading={loading} />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        {/* Page Content Start */}
                        <div class="col">
                            {/* Title and Top Buttons Start */}

                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">My Account</h1>
                                            <div class="text-muted font-heading text-small"></div>
                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>

                                    <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">

                                    </div>
                                </div>
                            </div>


                            <div className='card mt-5'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <p class="heading title mb-5">Profile Details</p>
                                        </div>
                                        <div className='col-sm-3 top-label'>
                                            <input type="text" id="name" className="form-control required" name="name" defaultValue={profile.name} />
                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Name</span>
                                        </div>
                                        <div className='col-sm-3 top-label'>
                                            <input type="text" id="email" className="form-control required" name="email" defaultValue={profile.email} readOnly={true}/>
                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Email</span>
                                        </div>
                                        <div className='col-sm-3 top-label'>
                                            <input type="text" id="phone" className="form-control required" name="phone" defaultValue={profile.phone} />
                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Phone</span>
                                        </div>
                                        <div className='col-sm-3 top-label'>
                                            <input type="text" id="website" className="form-control required" name="website" defaultValue={profile.website} />
                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Website</span>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <button type="button" class="btn btn-primary mb-1 mt-3" onClick={() => _save()}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='card mt-5'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <p class="heading title mb-5">Change Password</p>
                                        </div>
                                        <div className='col-sm-3 top-label'>
                                            <input type="password" id="current_password" className="form-control required" name="name"/>
                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Current Password</span>
                                        </div>
                                        <div className='col-sm-3 top-label'>
                                            <input type="password" id="password" className="form-control required" name="email"/>
                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>New Password</span>
                                        </div>
                                        <div className='col-sm-3 top-label'>
                                            <input type="password" id="confirm_password" className="form-control required" name="phone"/>
                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Confirm Password</span>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <button type="button" class="btn btn-primary mb-1 mt-3" onClick={() => _changePassword()}>Change Password</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default MyAccount;