import React, { useState, useHook, useEffect, useRef } from 'react';
import logo from '../../images/logo.svg';
import User from '../../components/User';
import avatar from '../../images/avatar.jpg';
import { withParams } from "../HOC";
import Cookies from 'js-cookie';


const Header = (props) => {

    const setLang = (lang) => {
        Cookies.set('_lang', lang);
        window.location = window.location;
    }

    return (
        <>
            <div id="nav" class="nav-container d-flex">
                <div class="nav-content d-flex">
                    {/* Logo Start */}
                    <div class="logo position-relative">
                        <a href="/">
                            {/* Logo can be added directly */}
                            <div class="img" style={{backgroundImage: `url(${logo})`, width: 300,minHeight: 52, marginTop:'-3px'}}></div>

                            {/* Or added via css to provide different ones for different color themes */}
                            {/*<div class="img">Auto AID</div>*/}
                        </a>
                    </div>
                    {/* Logo End */}

                    <div class="menu-container flex-grow-1">
                        <ul id="menu" class="menu show">
                            <li>

                            </li>
                        </ul>
                    </div>

                    {/* User Menu Start */}
                    <div class="user-container d-flex">
                        <a href="#" class="d-flex user position-relative" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img class="profile" alt="profile" src={avatar} />
                            <div class="name"><User show_name={true} employer={true} /></div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end user-menu wide">
                            <div class="row mb-1 ms-0 me-0">
                                <div class="col-6 pe-1 ps-1">
                                    <ul class="list-unstyled">
                                        <li>
                                            <a href="/employer/logout">
                                                <i data-acorn-icon="logout" class="me-2" data-acorn-size="17"></i>
                                                <span class="align-middle">Logout</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* User Menu End */}

                    {/* Icons Menu Start */}
                    <ul class="list-unstyled list-inline text-center menu-icons">
                        <li class="list-inline-item">
                            <div class="btn-group mt-3 me-5" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" checked={Cookies.get("_lang") == "en" || Cookies.get("_lang") == "" ? true : false} onChange={() => setLang('en')} />
                                <label class="btn btn-outline-primary" for="btnradio1">English</label>
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" checked={Cookies.get("_lang") == "de" ? true : false} onChange={() => setLang('de')} />
                                <label class="btn btn-outline-primary" for="btnradio2">Deutsch</label>
                            </div>
                        </li>
                        
                        <li class="list-inline-item">
                            <a href="#" id="colorButton">
                                <i data-acorn-icon="light-on" class="light" data-acorn-size="18"></i>
                                <i data-acorn-icon="light-off" class="dark" data-acorn-size="18"></i>
                            </a>
                        </li>
                    </ul>
                    {/* Icons Menu End */}

                    {/* Menu Start */}
                    <div class="menu-container flex-grow-1">

                    </div>
                    {/* Menu End */}

                    {/* Mobile Buttons Start */}
                    <div class="mobile-buttons-container">
                        {/* Menu Button Start */}
                        <a href="#" id="mobileMenuButton" class="menu-button">
                            <i data-acorn-icon="menu"></i>
                        </a>
                        {/* Menu Button End */}
                    </div>
                    {/* Mobile Buttons End */}
                </div>
                <div class="nav-shadow"></div>
            </div>
        </>
    )
}

export default withParams(Header);