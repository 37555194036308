import React, { useState, useHook, useEffect, useRef } from 'react';
import {
    Link
} from "react-router-dom";

import { useTranslation } from "react-i18next";
//import "../pages/translations/translations/i18n";
import "./translations/i18n";

const SideBar = () => {

    const { t } = useTranslation();

    return (
        <>
            <div class="col-auto d-none d-lg-flex">
                <ul class="sw-25 side-menu mb-0 primary" id="menuSide">
                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <i data-acorn-icon="home" class="icon d-none" data-acorn-size="18"></i>
                            <span class="label">{t("enquiries")}</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/employer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-content me-3 d-inline-block text-primary"><path d="M3 5.5C3 4.09554 3 3.39331 3.33706 2.88886C3.48298 2.67048 3.67048 2.48298 3.88886 2.33706C4.39331 2 5.09554 2 6.5 2H13.5C14.9045 2 15.6067 2 16.1111 2.33706C16.3295 2.48298 16.517 2.67048 16.6629 2.88886C17 3.39331 17 4.09554 17 5.5V14.5C17 15.9045 17 16.6067 16.6629 17.1111C16.517 17.3295 16.3295 17.517 16.1111 17.6629C15.6067 18 14.9045 18 13.5 18H6.5C5.09554 18 4.39331 18 3.88886 17.6629C3.67048 17.517 3.48298 17.3295 3.33706 17.1111C3 16.6067 3 15.9045 3 14.5V5.5Z"></path><path d="M7 6.75C7 6.04777 7 5.69665 7.16853 5.44443C7.24149 5.33524 7.33524 5.24149 7.44443 5.16853C7.69665 5 8.04777 5 8.75 5H11.25C11.9522 5 12.3033 5 12.5556 5.16853C12.6648 5.24149 12.7585 5.33524 12.8315 5.44443C13 5.69665 13 6.04777 13 6.75V7.25C13 7.95223 13 8.30335 12.8315 8.55557C12.7585 8.66476 12.6648 8.75851 12.5556 8.83147C12.3033 9 11.9522 9 11.25 9H8.75C8.04777 9 7.69665 9 7.44443 8.83147C7.33524 8.75851 7.24149 8.66476 7.16853 8.55557C7 8.30335 7 7.95223 7 7.25V6.75Z"></path><path d="M7 12H13M7 15H13"></path></svg>
                                    <span class="label">{t("all")}</span>
                                </a>
                            </li>
                            <li>
                                <a href="/employer/enquiries/generate">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-plus me-3 d-inline-block text-primary"><path d="M10 17 10 3M3 10 17 10"></path></svg>
                                    <span class="label">{t("generate")}</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <i data-acorn-icon="home" class="icon d-none" data-acorn-size="18"></i>
                            <span class="label">{t("candidates")}</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/employer/selected">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-check me-3 d-inline-block text-primary"><path d="M16 5L7.7051 14.2166C7.32183 14.6424 6.65982 14.6598 6.2547 14.2547L3 11"></path></svg>
                                    <span class="label">{t("selected")}</span>
                                </a>
                            </li>
                            <li>
                                <a href="/employer/rejected">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-close me-3 d-inline-block text-primary"><path d="M5 5 15 15M15 5 5 15"></path></svg>
                                    <span class="label">{t("rejected")}</span>
                                </a>
                            </li>
                            {/*<li>
                                <a href="/employer/not_attend">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-hourglass me-3 d-inline-block text-primary"><path d="M7.75 18 12.25 18C12.9522 18 13.3033 18 13.5556 17.8315 13.6648 17.7585 13.7585 17.6648 13.8315 17.5556 14 17.3033 14 16.9522 14 16.25L14 14.75C14 14.0158 14 13.6487 13.8803 13.3185 13.8276 13.173 13.7581 13.0341 13.6734 12.9047 13.481 12.6108 13.1873 12.3905 12.6 11.95L11.05 10.7875C10.6187 10.4641 10.4031 10.3023 10.1602 10.2629 10.0541 10.2457 9.94591 10.2457 9.83982 10.2629 9.59689 10.3023 9.38126 10.4641 8.95 10.7875L7.4 11.95C6.81265 12.3905 6.51897 12.6108 6.32659 12.9047 6.24186 13.0341 6.17243 13.173 6.11971 13.3185 6 13.6487 6 14.0158 6 14.75L6 16.25C6 16.9522 6 17.3033 6.16853 17.5556 6.24149 17.6648 6.33524 17.7585 6.44443 17.8315 6.69665 18 7.04777 18 7.75 18zM7.75 2 12.25 2C12.9522 2 13.3033 2 13.5556 2.16853 13.6648 2.24149 13.7585 2.33524 13.8315 2.44443 14 2.69665 14 3.04777 14 3.75L14 5.25C14 5.98419 14 6.35128 13.8803 6.68154 13.8276 6.827 13.7581 6.96585 13.6734 7.09531 13.481 7.38923 13.1873 7.60949 12.6 8.05L11.05 9.2125C10.6187 9.53594 10.4031 9.69766 10.1602 9.73709 10.0541 9.7543 9.94591 9.7543 9.83982 9.73709 9.59689 9.69767 9.38126 9.53594 8.95 9.2125L7.4 8.05C6.81265 7.60949 6.51897 7.38923 6.32659 7.09531 6.24186 6.96585 6.17243 6.827 6.11971 6.68154 6 6.35128 6 5.98419 6 5.25L6 3.75C6 3.04777 6 2.69665 6.16853 2.44443 6.24149 2.33524 6.33524 2.24149 6.44443 2.16853 6.69665 2 7.04777 2 7.75 2z"></path><path d="M15 2 5 2M15 18 5 18"></path></svg>
                                    <span class="label">{t("not_attend")}</span>
                                </a>
    </li>*/}
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default SideBar;