import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';

import { get, post, put, deleteM } from "../../utility/Adapter";
import { createError, removeErrors } from "../../utility/Validation";
import dimage from '../../images/document.jpg';


const Documents = () => {

    const [loading, setLoading] = useState(false);
    const [myProfile, setMyProfile] = useState([]);
    const [file, setFile] = useState('');
    const [progress, setProgess] = useState(0);
    const [dStatus, setDStatus] = useState(0);
    const [proposalAmount, setProposalAmount] = useState(0);

    const [documentsNoLicenseNurse, setDocumentsNoLicenseNurse] = useState([
        { document_name: 'passport', id: 'F1' },
        { document_name: 'higher secondary (+2) Certificate & Mark list', id: 'F2' },
        { document_name: 'degree certificate & mark list', id: 'F3' },
        { document_name: 'transcript', id: 'F4' },
        { document_name: 'state nursing council registration', id: 'F5' },
        { document_name: 'experience certificates (not applicable freshers)', id: 'F6' },
        { document_name: 'birth certificate', id: 'F7' },
        { document_name: 'marriage certificate', id: 'F8' },
        { document_name: 'Application form for your Nursing recognition in Germany (attached herewith)', id: 'F9' },
        { document_name: 'Power of attorney for handling your documents (attached herewith)', id: 'F10' },
        { document_name: 'COVID-19 vaccination certificate', id: 'F11' }
    ]);
    const [documentsLicenseNurse, setDocumentsLicenseNurse] = useState([
        { document_name: 'passport', id: 'F12' },
        { document_name: 'urkunde - nursing registration certificate in Germany', id: 'F13' },
        { document_name: 'bescheid - deficit notice certificate in Germany', id: 'F14' },
        { document_name: 'COVID-19 vaccination certificate', id: 'F15' }
    ]);
    const [documentsStudents, setDocumentsStudents] = useState([
        { document_name: 'passport', id: 'F16' },
        { document_name: 'birth certificate', id: 'F17' },
        { document_name: 'secondary (10th) Certificate & Mark list', id: 'F18' },
        { document_name: 'higher secondary (+2) Certificate & Mark list', id: 'F19' },
        { document_name: 'resume', id: 'F20' },
        { document_name: 'motivation letter', id: 'F21' },
        { document_name: 'power of attorney', id: 'F22' },
        { document_name: 'ANTRAG AUF ANERKENNUNG/BEWERTUNG VON BILDUNGSNACHWEISEN (+2 registration application form)', id: 'F23' },
        { document_name: 'marriage certificate', id: 'F24' },
        { document_name: 'COVID-19 vaccination certificate', id: 'F25' }
    ]);
    const [uploadDocuments, setUploadDocuments] = useState([]);
    const [documentsCount, setDocumentsCount] = useState(0);
    const [candidatesDocumentsCount, setCandidatesDocumentsCount] = useState(0);

    useEffect(() => {
        _loadProfile();
        _loadDocuments();

        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);

    }, []);

    const _loadProfile = () => {
        get('candidates/profile').then(async function (response) {
            setMyProfile(response.data.data);

            if (response.data.data.candidate_degree == 'STUDENT') {
                setUploadDocuments(documentsStudents);

                if (response.data.data.candidate_family_status != 'm') {
                    setCandidatesDocumentsCount(documentsStudents.length - 1);
                }
                else {
                    setCandidatesDocumentsCount(documentsStudents.length);
                }
            }
            else if (response.data.data.candidate_licence == 'N') {
                setUploadDocuments(documentsNoLicenseNurse);

                if (response.data.data.candidate_family_status != 'm') {
                    setCandidatesDocumentsCount(documentsNoLicenseNurse.length - 1);
                }
                else {
                    setCandidatesDocumentsCount(documentsNoLicenseNurse.length);
                }
            }
            else if (response.data.data.candidate_licence == 'Y') {
                setUploadDocuments(documentsLicenseNurse);

                if (response.data.data.candidate_family_status != 'm') {
                    setCandidatesDocumentsCount(documentsLicenseNurse.length - 1);
                }
                else {
                    setCandidatesDocumentsCount(documentsLicenseNurse.length);
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadDocuments = () => {
        post('candidates/documents').then(async function (response) {
            setTimeout(function () {
                for (var j = 0; j < response.data.data.length; j++) {
                    var id = response.data.data[j].id;

                    var element = document.getElementsByClassName(id);
                    for (var i = 0; i < element.length; i++) {
                        element[i].style.setProperty("display", "none", "important")
                    }

                    element = document.getElementsByClassName(id + '_v');
                    for (var i = 0; i < element.length; i++) {
                        element[i].style.setProperty("display", "block", "important")
                        //element[i].setAttribute("href", process.env.REACT_APP_BASE_URL + 'view/' + response.data.data[j].document_file_name);
                    }

                }
            }, 20)


            setDocumentsCount(response.data.data.length);
            setDStatus(response.data.document_status);

            if (response.data.document_amount != undefined) {
                setProposalAmount(response.data.document_amount);
            }

        }).catch(function (error) {
            console.log(error);
        });
    }

    //STUDENT
    //NONE



    const uploadFile = (e, data, id) => {
        const file = e.target.files[0];
        setFile(file);
        setProgess(0);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', data);
        formData.append('id', id);

        var element = document.getElementsByClassName(id);
        for (var i = 0; i < element.length; i++) {
            element[i].style.display = 'none';
        }



        post('candidates/document-upload', formData)
            .then(res => {
                element = document.getElementsByClassName(id + '_v');
                for (var i = 0; i < element.length; i++) {
                    element[i].style.display = 'block';
                    //element[i].setAttribute("href", process.env.REACT_APP_BASE_URL + 'view/' + res.data.name);
                }

                _loadDocuments();
            }).catch(err => console.log(err));
        /*axios.post(process.env.REACT_APP_BASE_URL + 'candidates/document-upload', formData, {
            onUploadProgress: (ProgressEvent) => {
                let progress = Math.round(
                ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
                setProgess(progress);
            }
        }).then(res => {
            
        }).catch(err => console.log(err));*/
    }

    const payNow = () => {
        var amount = proposalAmount * 100; //Razorpay consider the amount in paisevar options = {
        var options = {
            "key": process.env.REACT_APP_razorpaytest_id,
            "amount": amount, // 2000 paise = INR 20, amount in paisa
            "name": "",
            "description": "",
            'order_id': "",
            "handler": function (response) {
                var values = {
                    razorpay_signature: response.razorpay_signature,
                    razorpay_order_id: response.razorpay_order_id,
                    transactionid: response.razorpay_payment_id,
                    transactionamount: amount,
                }
                post('payments/upgrade', values)
                    .then(res => { setDStatus(4); alert("Success") })
                    .catch(e => console.log(e))
            },
            "prefill": {
                "name": myProfile.candidate_first_name,
                "email": myProfile.candidate_email,
                "contact": myProfile.candidate_phone,
            },
            "notes": {
                "address": ""
            },
            "theme": {
                "color": "#528ff0"
            }
        };

        post('payments/order', { amount: amount })
            .then(res => {
                options.order_id = res.data.id;
                options.amount = res.data.amount;
                console.log(options)
                var rzp1 = new window.Razorpay(options);
                rzp1.open();
            })
            .catch(err => console.log(err));
    }

    var documentStatus = {
        0: 'Pending',
        1: 'Submitted',
        2: 'Waiting Proposal',
        3: 'Waiting Payment',
        4: 'Translation Progress',
        5: 'Translation Under Review',
        6: 'Completed'
    }


    return (
        <>
            <Header />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        {/* Page Content Start */}
                        <div class="col">
                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container mb-3">
                                <div class="row">
                                    <div class="col mb-2">
                                        <h1 class="mb-2 pb-0 display-4" id="title">Documents </h1>
                                        <div class="text-muted font-heading text-small">Document Details..</div>
                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}


                            {
                                documentsCount == candidatesDocumentsCount && dStatus == 0 &&
                                <>
                                    <div class="alert alert-success" role="alert"><i data-acorn-icon="check-circle" class="pin" data-acorn-size="26"></i> All documents have been submitted for verification</div>
                                </>
                            }

                            <div class="row mb-2">
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                    <div class="mb-4 mb-lg-3 bg-transparent no-shadow d-none d-lg-block">
                                        <div class="row g-0">
                                            <div class="col-auto sw-11 d-none d-lg-flex"></div>
                                            <div class="col">
                                                <div class="ps-5 pe-4 h-100">
                                                    <div class="row g-0 h-100 align-content-center custom-sort">
                                                        <div class="col-lg-4 d-flex flex-column mb-lg-0 pe-3 d-flex">
                                                            <div class="text-muted text-small cursor-pointer sort" data-sort="name">DOCUMENT</div>
                                                        </div>
                                                        <div class="col-lg-2 d-flex flex-column pe-1 justify-content-center">
                                                            <div class="text-muted text-small cursor-pointer sort" data-sort="email"></div>
                                                        </div>
                                                        <div class="col-lg-3 d-flex flex-column pe-1 justify-content-center">
                                                            <div class="text-muted text-small cursor-pointer sort" data-sort="phone"></div>
                                                        </div>
                                                        <div class="col-lg-2 d-flex flex-column pe-1 justify-content-center">
                                                            <div class="text-muted text-small cursor-pointer sort" data-sort="group">STATUS</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/*CARD START*/}
                                    {
                                        uploadDocuments.map((d, k) =>

                                            myProfile.candidate_family_status == 'm' && d.document_name == 'marriage certificate' ?
                                                <div class="card mb-2">
                                                    <div class="row g-0 h-100 sh-lg-9 position-relative">
                                                        <a href="Products.Detail.html" class="col-auto position-relative">
                                                            <img src={dimage} className='card-img card-img-horizontal sw-11 h-100' />
                                                        </a>
                                                        <div class="col py-4 py-lg-0">
                                                            <div class="ps-5 pe-4 h-100">
                                                                <div class="row g-0 h-100 align-content-center">
                                                                    <a
                                                                        href="#"
                                                                        class="col-11 col-lg-4 d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center"
                                                                        style={{ textTransform: 'uppercase', color: '#4a4a4a' }}
                                                                    >
                                                                        {d.document_name}
                                                                        <div class="text-small text-muted text-truncate position">*Upload pdf format</div>
                                                                    </a>
                                                                    <div class="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                                                                        <div class="lh-1 text-alternate"></div>
                                                                    </div>
                                                                    <div class="col-12 col-lg-3 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4">
                                                                        <div className={"lh-1 text-alternate d-none " + d.id + "_v " + d.id + '_l'}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-check mb-3 d-inline-block text-primary"><path d="M16 5L7.7051 14.2166C7.32183 14.6424 6.65982 14.6598 6.2547 14.2547L3 11"></path></svg></div>
                                                                    </div>
                                                                    <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 " + d.id}>
                                                                        <input id={"f_" + d.id} accept="application/pdf" type="file" style={{ position: 'absolute', zIndex: 2, top: 0, left: 0, filter: 'alpha(opacity=0)', msFilter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)", opacity: 0, backgroundColor: 'transparent', color: 'transparent' }} name="file_source" size="40" onChange={(e) => uploadFile(e, d.document_name, d.id)} />
                                                                        <label htmlFor={"f_" + d.id} className="badge bg-outline-primary group">UPLOAD</label>
                                                                    </div>

                                                                    <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 d-none " + d.id + "_v " + d.id + '_l'}>
                                                                        <span class="badge bg-outline-primary group" style={{ marginTop: 28, cursor: 'pointer' }}>VIEW</span>
                                                                    </div>

                                                                    <div class="col-1 d-flex flex-column mb-2 mb-lg-0 align-items-end order-2 order-lg-last justify-content-lg-center">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                d.document_name != 'marriage certificate' &&
                                                <div class="card mb-2">
                                                    <div class="row g-0 h-100 sh-lg-9 position-relative">
                                                        <a href="Products.Detail.html" class="col-auto position-relative">
                                                            <img src={dimage} className='card-img card-img-horizontal sw-11 h-100' />
                                                        </a>
                                                        <div class="col py-4 py-lg-0">
                                                            <div class="ps-5 pe-4 h-100">
                                                                <div class="row g-0 h-100 align-content-center">
                                                                    <a
                                                                        href="#"
                                                                        class="col-11 col-lg-4 d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center"
                                                                        style={{ textTransform: 'uppercase', color: '#4a4a4a' }}
                                                                    >
                                                                        {d.document_name}
                                                                        <div class="text-small text-muted text-truncate position">*Upload pdf format</div>
                                                                    </a>
                                                                    <div class="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                                                                        <div class="lh-1 text-alternate">&nbsp;</div>
                                                                    </div>
                                                                    <div className={"col-12 col-lg-3 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4"}>
                                                                        <div className={"lh-1 text-alternate d-none " + d.id + "_v " + d.id + '_l'}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-check mb-3 d-inline-block text-primary"><path d="M16 5L7.7051 14.2166C7.32183 14.6424 6.65982 14.6598 6.2547 14.2547L3 11"></path></svg></div>
                                                                    </div>
                                                                    <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 " + d.id}>
                                                                        <input id={"f_" + d.id} accept="application/pdf" type="file" style={{ position: 'absolute', zIndex: 2, top: 0, left: 0, filter: 'alpha(opacity=0)', msFilter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)", opacity: 0, backgroundColor: 'transparent', color: 'transparent' }} name="file_source" size="40" onChange={(e) => uploadFile(e, d.document_name, d.id)} />
                                                                        <label htmlFor={"f_" + d.id} className="badge bg-outline-primary group">UPLOAD</label>
                                                                    </div>

                                                                    <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 d-none " + d.id + "_v " + d.id + '_l'}>
                                                                        <span class="badge bg-outline-primary group" style={{ marginTop: 28, cursor: 'pointer' }}>VIEW</span>
                                                                    </div>

                                                                    <div class="col-1 d-flex flex-column mb-2 mb-lg-0 align-items-end order-2 order-lg-last justify-content-lg-center">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                    }

                                    {/*CARD END*/}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Documents;