import React, { useState, useHook, useEffect, useRef } from 'react';
import logo from '../../images/logo.svg';
import avatar from '../../images/avatar.jpg';
import User from '../../components/User';
import { withParams } from "../HOC";


const Header = (props) => {
    return (
        <>
            <div id="nav" class="nav-container d-flex">
                <div class="nav-content d-flex">
                    {/* Logo Start */}
                    <div class="logo position-relative">
                        <a href="/">
                            {/* Logo can be added directly */}
                             <img src={logo} alt="logo" style={{width:'100%', height: 0}}/> 

                            {/* Or added via css to provide different ones for different color themes */}
                            {/*<div class="img">Auto AID</div>*/}
                        </a>
                    </div>
                    {/* Logo End */}

                    {/* User Menu Start */}
                    <div class="user-container d-flex">
                        <a href="#" class="d-flex user position-relative" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img class="profile" alt="profile" src={avatar} />
                            <div class="name"><User show_name={true} /></div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end user-menu wide">
                            <div class="row mb-3 ms-0 me-0">
                                <div class="col-12 ps-1 mb-2">
                                    <div class="text-extra-small text-primary">ACCOUNT</div>
                                </div>
                                <div class="col-6 ps-1 pe-1">
                                    <ul class="list-unstyled">
                                        <li>
                                            <a href="/translator/myaccount">My Account</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row mb-1 ms-0 me-0">
                                <div class="col-12 p-1 mb-3 pt-3">
                                    <div class="separator-light"></div>
                                </div>
                                <div class="col-6 pe-1 ps-1">
                                    <ul class="list-unstyled">
                                        
                                        <li>
                                            <a href="/translator/logout">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-logout me-2"><path d="M7 15 2.35355 10.3536C2.15829 10.1583 2.15829 9.84171 2.35355 9.64645L7 5M3 10H13M12 18 14.5 18C15.9045 18 16.6067 18 17.1111 17.6629 17.3295 17.517 17.517 17.3295 17.6629 17.1111 18 16.6067 18 15.9045 18 14.5L18 5.5C18 4.09554 18 3.39331 17.6629 2.88886 17.517 2.67048 17.3295 2.48298 17.1111 2.33706 16.6067 2 15.9045 2 14.5 2L12 2"></path></svg>
                                                <span class="align-middle">Logout</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* User Menu End */}

                    {/* Icons Menu Start */}
                    <ul class="list-unstyled list-inline text-center menu-icons">
                        <li class="list-inline-item">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#searchPagesModal">
                                <i data-acorn-icon="search" data-acorn-size="18"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" id="pinButton" class="pin-button">
                                <i data-acorn-icon="lock-on" class="unpin" data-acorn-size="18"></i>
                                <i data-acorn-icon="lock-off" class="pin" data-acorn-size="18"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" id="colorButton">
                                <i data-acorn-icon="light-on" class="light" data-acorn-size="18"></i>
                                <i data-acorn-icon="light-off" class="dark" data-acorn-size="18"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" data-bs-toggle="dropdown" data-bs-target="#notifications" aria-haspopup="true" aria-expanded="false" class="notification-button">
                                <div class="position-relative d-inline-flex">
                                    <i data-acorn-icon="bell" data-acorn-size="18"></i>
                                    <span class="position-absolute notification-dot rounded-xl"></span>
                                </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end wide notification-dropdown scroll-out" id="notifications">
                                <div class="scroll">
                                    <ul class="list-unstyled border-last-none">
                                        <li class="mb-3 pb-3 border-bottom border-separator-light d-flex">
                                            <img src="img/profile/profile-1.webp" class="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                            <div class="align-self-center">
                                                <a href="#">Joisse Kaycee just sent a new comment!</a>
                                            </div>
                                        </li>
                                        <li class="mb-3 pb-3 border-bottom border-separator-light d-flex">
                                            <img src="img/profile/profile-2.webp" class="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                            <div class="align-self-center">
                                                <a href="#">New order received! It is total $147,20.</a>
                                            </div>
                                        </li>
                                        <li class="mb-3 pb-3 border-bottom border-separator-light d-flex">
                                            <img src="img/profile/profile-3.webp" class="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                            <div class="align-self-center">
                                                <a href="#">3 items just added to wish list by a user!</a>
                                            </div>
                                        </li>
                                        <li class="pb-3 pb-3 border-bottom border-separator-light d-flex">
                                            <img src="img/profile/profile-6.webp" class="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                            <div class="align-self-center">
                                                <a href="#">Kirby Peters just sent a new message!</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    {/* Icons Menu End */}

                    {/* Menu Start */}
                    <div class="menu-container flex-grow-1">
                        
                    </div>
                    {/* Menu End */}

                    {/* Mobile Buttons Start */}
                    <div class="mobile-buttons-container">
                        {/* Menu Button Start */}
                        <a href="#" id="mobileMenuButton" class="menu-button">
                            <i data-acorn-icon="menu"></i>
                        </a>
                        {/* Menu Button End */}
                    </div>
                    {/* Mobile Buttons End */}
                </div>
                <div class="nav-shadow"></div>
            </div>
        </>
    )
}

export default withParams(Header);