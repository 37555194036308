import React, { useState, useHook, useEffect, useRef } from 'react';

const Loader = (props) => {
    if (props.loading == true) {
        return <div className="loading">Loading&#8230;</div>;
    }

    return <></>
}

export default Loader;