import React, { useState, useHook, useEffect, useRef } from "react";
import { get, post, put, deleteM } from "../utility/Adapter";

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";

import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { withParams } from "../../HOC";
import { pdfFromReact } from "generate-pdf-from-react-html";
import './bio.css';

const Index = (props) => {

    const [loading, setLoading] = useState(false);
    const [candidate, setCandidate] = useState({
        candidate_professional: [],
        "candidate_education_details": {
            "course_start": "",
            "course_end": "",
            "school_name": "",
            "board_name": "",
            "state": "",
            "country": ""
        },
        candidate_hobbies: [],
        candidate_nurse_course: [],
        candidate_language_levels:[]
    });

    const { t } = useTranslation();
    const _back = () => {
        window.history.go(-1);
    }

    const _loadDetails = () => {
        setLoading(true);
        get("admin/employer/candidate/" + props.params.id)
            .then(async function (response) {
                setCandidate(response.data.data);
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    useEffect(() => {
        _loadDetails();
    }, []);

    var course = {
        "1": 'B.Sc Krankenpflege 4 Jahre Ausbildung',
        "2": 'M.Sc Pflege 2 Jahre Kurs',
        "3": 'Diplom in Krankenpflege/GNM 3,5-jähriger Kurs',
        "4": 'Post Basic B.Sc 2 Jahre Kurs',
      }

      var languageLevels = {
        NOT_ENTROLLED: "Not yet enrolled",
        A1_ENTROLLED: "A1 Enrolled",
        A1_COMPLETE: "A2 Complete",
        B1_COMPLETE: "B1 Complete",
        B2_COMPLETE: "B2 Complete",
        C1_COMPLETE: "C1 Complete",
        C2_COMPLETE: "C2 Complete",
      };

    return (
        <>
            <Header />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />

                        <div class="col">
                            <div class="page-title-container mb-3">
                                <div class="row">
                                    <div class="col mb-2">
                                        <h1 class="mb-2 pb-0 display-4" id="title" style={{ fontSize: 15 }} onClick={() => _back()}>
                                            <i
                                                data-acorn-icon="arrow-left"
                                                class="icon"
                                                data-acorn-size="18"
                                            ></i>
                                        </h1>
                                        <div class="text-muted font-heading text-small"></div>
                                    </div>
                                </div>
                            </div>

                            {/*<embed
                                src={
                                    process.env.REACT_APP_BASE_URL +
                                    "candidates/download/de_" +
                                    props.params.id
                                }
                                type="application/pdf"
                                width={"100%"}
                                height={800}
                            />*/}



                            <div className="container" >
                                <div className="row">
                                    <div className="col-sm-12 col-lg-2">
                                    </div>
                                    <div className="col-sm-12 col-lg-8">

                                        <table style={{ width: '100%', backgroundColor: "white" }}>
                                            <tr>
                                                <td style={{ border: '1px solid white', width: '40%', textAlign: 'right' }}>
                                                    <img src={candidate.candidate_image} style={{ width: 150, height: 150, borderRadius: 150, objectFit: 'contain' }} />
                                                </td>
                                                <td style={{ border: '1px solid white', width: '5%' }}>
                                                    &nbsp;
                                                </td>
                                                <td style={{ border: '1px solid white', width: '55%' }}>
                                                    <h4 className="fname">HNC{candidate.id}</h4>
                                                    <h4 className="lname"></h4>
                                                    <div className="borderW"></div>
                                                </td>
                                            </tr>

                                            

                                            <tr>
                                                <td style={{ border: '1px solid white', width: '40%', textAlign: 'right' }}>
                                                    <h5>PERSÖNLICHE DATEN</h5>
                                                    <div className="borderW" style={{ float: 'right', marginTop: 0 }}></div>
                                                </td>
                                                <td style={{ border: '1px solid white', width: '5%' }}>
                                                    &nbsp;
                                                </td>
                                                <td style={{ border: '1px solid white', width: '55%' }}>
                                                    <table border={0} style={{ width: '100%' }}>
                                                        <tr>
                                                            <td style={{ width: '30%' }}>
                                                                <span className="sm-title"> GEBURTSDATUM </span>
                                                            </td>
                                                            <td>{candidate.candidate_dob}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: '30%' }}>
                                                                <span className="sm-title"> GESC HLECHT </span>
                                                            </td>
                                                            <td>{candidate.candidate_gender}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: '30%' }}>
                                                                <span className="sm-title"> FAMILIENSTAND </span>
                                                            </td>
                                                            <td>{candidate.candidate_family_status}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: '30%' }}>
                                                                <span className="sm-title"> NATIONALITÄT </span>
                                                            </td>
                                                            <td>{candidate.candidate_nationality}</td>
                                                        </tr>

                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ border: '1px solid white', width: '40%', textAlign: 'right' }}>

                                                </td>
                                                <td style={{ border: '1px solid white', width: '5%' }}>
                                                    &nbsp;
                                                </td>
                                                <td style={{ border: '1px solid white', width: '55%' }}>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ border: '1px solid white', width: '40%', textAlign: 'right' }}>
                                                    <h5>BERUFSERFAHRUNG</h5>
                                                    <div className="borderW" style={{ float: 'right', marginTop: 0 }}></div>
                                                </td>
                                                <td style={{ border: '1px solid white', width: '5%' }}>
                                                    &nbsp;
                                                </td>
                                                <td style={{ border: '1px solid white', width: '55%' }}>

                                                </td>
                                            </tr>



                                            {
                                                candidate.candidate_professional.map((p, l) =>
                                                    <tr>
                                                        <td valign="top" style={{ border: '1px solid white', width: '40%', textAlign: 'right', paddingTop: 20 }}>
                                                            <div>{p.experience_start} - {p.experience_end}</div>
                                                        </td>
                                                        <td style={{ width: '5%' }}>&nbsp;</td>
                                                        <td valign="top" style={{ border: '1px solid white', width: '55%', textAlign: 'left', paddingTop: 20, paddingLeft: 10 }}>
                                                            <div style={{ fontWeight: 600 }}>{p.employer_name} | {p.designation_name}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            }

<tr>
                                                <td style={{ border: '1px solid white', width: '40%', textAlign: 'right' }}>

                                                </td>
                                                <td style={{ border: '1px solid white', width: '5%' }}>
                                                    &nbsp;
                                                </td>
                                                <td style={{ border: '1px solid white', width: '55%' }}>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ border: '1px solid white', width: '40%', textAlign: 'right' }}>
                                                    <h5>SCHULE & AUSBILDUNG</h5>
                                                    <div className="borderW" style={{ float: 'right', marginTop: 0 }}></div>
                                                </td>
                                                <td style={{ border: '1px solid white', width: '5%' }}>
                                                    &nbsp;
                                                </td>
                                                <td style={{ border: '1px solid white', width: '55%' }}>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td valign="top" style={{ border: '1px solid white', width: '40%', textAlign: 'right', paddingTop: 20 }}>
                                                    <div>{candidate.candidate_education_details.course_start} - {candidate.candidate_education_details.course_end}</div>
                                                </td>
                                                <td style={{ width: '5%' }}>&nbsp;</td>
                                                <td valign="top" style={{ border: '1px solid white', width: '55%', textAlign: 'left', paddingTop: 20, paddingLeft: 10 }}>
                                                    <div style={{ fontWeight: 600 }}>höhere Sekundarstufe</div>
                                                    <div>{candidate.candidate_education_details.board_name} {candidate.candidate_education_details.school_name}  | {candidate.candidate_education_details.state}, {candidate.candidate_education_details.country}</div>
                                                </td>
                                            </tr>
                                            
                                            {
                                                candidate.candidate_nurse_course.map((c, k) => 
                                                <tr>
                                                    <td valign="top" style={{ border: '1px solid white', width: '40%', textAlign: 'right', paddingTop: 20 }}>
                                                        <div>{c.course_start_f} - {c.course_end_f}</div>
                                                    </td>
                                                    <td style={{ width: '5%' }}>&nbsp;</td>
                                                    <td valign="top" style={{ border: '1px solid white', width: '55%', textAlign: 'left', paddingTop: 20, paddingLeft: 10 }}>
                                                        <div style={{ fontWeight: 600 }}>{course[c.course]}</div>
                                                        <div>{c.board_name} {c.college_name}  | {c.state}, {c.country_f}</div>
                                                    </td>
                                                </tr>
                                                )
                                            }
                                            


                                            <tr>
                                                <td style={{ border: '1px solid white', width: '40%', textAlign: 'right' }}>

                                                </td>
                                                <td style={{ border: '1px solid white', width: '5%' }}>
                                                    &nbsp;
                                                </td>
                                                <td style={{ border: '1px solid white', width: '55%' }}>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ border: '1px solid white', width: '40%', textAlign: 'right' }}>
                                                <h5>SPRACHKURS- DEUTSCH</h5>
                                                    <div className="borderW" style={{ float: 'right', marginTop: 0 }}></div>
                                                </td>
                                                <td style={{ border: '1px solid white', width: '5%' }}>
                                                    &nbsp;
                                                </td>
                                                <td style={{ border: '1px solid white', width: '55%' }}>
                                                    {languageLevels[candidate.candidate_language_level]}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td valign="top" style={{ border: '1px solid white', width: '40%', textAlign: 'right', paddingTop: 20 }}>
                                                    <h5>SPRACHKENNTNISSE</h5>
                                                    <div className="borderW" style={{ float: 'right', marginTop: 0 }}></div>
                                                </td>
                                                <td style={{ width: '5%' }}>&nbsp;</td>
                                                <td valign="top" style={{ border: '1px solid white', width: '55%', textAlign: 'left', paddingTop: 20, paddingLeft: 10 }}>
                                                    {
                                                        candidate.candidate_language_levels.map((l, k) => 
                                                            <div>{l.speaking_name}</div>
                                                        )
                                                    }
                                                </td>
                                            </tr>

                                            {/*<tr>
                                                <td valign="top" style={{ border: '1px solid white', width: '40%', textAlign: 'right', paddingTop: 20 }}>
                                                    <div style={{ fontWeight: 'bold', fontSize: 16 }}>PLACE, DATE</div>
                                                    <div style={{ fontWeight: 'bold', fontSize: 16 }}>SIGNATURE</div>
                                                </td>
                                                </tr>*/}

                                        </table>

                                        
                                    </div>
                                    <div className="col-sm-12 col-lg-2">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default withParams(Index);