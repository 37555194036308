import React, { useState, useHook, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';

const Logout = () => {
    useEffect(() => {
        Cookies.remove('hcTransilator');
        Cookies.remove('hcTransilatorData');
        window.location = '/translator/login';
    }, []);

    return (
        <>
            Please wait...
        </>
    )
}

export default Logout;