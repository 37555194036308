import React, { useState, useHook, useEffect, useRef } from 'react';
import { get, post, put, deleteM } from "../../../utility/Adapter";
import logo from '../../../images/logo.svg';
import Cookies from 'js-cookie';

import { useTranslation } from "react-i18next";
import "../translations/i18n";

const EmployerLogin = () => {

    const { t } = useTranslation();
    //{t("employer_login")}

    Element.prototype.insertAfter = function (newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const setLang = (lang) => {
        Cookies.set('_lang', lang);
        window.location = window.location;
    }

    const signin = () => {
        var email = document.getElementById('email').value;
        var password = document.getElementById('password').value;

        if (!validateEmail(email)) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter valid email address";
            document.getElementById('email').insertAfter(errorElement);
            return false;
        }

        if (password == "") {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter password";
            document.getElementById('password').insertAfter(errorElement);
            return false;
        }

        var raw = JSON.stringify({
            "email": email,
            "password": password
        });

        post('employer/login', raw).then(async function (response) {
            if (response.data.data.token) {
                Cookies.set('hcEmployer', response.data.data.token, { expires: 7 });
                window.location = '/employer';
            }

        }).catch(function (error) {
            //console.log(error)
            if (error.response.data) {
                if (error.response.data.error_code == 100) {
                    var errorElement = document.createElement("div");
                    errorElement.classList.add('error');
                    errorElement.innerHTML = t("enter_email");
                    document.getElementById('email').insertAfter(errorElement);

                }
                else if (error.response.data.error_code == 101) {
                    var errorElement = document.createElement("div");
                    errorElement.classList.add('error');
                    errorElement.innerHTML = t("enter_password");
                    document.getElementById('password').insertAfter(errorElement);
                }
                else {
                    var errorElement = document.createElement("div");
                    errorElement.classList.add('error');
                    errorElement.innerHTML = t("invalid_login");
                    document.getElementById('password').insertAfter(errorElement);
                }
            }
        });
    }

    return (
        <>
            <div class="h-100">
                <div class="fixed-background"></div>
                <div class="container-fluid p-0 h-100 position-relative">
                    <div class="row g-0 h-90">
                        <div class="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-90">
                            
                            <div class="min-h-100 d-flex align-items-center">
                            
                                <div class="w-100 w-lg-75 w-xxl-50">
                                    <div>
                                        <div class="mb-5">
                                            <h1 class="display-3 text-white">Hire and Care</h1>
                                            <h1 class="display-3 text-white">{t("employer_login")}</h1>
                                        </div>
                                        <p class="h6 text-white lh-1-5 mb-5" style={{width: 600}}>
                                            
                                        </p>
                                        <div class="mb-5">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                                <div style={{marginTop:20,height: 60, textAlign:'right',width:'100%', paddingRight: 20}} className="justify-content-center align-items-center">
                                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" checked={Cookies.get("_lang") == "en" || Cookies.get("_lang") == "" ? true : false} onChange={()=>setLang('en')}/>
                                        <label class="btn btn-outline-primary" for="btnradio1">English</label>
                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" checked={Cookies.get("_lang") == "de" ? true : false} onChange={()=>setLang('de')}/>
                                        <label class="btn btn-outline-primary" for="btnradio2">Deutsch</label>
                                    </div>
                                    </div>

                                    <div class="sw-lg-70 min-h-90 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">

                                    <div class="sw-lg-50 px-5">
                                        <div class="sh-11">
                                        
                                            <a href="/">
                                                <div class="logo-default">
                                                    <img src={logo} alt="logo" style={{ width: 180 }} />
                                                </div>
                                            </a>
                                        </div>
                                        <div class="mb-5">
                                            <h2 class="cta-1 mb-0 text-primary">{t("welcome")},</h2>
                                            <h2 class="cta-1 text-primary">{t("lets")}</h2>
                                        </div>
                                        <div class="mb-5">
                                            <p class="h6">{t("credential")}</p>
                                        </div>
                                        <div>
                                            <div class="mb-3 filled form-group tooltip-end-top">
                                                <i data-acorn-icon="email"></i>
                                                <input class="form-control" placeholder="Email" name="email" id="email"/>
                                            </div>
                                            <div class="mb-3 filled form-group tooltip-end-top">
                                                <i data-acorn-icon="lock-off"></i>
                                                <input class="form-control pe-7" name="password" type="password" placeholder="Password" id="password"/>
                                                {/*<a class="text-small position-absolute t-3 e-3" href="ja">Forgot?</a>*/}
                                            </div>
                                            <button type="submit" class="btn btn-lg btn-primary" onClick={() => signin()}>{t("login")}</button>
                                        </div>
                                    </div>
                                    </div>
                                
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployerLogin;