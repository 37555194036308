import React, { useState, useHook, useEffect, useRef } from 'react';
import { get, post, put, deleteM } from "../../utility/Adapter"
import { withParams } from "../../pages/HOC";
import Cookies from 'js-cookie';
import axios from 'axios';

const DocumentDetails = (props) => {
    const [open, setOpen] = useState(false);
    const [documentFileName, setDocumentFileName] = useState(null);
    const [documentId, setDocumentId] = useState("");

    useEffect(function () {
        window.$('document').ready(function () {
            window.$('[data-toggle="tooltip"]').tooltip()
        });
        setDocumentFileName(props.d.document_file_name);

        console.log('props d --', props.d)
    }, []);

    const _reject = (id, did) => {
        setDocumentId(id);
        window.$('#exampleModal' + did).modal('toggle');
    }

    const getData = () => {
        try {
            const value = Cookies.get('hcAdmin')
            if (value !== undefined) {
                return value;
            }
        } catch (e) {
            // error reading value
        }
    }

    const save = (id) => {
        var reason = document.getElementById('reason' + props.d.id).value;

        var raw = JSON.stringify({
            "reason": reason,
            "candidate_id": props.candidate_id,
            "document_id": props.d._id,
        });

        post('admin/document/reject', raw).then(async function (response) {
            window.$('#exampleModal' + props.d.id).modal('toggle');
            alert('Successfully rejected!');
            window.reload();
        }).catch(function (error) {
        });
    }

    const _view = (fileName) => {
        setDocumentFileName(props.d.document_file_name);
        window.$("#largeLeftModalExample_" + props.d.id).modal('toggle');
    }

    const uploadFile = (e, id) => {
        const uploadedFile = e.target.files[0];
        const formData = new FormData();
        formData.append('file', uploadedFile);
        formData.append('id', id);

        const token = getData();

        const headers = {
            "Contetnt-Type": "multipart/form-data",
            'Authorization': `Bearer ${token}`,
            'HC-User-Token': `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_BASE_URL + 'admin/candidates/document-upload', formData, {
            headers: headers
        })
            .then((res) => {
                alert('Successfully uploaded');
                window.location.reload();
            })
            .catch((error) => {
                alert('Document upload failed, Please try agian')
            })


    };



    return (
        <>
            {
                documentFileName != null &&
                <div className="modal modal-right large fade" id={"largeLeftModalExample_" + props.d.id} tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabelLeftLarge">Document Preview</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                            </div>
                            <div className="modal-body">
                                <div style={{ textAlign: 'center' }}>
                                    <a href={process.env.REACT_APP_BASE_URL + 'documents/preview/' + documentFileName} target='_blank'>Cannot view click here to download</a>
                                </div>

                                <embed src={process.env.REACT_APP_BASE_URL + 'documents/preview/' + documentFileName} type="application/pdf" width={'100%'} height={'700px'} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }


            {/*MODAL START*/}
            <div class="modal fade" id={"exampleModal" + props.d.id} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelDefault" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabelDefault">Reason for reject document</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <textarea id={"reason" + props.d.id} class="form-control required" style={{ height: 140 }} data-error="Please enter reason" placeholder='Enter reason for reject'></textarea>
                        </div>
                        <div class="modal-footer">

                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => save(props.d._id, props.d.id)}>Reject Document</button>
                        </div>
                    </div>
                </div>
            </div>
            {/*MODAL END*/}

            <div class="card mb-2 sh-11 sh-md-8">
                <div class="card-body pt-0 pb-0 h-100">
                    <div class="row g-0 h-100 align-content-center">
                        <div class="col-11 col-md-7 d-flex align-items-center mb-1 mb-md-0 order-1 order-md-1 pt-2">
                            <a target="_blank" href={process.env.REACT_APP_BASE_URL + 'documents/preview/' + props.d.document_file_name} class="body-link text-truncate">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-file-text sw-2 me-2 text-alternate"><path d="M6.5 18H13.5C14.9045 18 15.6067 18 16.1111 17.6629C16.3295 17.517 16.517 17.3295 16.6629 17.1111C17 16.6067 17 15.9045 17 14.5V7.44975C17 6.83775 17 6.53175 16.9139 6.24786C16.8759 6.12249 16.8256 6.00117 16.7638 5.88563C16.624 5.62399 16.4076 5.40762 15.9749 4.97487L14.0251 3.02513L14.0251 3.02512C13.5924 2.59238 13.376 2.37601 13.1144 2.23616C12.9988 2.1744 12.8775 2.12415 12.7521 2.08612C12.4682 2 12.1622 2 11.5503 2H6.5C5.09554 2 4.39331 2 3.88886 2.33706C3.67048 2.48298 3.48298 2.67048 3.33706 2.88886C3 3.39331 3 4.09554 3 5.5V14.5C3 15.9045 3 16.6067 3.33706 17.1111C3.48298 17.3295 3.67048 17.517 3.88886 17.6629C4.39331 18 5.09554 18 6.5 18Z"></path><path d="M13 6 7 6M13 10 7 10M13 14 7 14"></path></svg>
                                <span class="align-middle text-capitalize">{props.d.document_name} - {props.d.document_file_name} </span>
                            </a>
                        </div>
                        <div class="col-12 col-md-3 d-flex align-items-center justify-content-md-center text-muted order-3 order-md-2">
                            {
                                props.d.document_status == '1' ? <span class="badge bg-danger text-uppercase" data-toggle="tooltip" data-placement="top" title={props.d.document_action_reason}>Rejected</span> : ''
                            }
                        </div>
                        <div class="col-1 col-md-2 d-flex align-items-center text-muted text-medium justify-content-end order-2 order-md-3">
                            <button class="btn btn-icon btn-icon-only btn-link btn-sm p-1" type="button" onClick={() => _view(props.d.document_file_name)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-arrow-bottom undefined"><path d="M17 11 10.3536 17.6464C10.1583 17.8417 9.84171 17.8417 9.64645 17.6464L3 11M10 2 10 17"></path></svg>
                            </button>

                            <div class="dropdown-as-select d-inline-block" data-childSelector="span">
                                <button class="btn btn-icon btn-icon-only btn-link btn-sm mt-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-more-vertical mb-3 d-inline-block text-primary"><path d="M10 9C10.5523 9 11 9.44772 11 10V10C11 10.5523 10.5523 11 10 11V11C9.44772 11 9 10.5523 9 10V10C9 9.44772 9.44772 9 10 9V9zM10 2C10.5523 2 11 2.44772 11 3V3C11 3.55228 10.5523 4 10 4V4C9.44772 4 9 3.55228 9 3V3C9 2.44772 9.44772 2 10 2V2zM10 16C10.5523 16 11 16.4477 11 17V17C11 17.5523 10.5523 18 10 18V18C9.44772 18 9 17.5523 9 17V17C9 16.4477 9.44772 16 10 16V16z"></path></svg>
                                </button>

                                <div class="dropdown-menu dropdown-menu-end">
                                    <a class="dropdown-item active" href="#" onClick={() => _reject(props.d._id, props.d.id)}>Reject</a>
                                    <input id={"f_" + props.d._id} accept="application/pdf" type="file" style={{ position: 'absolute', zIndex: 2, top: 0, left: 0, filter: 'alpha(opacity=0)', msFilter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)", opacity: 0, backgroundColor: 'transparent', color: 'transparent' }} name="file_source" size="40" onChange={(e) => uploadFile(e, props.d._id)} />
                                    <label class="dropdown-item active" htmlFor={"f_" + props.d._id}>
                                        Upload PDF
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}


export default withParams(DocumentDetails);