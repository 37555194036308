import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import { get, post, put, deleteM } from "../../utility/Adapter";
import DataTable from 'react-data-table-component';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Translators = () => {

    const [transilators, setTransilators] = useState([]);
    const [pending, setPending] = React.useState(true);
    //const alert = useAlert();

    Element.prototype.insertAfter = function (newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    useEffect(function () {
        _loadTransilators();
    }, []);

    const _loadTransilators = () => {
        get('admin/transilators').then(async function (response) {
            setTransilators(response.data.data);
            setPending(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _save = () => {

        var ele = document.querySelectorAll(".error");

        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }

        var name = document.getElementById('name').value;
        var phone = document.getElementById('phone').value;
        var email = document.getElementById('email').value;
        var website = document.getElementById('website').value;
        var password = document.getElementById('password').value;

        if (name.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter name";
            document.getElementById('name').insertAfter(errorElement);
            return false;
        }
        else if (phone.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter phone";
            document.getElementById('phone').insertAfter(errorElement);
            return false;
        }
        else if (password.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter password";
            document.getElementById('password').insertAfter(errorElement);
            return false;
        }
        else if (!validateEmail(email)) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter email";
            document.getElementById('email').insertAfter(errorElement);
            return false;
        }

        var raw = JSON.stringify({
            "email": email,
            "name": name,
            "phone": phone,
            "website": website,
            "password": password
        });

        post('admin/transilators', raw).then(async function (response) {

            if (response.data.success === false) {
                alert('Email address is already exists');
                return false;
            }

            document.getElementById('name').value = "";
            document.getElementById('phone').value = "";
            document.getElementById('email').value = "";
            document.getElementById('website').value = "";
            document.getElementById('password').value = "";

            window.$("#exampleModal").modal('toggle');
            alert('Successfully created!'); 
            _loadTransilators();
            
        }).catch(function (error) {
            alert('Email address is already exists');
        });
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.transilator_name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.transilator_email,
        },
        {
            name: 'Phone',
            selector: row => row.transilator_phone,
        },
        {
            name: 'Website',
            selector: row => row.transilator_website,
        },
    ];

    return (
        <>
            <Header />

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Translator</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Translator Name</span>
                                <input class="form-control" id="name" placeholder="Translator name" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Translator Email</span>
                                <input class="form-control" id="email" placeholder="Translator email" type="email" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Translator Phone</span>
                                <input class="form-control" id="phone" placeholder="Translator phone" type="number" pattern="\d+"/>
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Translator Website</span>
                                <input class="form-control" id="website" placeholder="Translator website" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Translator Password</span>
                                <input class="form-control" id="password" placeholder="Translator password" type="text" />
                            </label>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => _save()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        <div class="col">

                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">Translators</h1>

                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>

                                    <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                                        <button data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" class="btn btn-outline-primary btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto" id="newContactButton">
                                            <i data-acorn-icon="plus" class="icon" data-acorn-size="18"></i>
                                            <span className='ml-2'>Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}

                            <div className='col'>
                                <div class="mb-1 mb-lg-1 bg-transparent no-shadow d-none d-lg-block">
                                    <div class="row g-0">
                                        <div class="col-auto sw-0 d-none d-lg-flex"></div>
                                        <div class="col">
                                            <div class="ps-0 ps-5 pe-4 pt-0 pb-0 h-100">
                                                <div class="row g-0 h-100 align-content-center custom-sort">
                                                    <div class="col-3 col-lg-4 d-flex flex-column mb-lg-0 pe-3 d-flex">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="name">NAME</div>
                                                    </div>
                                                    <div class="col-3 col-lg-3 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="email">EMAIL</div>
                                                    </div>
                                                    <div class="col-3 col-lg-3 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="phone">PHONE</div>
                                                    </div>
                                                    <div class="col-3 col-lg-1 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="group">WEBSITE</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                transilators.map((t, k) =>
                                    <div class="card mb-2 mt-2">
                                        <div class="d-none id">16</div>
                                        <div class="row g-0 h-100 sh-lg-9 position-relative">

                                            <div class="col py-3 py-sm-3">
                                                <div class="card-body ps-5 pe-4 pt-0 pb-0 h-100">
                                                    <div class="row g-0 h-100 align-content-center">
                                                        <a href={"/translator/" + t._id} class="col-11 col-lg-4 d-flex flex-column mb-lg-0 mb-3 mb-lg-0 pe-3 d-flex order-1 view-click justify-content-center">
                                                            <div class="name text-capitalize" id="contactName">
                                                                {t.transilator_name}
                                                            </div>
                                                        </a>
                                                        <div class="col-12 col-lg-3 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                                                            <div class="lh-1 text-alternate email" id="contactEmail">{t.transilator_email}</div>
                                                        </div>
                                                        <div class="col-12 col-lg-3 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4">
                                                            <div class="lh-1 text-alternate phone" id="contactPhone">
                                                                {t.transilator_phone}
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4">
                                                            <div class="lh-1 text-alternate phone" id="contactPhone">
                                                                {t.transilator_website}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </main>

            {/*<div className="az-content az-content-dashboard">
                <div className="container">
                    <div className="az-content-body">
                        <div className="az-dashboard-one-title">
                            <div>
                                <h2 class="az-dashboard-title">Translators</h2>
                                <p class="az-dashboard-text"></p>
                            </div>

                            <div class="az-content-header-right">
                                <div class="media">
                                    <div class="media-body">
                                        <label>Total translators</label>
                                        <h6>{transilators.length}</h6>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                                    Add New
                                </button>
                            </div>
                        </div>



                        <div class="row row-sm mg-b-20">
                            <div class="col-lg-12 ht-lg-100p">
                                <div class="table-responsive ">


                                    <div style={{ border: '1px solid #fff' }}>
                                        <DataTable
                                            columns={columns}
                                            data={transilators}
                                            defaultSortFieldId={1}
                                            fixedHeader
                                            responsive={true}
                                            striped={true}
                                            highlightOnHover={true}
                                            progressPending={pending}
                                            pagination
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                        </div>*/}

            <Footer />

        </>
    )
}


export default Translators;