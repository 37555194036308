import React, { useState, useHook, useEffect, useRef } from 'react';
import { get, post, put, deleteM } from "../utility/Adapter";

import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';

import { withParams } from "../../HOC";
import DocumentDetails from '../../../components/candidates/Documents';
import axios from 'axios';
import Cookies from 'js-cookie';

const TranslatorCandidateDocuments = (props) => {

    const [documents, setDocuments] = useState([]);
    const [dStatus, setDocumentStatus] = useState(0);
    const [translator, setTranslator] = useState([]);
    const [docId, setDocId] = useState(null);
    const [candidateId, setCandidateId] = useState(null);

    useEffect(function () {
        _loadDocuments(1);
    }, []);

    var documentStatus = {
        0: 'Pending',
        1: 'Submitted',
        2: 'Waiting Proposal',
        3: 'Waiting Admin Approval',
        4: 'Waiting Payment',
        5: 'Translation Progress',
        6: 'Translation Under Review',
        7: 'Completed'
    }

    const _loadDocuments = (page) => {
        get('admin/candidate/documents/' + props.params.id)
            .then(async function (response) {

                console.log("response.data.data", response.data.data[0].document_candidate_id)

                setDocuments(response.data.data);
                setCandidateId(response.data.data[0].document_candidate_id);
                setDocId(response.data.data[0].document_id);
                setDocumentStatus(response.data.document_status);
                setTranslator(response.data.transilator);
            }).catch(function (error) {
            });
    }

    const _save = () => {
        var ele = document.querySelectorAll(".error");

        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }

        var amount = document.getElementById('amount').value;
        var days = document.getElementById('days').value;
        var note = document.getElementById('proposal_note').value;

        if (amount.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter amount";
            document.getElementById('amount').insertAfter(errorElement);
            return false;
        }
        else if (days.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter days";
            document.getElementById('days').insertAfter(errorElement);
            return false;
        }

        var raw = JSON.stringify({
            "amount": parseFloat(amount),
            "days": days,
            "id": translator.document_id,
            "candidate": props.params.id,
            note: note
        });

        post('translators/proposal', raw).then(async function (response) {
            alert('Successfully submitted!');
            window.location = '/translator/candidates';
        }).catch(function (error) {
        });
    }

    const _download = () => {
        window.open(
            process.env.REACT_APP_BASE_URL + 'documents/download/' + docId,
            '_blank'
        );
    }

    const getData = () => {
        try {
            const value = Cookies.get('hcTransilator')
            if (value !== undefined) {
                return value;
            }
        } catch (e) {
            // error reading value
        }
    }

    const uploadFile = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('document_id', docId);
        formData.append('candidateId', candidateId);

        /*post('admin/translators/document_upload', formData)
            .then(res => {
                _loadDocuments(1);
                alert("Successfully Uploaded!");
            }).catch(err => console.log(err));*/

            const headers = {
                "Contetnt-Type": "multipart/form-data",
                "Authorization": "Bearer " + getData(),
                "HC-User-Token": "Bearer " + getData(),
            }
    
    
            axios.post(process.env.REACT_APP_BASE_URL + 'admin/translators/document_upload', formData, {
                headers: headers
            })
            .then((res) => {
                _loadDocuments(1);
                alert("Successfully Uploaded!");
            })
            .catch((error) => {
                alert('Document upload failed, Please try agian')
            })
    }

    return (
        <>
            <Header />
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelDefault" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabelDefault">Send Proposal</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <label class="mb-3 top-label">
                                <input type="text" id="amount" class="form-control required" data-error="Proposal Amount" />
                                <span style={{ color: 'rgb(94, 92, 92)' }}>PROPOSAL AMOUNT</span>
                            </label>

                            <label class="mb-3 top-label">
                                <input type="text" id="days" class="form-control required" data-error="Proposal Days" />
                                <span style={{ color: 'rgb(94, 92, 92)' }}>PROPOSAL DAYS</span>
                            </label>

                            <label class="mb-3 top-label">
                                <textarea type="text" id="proposal_note" class="form-control required" data-error="Proposal Note" />
                                <span style={{ color: 'rgb(94, 92, 92)' }}>PROPOSAL NOTE</span>
                            </label>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => _save()}>Send Proposal</button>
                        </div>
                    </div>
                </div>
            </div>
            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        {/* Page Content Start */}
                        <div class="col">
                            {/* Title and Top Buttons Start */}

                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">Candidate Documents ({documents.length}) </h1>
                                            <div class="text-muted font-heading text-small">List all candidate documents</div>
                                        </div>
                                    </div>


                                    <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-2">

                                        {
                                            docId != null &&
                                            <button type="button" class="btn btn-outline-secondary mb-1 me-2" onClick={() => _download()}><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.25763 9.01146C6.17933 8.92367 6.92028 8.21474 7.04867 7.29781C7.38869 4.86953 9.47771 3 12 3C14.0101 3 15.7474 4.18626 16.542 5.90556C16.8225 6.51241 17.3877 6.93898 18.0482 7.04239C19.7203 7.30417 21 8.7545 21 10.5C21 11.8067 20.2839 12.9461 19.2228 13.5473C18.8165 13.7775 18.5 14.1752 18.5 14.6422C18.5 15.3016 19.1051 15.8035 19.7088 15.5385C21.6466 14.6878 23 12.7519 23 10.5C23 7.75413 20.9878 5.47824 18.3575 5.06645C17.2479 2.66574 14.8185 1 12 1C8.46644 1 5.54439 3.6182 5.06799 7.02047C2.78538 7.23788 1 9.16044 1 11.5C1 13.6444 2.4999 15.4384 4.5078 15.8903C5.04661 16.0115 5.5 15.5523 5.5 15C5.5 14.4477 5.03486 14.0186 4.52626 13.8033C3.62931 13.4236 3 12.5353 3 11.5C3 10.2014 3.99164 9.13204 5.25763 9.01146Z" fill="#148dd3" />
                                                <path d="M14.586 16.9999L13.0001 18.5858V13C13.0001 12.4477 12.5524 12 12.0001 12C11.4478 12 11.0001 12.4477 11.0001 13V18.5858L9.41441 17.0001C9.02389 16.6096 8.39072 16.6096 8.0002 17.0001C7.60967 17.3906 7.60967 18.0238 8.0002 18.4143L11.293 21.7071C11.6835 22.0976 12.3167 22.0976 12.7072 21.7071L16.0002 18.4141C16.3907 18.0236 16.3907 17.3904 16.0002 16.9999C15.6097 16.6094 14.9765 16.6094 14.586 16.9999Z" fill="#148dd3" />
                                            </svg>
                                                Download</button>
                                        }

                                        {
                                            translator.status == '5' &&
                                            <>
                                                <input accept="application/pdf" type="file" style={{ position: 'absolute', zIndex: 2, top: 0, left: 0, filter: 'alpha(opacity=0)', msFilter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)", opacity: 0, backgroundColor: 'transparent', color: 'transparent' }} name="file_source" size="40" onChange={(e) => uploadFile(e)} id="uploadDoc" />
                                                <label htmlFor="uploadDoc" class="btn btn-success mb-1">
                                                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.25763 9.01146C6.17933 8.92367 6.92028 8.21474 7.04867 7.29781C7.38869 4.86953 9.47771 3 12 3C14.0101 3 15.7474 4.18626 16.542 5.90556C16.8225 6.51241 17.3877 6.93898 18.0482 7.04239C19.7203 7.30417 21 8.7545 21 10.5C21 11.8067 20.2839 12.9461 19.2228 13.5473C18.8165 13.7775 18.5 14.1752 18.5 14.6422C18.5 15.3015 19.1051 15.8035 19.7089 15.5385C21.6466 14.6878 23 12.7519 23 10.5C23 7.75413 20.9878 5.47824 18.3575 5.06645C17.2479 2.66574 14.8185 1 12 1C8.46644 1 5.54439 3.6182 5.06799 7.02047C2.78538 7.23788 1 9.16044 1 11.5C1 13.6444 2.4999 15.4384 4.50781 15.8903C5.04662 16.0115 5.5 15.5523 5.50001 15C5.50001 14.4477 5.03487 14.0186 4.52627 13.8033C3.62931 13.4236 3 12.5353 3 11.5C3 10.2014 3.99164 9.13204 5.25763 9.01146Z" fill="#ffffff" />
                                                        <path d="M14.586 17.0001L13.0001 15.4142V21C13.0001 21.5523 12.5524 22 12.0001 22C11.4478 22 11.0001 21.5523 11.0001 21V15.4142L9.41441 16.9999C9.02389 17.3904 8.39072 17.3904 8.0002 16.9999C7.60967 16.6094 7.60967 15.9762 8.0002 15.5857L11.293 12.2929C11.6835 11.9024 12.3167 11.9024 12.7072 12.2929L16.0002 15.5859C16.3907 15.9764 16.3907 16.6096 16.0002 17.0001C15.6097 17.3906 14.9765 17.3906 14.586 17.0001Z" fill="#ffffff" />
                                                    </svg> Upload
                                                </label>
                                            </>
                                        }

                                        {
                                            translator.status == '2' &&
                                            <button type="button" class="btn btn-outline-primary mb-1 ml-2" data-bs-toggle="modal" data-bs-target="#exampleModal"><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.0088 8.41426L18.5946 11H9.00879C5.1428 11 2.00879 14.134 2.00879 18C2.00879 18.5523 2.4565 19 3.00879 19C3.56107 19 4.00879 18.5523 4.00879 18C4.00879 15.2386 6.24736 13 9.00879 13H18.5946L16.0088 15.5858C15.6183 15.9763 15.6183 16.6095 16.0088 17C16.3993 17.3905 17.0325 17.3905 17.423 17L21.7159 12.7071C22.1064 12.3166 22.1064 11.6834 21.7159 11.2929L17.423 7.00005C17.0325 6.60952 16.3993 6.60952 16.0088 7.00005C15.96 7.04886 15.9173 7.10147 15.8806 7.15692C15.6244 7.54507 15.6671 8.07255 16.0088 8.41426Z" fill="#148dd3" />
                                            </svg>
                                                Send Proposal</button>
                                        }
                                    </div>
                                </div>
                            </div>


                            {/* Title and Top Buttons End */}

                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <th>
                                            Document Status
                                        </th>
                                        <td>
                                            {documentStatus[translator.status]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Date
                                        </th>
                                        <td>
                                            {translator.createdAt}
                                        </td>
                                    </tr>

                                    {
                                        translator.status >= '3' &&
                                        <>
                                            <tr>
                                                <th>
                                                    Proposal Amount
                                                </th>
                                                <td>
                                                    {translator.amount}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Proposal Days
                                                </th>
                                                <td>
                                                    {translator.days}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Proposal Note
                                                </th>
                                                <td>
                                                    {translator.note}
                                                </td>
                                            </tr>
                                        </>
                                    }

                                    {
                                        translator.re_translation_note != undefined &&
                                        <tr>
                                            <th style={{ color: 'red' }}>Re Translation Note</th>
                                            <td>{translator.re_translation_note}</td>
                                        </tr>
                                    }

                                </tbody>
                            </table>

                            {
                                documents.map((d, k) =>
                                    <DocumentDetails d={d} key={k} candidate_id={props.params.id} />
                                )
                            }

                            {
                                documents.length == 0 &&
                                <div className='row'>
                                    <div className='col-lg-12 text-center'>
                                        <span>
                                            Douments not found!
                                        </span>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default withParams(TranslatorCandidateDocuments);