import React, { useState, useHook, useEffect, useRef } from 'react';
import { get, post, put, deleteM } from "../utility/Adapter";

import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';

import Cookies from 'js-cookie';

const TranslatorCandidates = () => {

    const [candidates, setCandidates] = useState([]);

    useEffect(function () {
        _loadCandidates();
    }, []);

    const _loadCandidates = () => {
        get('translators/candidates').then(async function (response) {
            setCandidates(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }


    return (
        <>
            <Header />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        {/* Page Content Start */}
                        <div class="col">
                            {/* Title and Top Buttons Start */}

                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">Candidate Documents</h1>
                                            <div class="text-muted font-heading text-small">List all assigned candidate documents</div>
                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>

                                    <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                                    
                                    </div>
                                </div>
                            </div>

                            
                            {/* Title and Top Buttons End */}

                            <div class="mb-4 mb-lg-3 bg-transparent no-shadow d-none d-lg-block">
                                <div class="row g-0">
                                    <div class="col">
                                        <div class="ps-0 ps-5 pe-4 pt-0 pb-0 h-100">
                                            <div class="row g-0 h-100 align-content-center custom-sort">
                                                <div class="col-3 col-lg-3 d-flex flex-column mb-lg-0 pe-3 d-flex">
                                                    <div class="text-muted text-small cursor-pointer sort" data-sort="name">NAME</div>
                                                </div>
                                                <div class="col-3 col-lg-2 d-flex flex-column pe-1 justify-content-center">
                                                    <div class="text-muted text-small cursor-pointer sort" data-sort="email">PHONE</div>
                                                </div>
                                                <div class="col-3 col-lg-2 d-flex flex-column pe-1 justify-content-center">
                                                    <div class="text-muted text-small cursor-pointer sort" data-sort="phone">NURSING LICENSE</div>
                                                </div>

                                                <div class="col-3 col-lg-2 d-flex flex-column pe-1 justify-content-center">
                                                    <div class="text-muted text-small cursor-pointer" data-sort="group"></div>
                                                </div>

                                                <div class="col-3 col-lg-1 d-flex flex-column pe-1 justify-content-center">
                                                    <div class="text-muted text-small cursor-pointer sort" data-sort="group">ACTIONS</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                candidates.map((c, k) =>
                                    <div class="card mb-2 mt-2">
                                        <div class="d-none id">16</div>
                                        <div class="row g-0 h-100 sh-lg-9 position-relative">

                                            <div class="col py-3 py-sm-3">
                                                <div class="card-body ps-5 pe-4 pt-0 pb-0 h-100">
                                                    <div class="row g-0 h-100 align-content-center">
                                                        <a href="javascript:;" class="col-11 col-lg-3 d-flex flex-column mb-lg-0 mb-3 mb-lg-0 pe-3 d-flex order-1 view-click justify-content-center">
                                                            <div class="name text-capitalize justify-content-center" id="contactName">
                                                                {c.candidate.candidate_first_name + ' ' + c.candidate.candidate_last_name}
                                                            </div>
                                                            <div class="text-small text-muted text-truncate position" id="contactPosition">{c.candidate_email}</div>
                                                        </a>
                                                        <div class="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                                                            <div class="lh-1 text-alternate email" id="contactEmail">{c.candidate.candidate_phone}</div>
                                                        </div>
                                                        <div class="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4">
                                                            <div class="lh-1 text-alternate phone" id="contactPhone">
                                                                {c.candidate.candidate_licence == 'Y' ? "Yes" : "No"}
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4">
                                                            <div class="lh-1 text-alternate phone" id="contactPhone">
                                                                {c.candidate.created}
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-lg-1 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5">
                                                            <a title="View Documents" style={{ color: '#000' }} href={"/translator/candidate/documents/" + c.candidate._id}>
                                                                <span class="badge bg-outline-primary group" id="contactGroup">View Documents</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }



                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default TranslatorCandidates;