import React, { useState, useHook, useEffect, useRef } from 'react';
import {
    Link
} from "react-router-dom";

const SideBar = () => {
    return (
        <>
            <div class="col-auto d-none d-lg-flex">
                <ul class="sw-25 side-menu mb-0 primary" id="menuSide">
                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <i data-acorn-icon="home" class="icon" data-acorn-size="18"></i>
                            <span class="label" style={{ color: "#0abfe4" }}>Candidates</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/candidates">
                                    <i data-acorn-icon="user" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">All</span>
                                </a>
                            </li>

                            <li>
                                <a href="/candidates/licensed">
                                    <i data-acorn-icon="star" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Licensed</span>
                                </a>
                            </li>

                            <li>
                                <a href="/candidates/inactive">
                                    <i data-acorn-icon="shield-warning" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Inactive</span>
                                </a>
                            </li>

                            <li>
                                <a href="/candidates/recommended">
                                    <i data-acorn-icon="crown" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Recommended</span>
                                </a>
                            </li>

                            <li>
                                <a href="/candidates/campaigns">
                                    <i data-acorn-icon="flash" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Campaigns</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <i data-acorn-icon="home" class="icon" data-acorn-size="18"></i>
                            <span class="label" style={{ color: "#aa830f" }}>Affiliates</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/affiliates">
                                    <i data-acorn-icon="web-page" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">All</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <i data-acorn-icon="home" class="icon" data-acorn-size="18"></i>
                            <span class="label">Translators</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/translators">
                                    <i data-acorn-icon="web-page" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">All</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <i data-acorn-icon="home" class="icon" data-acorn-size="18"></i>
                            <span class="label">Employers</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/employers">
                                    <i data-acorn-icon="user" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">All</span>
                                </a>
                            </li>

                            <li>
                                <a href="/employers/interview_scheduled">
                                    <i data-acorn-icon="attachment" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Interview Scheduled</span>
                                </a>
                            </li>

                            <li>
                                <a href="/employers/rejected">
                                    <i data-acorn-icon="attachment" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Rejected</span>
                                </a>
                            </li>

                            <li>
                                <a href="/employers/selected">
                                    <i data-acorn-icon="bookmark" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Selected</span>
                                </a>
                            </li>

                        </ul>
                    </li>



                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <i data-acorn-icon="home" class="icon" data-acorn-size="18"></i>
                            <span class="label">Staffs</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/staffs">
                                    <i data-acorn-icon="web-page" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">All</span>
                                </a>
                            </li>
                        </ul>
                    </li>



                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <i data-acorn-icon="home" class="icon" data-acorn-size="18"></i>
                            <span class="label">Enquiries</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/web-contacts">
                                    <i data-acorn-icon="web-page" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">From Web Contacts</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <i data-acorn-icon="home" class="icon" data-acorn-size="18"></i>
                            <span class="label">Settings</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/settings/site">
                                    <i data-acorn-icon="web" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Site Languages</span>
                                </a>
                            </li>
                            <li>
                                <a href="/settings/hobbies">
                                    <i data-acorn-icon="cook-hat" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Hobbies</span>
                                </a>
                            </li>
                            <li>
                                <a href="/settings/designations">
                                    <i data-acorn-icon="database" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Designations</span>
                                </a>
                            </li>
                            <li>
                                <a href="/settings/departments">
                                    <i data-acorn-icon="grid-1" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Departments</span>
                                </a>
                            </li>
                            <li>
                                <a href="/settings/countries">
                                    <i data-acorn-icon="compass" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Countries</span>
                                </a>
                            </li>
                            <li>
                                <a href="/settings/nationalities">
                                    <i data-acorn-icon="pin" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Nationalities</span>
                                </a>
                            </li>
                            <li>
                                <a href="/settings/speaking-languages">
                                    <i data-acorn-icon="messages" class="icon d-none" data-acorn-size="18"></i>
                                    <span class="label">Speaking Languages</span>
                                </a>
                            </li>
                        </ul>
                    </li>





                </ul>
            </div>
        </>
    )
}

export default SideBar;