import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import { get, post, put, deleteM } from "../../utility/Adapter";
import DataTable from "react-data-table-component";
import { withParams } from "../HOC";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

const CandidatesLicensed = (props) => {
  const [candidates, setCandidates] = useState([]);
  const [pending, setPending] = React.useState(true);
  const [totalRows, setTotalRows] = React.useState(0);
  const [paginationPerPage, setPaginationPerPage] = React.useState(25);
  //const alert = useAlert();

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  useEffect(function () {
    //_loadCandidates(1);

    window.$("#datePickerRange").datepicker({
      weekStart: 1,
    });

    if (props.params.type != undefined) {
      filter("licensed");
    }
  }, []);

  const _loadCandidates = (page) => {
    get("admin/candidates/" + page)
      .then(async function (response) {
        setCandidates(response.data.data);
        setTotalRows(response.data.totalRows);
        setPaginationPerPage(response.data.paginationPerPage);
        setPending(false);

        var table = window.$('#datatableRows').DataTable();
        table.destroy();

        setTimeout(function () {

          var uninitialized = window.$('#datatableRows').filter(function () {
            return !window.$.fn.DataTable.fnIsDataTable(this);
          });

          uninitialized.each(function () {
            window.$(this).dataTable(
              {
                dom: 'Bfrtip',
                buttons: [
                  {
                    extend: 'excel',
                    text: 'Export Excel',
                    className: 'btn btn-light',
                    exportOptions: {
                      columns: 'th:not(:last-child)'
                    }
                  },
                  {
                    extend: 'csv',
                    text: 'Export Csv',
                    className: 'btn btn-light',
                    exportOptions: {
                      columns: 'th:not(:last-child)'
                    }
                  },

                ]
              }
            );
          });
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var documentStatus = {
    0: "Pending",
    1: "Submitted",
    2: "Waiting Proposal",
    3: "Waiting Payment",
    4: "Translation Progress",
    5: "Translation Under Review",
    6: "Completed",
  };

  var languageLevels = {
    NOT_ENTROLLED: "Not yet enrolled",
    A1_ENTROLLED: "A1 Enrolled",
    A1_COMPLETE: "A2 Complete",
    B1_COMPLETE: "B1 Complete",
    B2_COMPLETE: "B2 Complete",
    C1_COMPLETE: "C1 Complete",
    C2_COMPLETE: "C2 Complete",
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <a
          href={"/candidate/" + row.candidate_id}
          style={{ textTransform: "capitalize" }}
        >
          {row.candidate_first_name + " " + row.candidate_last_name}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.candidate_email,
    },
    {
      name: "Phone",
      selector: (row) => row.candidate_phone,
    },
    {
      name: "Nursing Degree",
      selector: (row) => row.candidate_degree,
      sortable: true,
    },
    {
      name: "Document Status",
      selector: (row) => documentStatus[row.document_status],
      sortable: true,
    },
    {
      name: "",
      selector: (row) => (
        <a
          title="View Documents"
          style={{ color: "#000" }}
          href={"/candidate/documents/" + row.candidate_id}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="acorn-icons acorn-icons-file-text mb-3 d-inline-block text-primary"
          >
            <path d="M6.5 18H13.5C14.9045 18 15.6067 18 16.1111 17.6629C16.3295 17.517 16.517 17.3295 16.6629 17.1111C17 16.6067 17 15.9045 17 14.5V7.44975C17 6.83775 17 6.53175 16.9139 6.24786C16.8759 6.12249 16.8256 6.00117 16.7638 5.88563C16.624 5.62399 16.4076 5.40762 15.9749 4.97487L14.0251 3.02513L14.0251 3.02512C13.5924 2.59238 13.376 2.37601 13.1144 2.23616C12.9988 2.1744 12.8775 2.12415 12.7521 2.08612C12.4682 2 12.1622 2 11.5503 2H6.5C5.09554 2 4.39331 2 3.88886 2.33706C3.67048 2.48298 3.48298 2.67048 3.33706 2.88886C3 3.39331 3 4.09554 3 5.5V14.5C3 15.9045 3 16.6067 3.33706 17.1111C3.48298 17.3295 3.67048 17.517 3.88886 17.6629C4.39331 18 5.09554 18 6.5 18Z"></path>
            <path d="M13 6 7 6M13 10 7 10M13 14 7 14"></path>
          </svg>
        </a>
      ),
    },
  ];

  const pagination = (page, totalRows) => {
    setPending(true);
    _loadCandidates(page);
  };

  const search = () => {
    var q = document.getElementById("q").value;

    setPending(true);

    post("admin/candidates/search", { q: q, type: "search" })
      .then(async function (response) {
        setCandidates(response.data.data);
        setTotalRows(response.data.totalRows);
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const filter = (filter) => {
    //var filter = document.getElementById('filter').value;

    post("admin/candidates/search", { q: filter, type: "filter" })
      .then(async function (response) {
        setCandidates(response.data.data);
        setTotalRows(response.data.totalRows);
        setPending(false);

        var table = window.$('#datatableRows').DataTable();
        table.destroy();

        setTimeout(function () {

          var uninitialized = window.$('#datatableRows').filter(function () {
            return !window.$.fn.DataTable.fnIsDataTable(this);
          });

          uninitialized.each(function () {
            window.$(this).dataTable(
              {
                dom: 'Bfrtip',
                buttons: [
                  {
                    extend: 'excel',
                    text: 'Export Excel',
                    className: 'btn btn-light',
                    exportOptions: {
                      columns: 'th:not(:last-child)'
                    }
                  },
                  {
                    extend: 'csv',
                    text: 'Export Csv',
                    className: 'btn btn-light',
                    exportOptions: {
                      columns: 'th:not(:last-child)'
                    }
                  },

                ]
              }
            );
          });
        }, 100);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const filterByDate = () => {
    var date_from = document.getElementById("start_date").value;
    var date_to = document.getElementById("end_date").value;

    post("admin/candidates/search", {
      date_from: date_from,
      date_to: date_to,
      type: "date",
    })
      .then(async function (response) {
        setCandidates(response.data.data);
        setTotalRows(response.data.totalRows);
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const _markAsDone = (candidateId) => {
    confirmAlert({
      title: 'Confirm to done',
      message: 'Are you sure to do this.',
      buttons: [
          {
              label: 'Yes',
              onClick: () => _done(candidateId)
          },
          {
              label: 'No',
              onClick: () => { }
          }
      ]
  });
  }

  const _done = (candidateId) => {
    post("admin/candidates/markasdone", {
      'candidateId': candidateId
    })
      .then(async function (response) {
        success({
          title: "SUCCESS",
          text:
              "Successfully marked as done",
          modules: new Map([
              [
                  Confirm,
                  {
                      confirm: true,
                      buttons: [
                          {
                              text: "Ok",
                              primary: true,
                              click: notice => {
                                  notice.close();
                              }
                          }
                      ]
                  }
              ]
          ])
      });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Header />

      <main>
        <div class="container-fluid">
          <div class="row">
            {/* Menu Start */}
            <SideBar />
            {/*  Menu End */}

            {/* Page Content Start */}
            <div class="col">
              {/* Title and Top Buttons Start */}
              <div class="page-title-container">
                <div class="row g-0">
                  <div class="col-auto mb-2 mb-md-0 me-auto">
                    <div class="w-auto sw-md-30">
                      <h1 class="mb-0 pb-0 display-4" id="title">
                        Licensed Candidates
                      </h1>
                    </div>
                  </div>
                  <div class="w-100 d-md-none"></div>
                  <div class="col-12 col-sm-6 col-md d-flex align-items-start justify-content-end order-3 order-sm-2">
                    <div class="w-100 w-lg-auto search-input-container border border-separator">
                      <input
                        class="form-control search"
                        type="text"
                        autocomplete="off"
                        placeholder="Search"
                      />
                      <span class="search-magnifier-icon">
                        <i data-acorn-icon="search"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                    <div
                      class="dropdown-as-select d-inline-block"
                      data-childSelector="span"
                    >
                      <button
                        class="btn p-0"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span
                          class="btn btn-outline-primary dropdown-toggle"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-delay="0"
                          title="Item Count"
                        ></span>
                      </button>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a
                          class="dropdown-item active"
                          href="javascript:;"
                          onClick={() => filter("")}
                        >
                          Filter By
                        </a>
                        <a
                          class="dropdown-item"
                          href="javascript:;"
                          onClick={() => filter("licensed")}
                        >
                          Licensed
                        </a>
                        <a
                          class="dropdown-item"
                          href="javascript:;"
                          onClick={() => filter("non-licensed")}
                        >
                          Non-Licensed
                        </a>
                        <a
                          class="dropdown-item"
                          href="javascript:;"
                          onClick={() => filter("students")}
                        >
                          Students
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="data-table-rows slim">
                <div class="data-table-responsive-wrapper">
                  <table id="datatableRows" class="data-table nowrap hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Email</th>
                        <th scope="col">Language Level</th>
                        <th scope="col">Documents</th>
                        <th scope="col">Resume</th>
                        <th scope="col">Created</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {candidates.map((c, k) => (
                        <tr key={k}>
                          <td scope="row">1</td>
                          <td>
                            <a href={"/candidate/" + c.candidate_id}>
                              <span class="text-capitalize" id="contactName">
                                {c.candidate_first_name +
                                  " " +
                                  c.candidate_last_name}
                              </span>
                            </a>
                          </td>
                          <td>{c.candidate_phone}</td>
                          <td>{c.candidate_email}</td>
                          <td>{languageLevels[c.candidate_language_level]}</td>
                          <td>{c.document_status == 0 ? "Pending" : "Uploaded"}</td>
                          <td>{c.candidate_profile_resume == null ? "Not Uploaded" : <a href={process.env.REACT_APP_BASE_URL + 'documents/preview/' + c.candidate_profile_resume} target="_blank">View</a>}</td>
                          <td>{c.created}</td>
                          <td>
                            {c.candidate_status == 1 ? "Active" : "Inactive"}
                          </td>
                          <td>
                            <a
                              title="View Documents"
                              style={{ color: "#000" }}
                              href={"/candidate/documents/" + c.candidate_id}
                            >
                              <span
                                class="badge bg-outline-primary group"
                                id="contactGroup"
                              >
                                View Documents
                              </span>
                            </a>

                            <a
                              title="View Documents"
                              style={{ color: "#000",marginLeft:4 }}
                              href="javascript:void(0);"
                              onClick={() => _markAsDone(c.candidate_id)}
                            >
                              <span
                                class="badge bg-outline-primary group"
                                id="contactGroup"
                              >
                                Mark as Done
                              </span>
                            </a>

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default withParams(CandidatesLicensed);
