import React, { useState, useHook, useEffect, useRef } from 'react';
import { get, post, put, deleteM } from "../../utility/Adapter";
import logo from '../../images/logo.svg';
import Cookies from 'js-cookie';

const AffiliatorLogin = () => {

    Element.prototype.insertAfter = function (newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const signin = () => {

    }

    const sendOTP = () => {
        var email = document.getElementById('email').value;

        if (!validateEmail(email)) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter valid email address";
            document.getElementById('email').insertAfter(errorElement);
            return false;
        }

        var raw = JSON.stringify({
            "email": email
        });

        post('affiliators/login', raw).then(async function (response) {
            document.getElementById('otp-input').classList.remove("d-none");
            document.getElementById('send-otp').classList.add('d-none');
            document.getElementById('login').classList.remove("d-none");
            document.getElementsByClassName('info')[0].innerHTML = "OTP successfully send to your email address.Please check and enter it.";

        }).catch(function (error) {
            //console.log(error)
            if (error.response.data) {
                if (error.response.data.error_code == 100) {
                    var errorElement = document.createElement("div");
                    errorElement.classList.add('error');
                    errorElement.innerHTML = error.response.data.error_message;
                    document.getElementById('email').insertAfter(errorElement);

                }
            }
        });
    }

    const login = () => {
        var email = document.getElementById('email').value;
        var otp = document.getElementById('otp').value;


        if (!validateEmail(email)) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter valid email address";
            document.getElementById('email').insertAfter(errorElement);
            return false;
        }

        if (otp.length < 6) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter valid otp";
            document.getElementById('otp').insertAfter(errorElement);
            return false;
        }

        var raw = JSON.stringify({
            "email": email,
            "otp": otp
        });

        post('affiliators/login/verify', raw).then(async function (response) {
            if (response.data.data.token) {
                Cookies.set('hcAffiliator', response.data.data.token, { expires: 7 });
                window.location = '/affiliator/candidates';
            }

        }).catch(function (error) {
            //console.log(error)
            if (error.response.data) {
                if (error.response.data.error_code == 100) {
                    var errorElement = document.createElement("div");
                    errorElement.classList.add('error');
                    errorElement.innerHTML = error.response.data.error_message;
                    document.getElementById('email').insertAfter(errorElement);

                }
                else if (error.response.data.error_code == 101) {
                    var errorElement = document.createElement("div");
                    errorElement.classList.add('error');
                    errorElement.innerHTML = error.response.data.error_message;
                    document.getElementById('otp').insertAfter(errorElement);

                }
            }
        });
    }

    return (
        <>
            <div class="h-100">
                <div class="fixed-background"></div>
                <div class="container-fluid p-0 h-100 position-relative">
                    <div class="row g-0 h-100">
                        <div class="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
                            <div class="min-h-100 d-flex align-items-center">
                                <div class="w-100 w-lg-75 w-xxl-50">
                                    <div>
                                        <div class="mb-5">
                                            <h1 class="display-3 text-white"></h1>
                                            <h1 class="display-3 text-white"></h1>
                                        </div>
                                        <p class="h6 text-white lh-1-5 mb-5">
                                            &nbsp;
                                        </p>
                                        <div class="mb-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                            <div class="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
                                <div class="sw-lg-50 px-5">
                                    <div class="sh-11">
                                        <a href="index.html">
                                            <div class="logo-default">
                                                <img src={logo} alt="logo" style={{ width: 180 }} />
                                            </div>
                                        </a>
                                    </div>
                                    <div class="mb-5">
                                        <h2 class="cta-1 mb-0 text-primary">Welcome</h2>
                                        <h2 class="cta-1 text-primary">let's get started!</h2>
                                    </div>
                                    <div class="mb-5">
                                        <p class="h6 info">Please use your credentials to login.</p>
                                    </div>
                                    <div>
                                        <div class="mb-3 filled form-group tooltip-end-top">
                                            <i data-acorn-icon="email"></i>
                                            <input class="form-control" placeholder="Email" name="email" id="email" />
                                        </div>
                                        <div class="mb-3 filled form-group tooltip-end-top d-none" id='otp-input'>
                                            <i data-acorn-icon="lock-off"></i>
                                            <input class="form-control pe-7" name="otp" type="text" placeholder="OTP" id="otp" />
                                            {/*<a class="text-small position-absolute t-3 e-3" href="ja">Forgot?</a>*/}
                                        </div>
                                        <button type="submit" class="btn btn-lg btn-primary" onClick={() => sendOTP()} id='send-otp'>Send OTP</button>
                                        <button type="submit" class="btn btn-lg btn-primary d-none" onClick={() => login()} id='login'>Login</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AffiliatorLogin;