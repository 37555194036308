import React, { useState, useHook, useEffect, useRef } from 'react';
import logo from '../images/logo.svg';
import avatar from '../images/avatar.jpg';

import { get, post, put, deleteM } from "../utility/Adapter";
import Cookies from 'js-cookie';

const Login = () => {

    Element.prototype.insertAfter = function (newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const signin = () => {
        var email = document.getElementById('email').value;
        var password = document.getElementById('password').value;

        if (!validateEmail(email)) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter valid email address";
            document.getElementById('email').insertAfter(errorElement);
            return false;
        }

        if (password == "") {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter password";
            document.getElementById('password').insertAfter(errorElement);
            return false;
        }

        var raw = JSON.stringify({
            "email": email,
            "password": password
        });

        post('admin/login', raw).then(async function (response) {
            if (response.data.data.token) {
                Cookies.set('hcAdmin', response.data.data.token, { expires: 7 });
                window.location = '/';
            }

        }).catch(function (error) {
            //console.log(error)
            if (error.response.data) {
                if (error.response.data.error_code == 100) {
                    var errorElement = document.createElement("div");
                    errorElement.classList.add('error');
                    errorElement.innerHTML = error.response.data.error_message;
                    document.getElementById('email').insertAfter(errorElement);

                }
                else if (error.response.data.error_code == 101) {
                    var errorElement = document.createElement("div");
                    errorElement.classList.add('error');
                    errorElement.innerHTML = error.response.data.error_message;
                    document.getElementById('password').insertAfter(errorElement);
                }
                else if (error.response.data.error_code == 103) {
                    var errorElement = document.createElement("div");
                    errorElement.classList.add('error');
                    errorElement.innerHTML = error.response.data.error_message;
                    document.getElementById('email').insertAfter(errorElement);
                }
            }
        });
    }


    return (
        <>
            <div class="h-100">
                <div class="fixed-background"></div>
                <div class="container-fluid p-0 h-100 position-relative">
                    <div class="row g-0 h-100">
                        <div class="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
                            <div class="min-h-100 d-flex align-items-center">
                                <div class="w-100 w-lg-75 w-xxl-50">
                                    <div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                            <div class="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
                                <div class="sw-lg-50 px-5">
                                    <div class="sh-11">
                                        <a href="index.html">
                                            <div class="logo-default">
                                                <img src={logo} alt="logo" style={{ width: 180 }} />
                                            </div>
                                        </a>
                                    </div>
                                    <div class="mb-5">
                                        <h2 class="cta-1 mb-0 text-primary">Welcome,</h2>
                                        <h2 class="cta-1 text-primary">let's get started!</h2>
                                    </div>
                                    <div class="mb-5">
                                        <p class="h6">Please use your credentials to login.</p>
                                    </div>
                                    <div>
                                        <div class="mb-3 filled form-group tooltip-end-top">
                                            <i data-acorn-icon="email"></i>
                                            <input class="form-control" placeholder="Email" name="email" id="email"/>
                                        </div>
                                        <div class="mb-3 filled form-group tooltip-end-top">
                                            <i data-acorn-icon="lock-off"></i>
                                            <input class="form-control pe-7" name="password" type="password" placeholder="Password" id="password"/>
                                            {/*<a class="text-small position-absolute t-3 e-3" href="ja">Forgot?</a>*/}
                                        </div>
                                        <button type="submit" class="btn btn-lg btn-primary" onClick={() => signin()}>Login</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;