import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import { get, post, put, deleteM } from "../../utility/Adapter";
import DataTable from 'react-data-table-component';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Staffs = () => {

    const [staffs, setStaffs] = useState([]);
    const [pending, setPending] = React.useState(true);
    const [staffId, setStaffId] = useState(0);

    Element.prototype.insertAfter = function (newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    useEffect(function () {
        _loadStaffs();
    }, []);

    const _loadStaffs = () => {
        get('admin/staffs').then(async function (response) {
            setStaffs(response.data.data);
            setPending(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _save = () => {
        var ele = document.querySelectorAll(".error");

        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }

        var name = document.getElementById('name').value;
        var phone = document.getElementById('phone').value;
        var email = document.getElementById('email').value;
        var website = document.getElementById('website').value;
        var password = document.getElementById('password').value;

        if (name.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter name";
            document.getElementById('name').insertAfter(errorElement);
            return false;
        }
        else if (phone.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter phone";
            document.getElementById('phone').insertAfter(errorElement);
            return false;
        }
        else if (password.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter password";
            document.getElementById('password').insertAfter(errorElement);
            return false;
        }
        else if (!validateEmail(email)) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter email";
            document.getElementById('email').insertAfter(errorElement);
            return false;
        }

        var raw = JSON.stringify({
            "email": email,
            "name": name,
            "phone": phone,
            "designation": website,
            "password": password
        });

        post('admin/staffs', raw).then(async function (response) {
            alert('Successfully created!');
            _loadStaffs();
            window.$("#exampleModal").modal('toggle');
        }).catch(function (error) {
            alert('Email address is already exists');
        });
    }

    const _change = (id) => {
        setStaffId(id);
        window.$("#changeModal").modal('toggle');
        //data-bs-toggle="modal" data-bs-target="#changeModal"
    }

    const _changePassword = () => {
        var ele = document.querySelectorAll(".error");

        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }

        var password = document.getElementById('change_password').value;
        var confirm_password = document.getElementById('confirm_password').value;

        if (password.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter password";
            document.getElementById('change_password').insertAfter(errorElement);
            return false;
        }

        if (password != confirm_password) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Password and confirm password do not match";
            document.getElementById('confirm_password').insertAfter(errorElement);
            return false;
        }

        var raw = JSON.stringify({
            "confirm_password": confirm_password,
            "password": password,
            "id": staffId
        });

        put('admin/staff_change', raw).then(async function (response) {
            alert('Successfully changed!');
            window.$("#changeModal").modal('toggle');
        }).catch(function (error) {
            console.log("error", error)
        });
    }


    return (
        <>
            <Header />

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Staff</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Staff Name</span>
                                <input class="form-control" id="name" placeholder="Staff name" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Staff Email</span>
                                <input class="form-control" id="email" placeholder="Staff email" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Staff Phone</span>
                                <input class="form-control" id="phone" placeholder="Staff phone" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Staff Designation</span>
                                <input class="form-control" id="website" placeholder="Staff designation" type="text" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Staff Password</span>
                                <input class="form-control" id="password" placeholder="Staff password" type="password" />
                            </label>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => _save()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="changeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Change Password</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>New Password</span>
                                <input class="form-control" id="change_password" placeholder="New password" type="password" />
                            </label>

                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Confirm Password</span>
                                <input class="form-control" id="confirm_password" placeholder="Confirm password" type="password" />
                            </label>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => _changePassword()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        <div class="col">
                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">Staffs</h1>

                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>

                                    <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                                        <button data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" class="btn btn-outline-primary btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto" id="newContactButton">
                                            <i data-acorn-icon="plus" class="icon" data-acorn-size="18"></i>
                                            <span className='ml-2'>Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}

                            <div className='col mb-5'>
                                <div class="mb-1 mb-lg-1 bg-transparent no-shadow d-none d-lg-block">
                                    <div class="row g-0">
                                        <div class="col-auto sw-0 d-none d-lg-flex"></div>
                                        <div class="col">
                                            <div class="ps-0 ps-5 pe-3 pt-0 pb-0 h-100">
                                                <div class="row g-0 h-100 align-content-center custom-sort">
                                                    <div class="col-3 col-lg-4 d-flex flex-column mb-lg-0 pe-3 d-flex">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="name">NAME</div>
                                                    </div>
                                                    <div class="col-3 col-lg-2 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="email">EMAIL</div>
                                                    </div>
                                                    <div class="col-3 col-lg-2 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="phone">PHONE</div>
                                                    </div>
                                                    <div class="col-3 col-lg-1 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="group">DESIGNATION</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                staffs.map((t, k) =>
                                    <div class="card mb-2 mt-2">
                                        <div class="d-none id">16</div>
                                        <div class="row g-0 h-100 sh-lg-9 position-relative">

                                            <div class="col py-3 py-sm-3">
                                                <div class="card-body ps-5 pe-4 pt-0 pb-0 h-100">
                                                    <div class="row g-0 h-100 align-content-center">
                                                        <a href={"#"} class="col-11 col-lg-3 d-flex flex-column mb-lg-0 mb-3 mb-lg-0 pe-3 d-flex order-1 view-click justify-content-center">
                                                            <div class="name text-capitalize" id="contactName">
                                                                {t.staff_name}
                                                            </div>
                                                        </a>
                                                        <div class="col-12 col-lg-3 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                                                            <div class="lh-1 text-alternate email" id="contactEmail">{t.staff_email}</div>
                                                        </div>
                                                        <div class="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4">
                                                            <div class="lh-1 text-alternate phone" id="contactPhone">
                                                                {t.staff_phone}
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4">
                                                            <div class="lh-1 text-alternate phone" id="contactPhone">
                                                                {t.staff_designation}
                                                            </div>
                                                        </div>

                                                        <div class="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-end order-4">
                                                            <div class="dropdown-as-select d-inline-block" data-childSelector="span">
                                                                <button class="btn btn-icon btn-icon-only btn-link btn-sm mt-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-more-vertical mb-3 d-inline-block text-primary"><path d="M10 9C10.5523 9 11 9.44772 11 10V10C11 10.5523 10.5523 11 10 11V11C9.44772 11 9 10.5523 9 10V10C9 9.44772 9.44772 9 10 9V9zM10 2C10.5523 2 11 2.44772 11 3V3C11 3.55228 10.5523 4 10 4V4C9.44772 4 9 3.55228 9 3V3C9 2.44772 9.44772 2 10 2V2zM10 16C10.5523 16 11 16.4477 11 17V17C11 17.5523 10.5523 18 10 18V18C9.44772 18 9 17.5523 9 17V17C9 16.4477 9.44772 16 10 16V16z"></path></svg>
                                                                </button>

                                                                <div class="dropdown-menu dropdown-menu-end">
                                                                    <a class="dropdown-item active" href="#" onClick={() => _change(t._id)}>Change Password</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                staffs.length === 0 &&
                                <div className='text-center'>
                                    No Staffs Found!
                                </div>
                            }


                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Staffs;