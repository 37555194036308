import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import { get, post, put, deleteM } from "../../utility/Adapter";
import DataTable from 'react-data-table-component';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Contacts = () => {

    const [c, setC] = useState([]);


    useEffect(function () {
        _loadContacts();
        //window.$('[data-toggle="popover"]').popover();
    }, []);

    const _loadContacts = () => {
        get('contacts').then(async function (response) {
            setC(response.data.data);

            setTimeout(function () {
                window.$('[data-toggle="popover"]').popover();
            }, 500);

            var table = window.$('#datatableRows').DataTable();
            table.destroy();

            setTimeout(function () {
                var uninitialized = window.$('#datatableRows').filter(function () {
                    return !window.$.fn.DataTable.fnIsDataTable(this);
                });

                uninitialized.each(function () {
                    window.$(this).dataTable(
                        {
                            dom: 'Bfrtip',
                            buttons: [
                                {
                                    extend: 'excel',
                                    text: 'Export Excel',
                                    className: 'btn btn-light',
                                    exportOptions: {
                                        columns: 'th:not(:last-child)'
                                    }
                                },
                                {
                                    extend: 'csv',
                                    text: 'Export Csv',
                                    className: 'btn btn-light',
                                    exportOptions: {
                                        columns: 'th:not(:last-child)'
                                    }
                                },

                            ]
                        }
                    );
                });
            }, 100)





        }).catch(function (error) {
            console.log(error);
        });
    }



    return (
        <>
            <Header />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        <div class="col">
                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">Enquiries - From Web Contact </h1>

                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>

                                </div>
                            </div>
                            {/* Title and Top Buttons End */}




                            <div class="data-table-rows slim">
                                <div class="data-table-responsive-wrapper">
                                    <table id="datatableRows" class="data-table nowrap hover">
                                        <thead>
                                            <tr>
                                                <th class="text-muted text-small text-uppercase">#</th>
                                                <th class="text-muted text-small text-uppercase">Name</th>
                                                <th class="text-muted text-small text-uppercase">Mobile</th>
                                                <th class="text-muted text-small text-uppercase">Email</th>
                                                <th class="text-muted text-small text-uppercase">Message</th>
                                                <th class="text-muted text-small text-uppercase">Created</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                c.map((contact, k) =>
                                                <tr>
                                                <td>{k+1}</td>
                                                <td className='text-capitalize'>{contact.name}</td>
                                                <td>{contact.mobile}</td>
                                                <td>{contact.email}</td>
                                                <td>{contact.message}</td>
                                                <td>{contact.date}</td>
                                                </tr>
                                                )
                                            }
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default Contacts;