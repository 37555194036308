import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import { get, post, put, deleteM } from "../../utility/Adapter";
import DataTable from 'react-data-table-component';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const Countries = () => {

    const [Countries, setCountries] = useState([]);
    const [langs, setLangs] = useState([]);
    const [editLangs, setEditLangs] = useState([]);
    //const alert = useAlert();

    useEffect(function () {
        _loadCountries();
        _loadLanguages();
    }, []);

    const _loadLanguages = () => {
        get('languages').then(async function (response) {
            setLangs(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadCountries = () => {
        get('countries').then(async function (response) {
            setCountries(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    Element.prototype.insertAfter = function (newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const _editData = (data) => {
        setEditLangs(data);
        window.$('#editeModal').modal('show');


        console.table(data)
    }

    const _save = () => {
        var ele = document.querySelectorAll(".error");

        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }


        var country_code = document.getElementById('country_code');
        if (country_code.value.length == 0) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter Country code";
            country_code.insertAfter(errorElement);

            error = true;
            return false;
        }

        var elements = document.getElementsByClassName('hobbi_input');
        var tmp = [];
        var error = false;

        tmp.push({
            country_code: country_code.value
        });

        for (var i = 0; i < elements.length; i++) {
            if (elements[i]) {
                var languageCode = elements[i].getAttribute('data-code');
                var hobbieName = elements[i].value;

                if (hobbieName.length == 0) {

                    var errorElement = document.createElement("div");
                    errorElement.classList.add('error');
                    errorElement.innerHTML = "Please enter Country name";
                    elements[i].insertAfter(errorElement);

                    error = true;
                    return false;
                }


                if (error == false) {
                    tmp.push({
                        "language_code": languageCode,
                        "country_name": hobbieName,
                        "country_code": country_code.value
                    });
                }

            }
        }

        window.$('#exampleModal').modal('hide');

        if (error === false) {
            post('countries', tmp).then(async function (response) {
                //alert.success('Successfully created new countries!');
                _loadCountries();
            }).catch(function (error) {
            });
        }
    }

    const getHobbie = (langaugeCode) => {
        if (editLangs._data == undefined) {
            return '';
        }

        for (var i = 0; i < editLangs._data.length; i++) {
            console.log(editLangs._data[i].language_code);

            if (editLangs._data[i].language_code == langaugeCode) {
                return editLangs._data[i].country_name;
            }
        }
    }

    const getId = (langaugeCode) => {
        if (editLangs._data == undefined) {
            return '0';
        }

        for (var i = 0; i < editLangs._data.length; i++) {
            console.log(editLangs._data[i].language_code);

            if (editLangs._data[i].language_code == langaugeCode) {
                return editLangs._data[i]._id;
            }
        }

        return 0;
    }

    const getHobbyId = (langaugeCode) => {
        if (editLangs._data == undefined) {
            return '0';
        }

        for (var i = 0; i < editLangs._data.length; i++) {
            console.log(editLangs._data[i].language_code);

            if (editLangs._data[i].language_code == langaugeCode) {
                return editLangs._data[i].country_id;
            }
        }

        return 0;
    }

    const _editSave = () => {
        var ele = document.querySelectorAll(".error");

        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }


        var elements = document.getElementsByClassName('hobbi_edit_input');
        var tmp = [];
        var error = false;

        for (var i = 0; i < elements.length; i++) {
            if (elements[i]) {
                var languageCode = elements[i].getAttribute('data-code');
                var mainHobbyId = elements[i].getAttribute('data-id');
                var hobbyId = elements[i].getAttribute('data-hobby-id');
                var hobbieName = elements[i].value;

                if (hobbieName.length == 0) {

                    var errorElement = document.createElement("div");
                    errorElement.classList.add('error');
                    errorElement.innerHTML = "Please enter Country name";
                    elements[i].insertAfter(errorElement);

                    error = true;
                    return false;
                }


                if (error == false) {
                    tmp.push({
                        "language_code": languageCode,
                        "country_name": hobbieName,
                        "country_id": hobbyId,
                        "parent_country_id": mainHobbyId
                    });
                }

            }
        }

        window.$('#editeModal').modal('hide');

        if (error === false) {
            put('countries', tmp).then(async function (response) {
                _loadCountries();
                //alert.success('Successfully updated!');
            }).catch(function (error) {
            });
        }
    }

    const _deleteCountry = (_id) => {
        var data = {}

        deleteM('countries/' + _id, data).then(async function (response) {
            _loadCountries();
            //alert.success('Successfully deleted!');
        }).catch(function (error) {
        });
    }

    const _deleteConfirmation = (_id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => _deleteCountry(_id)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    return (
        <>
            <Header />



            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Country</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <label class="mb-3 top-label">
                                <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter Country Code</span>
                                <input class="form-control" placeholder={"Country code"} type="text" id="country_code" />
                            </label>
                            {
                                langs.map((l, k) =>
                                    <label class="mb-3 top-label">
                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter Country name in {l.language_name}</span>
                                        <input class="form-control hobbi_input" data-code={l.language_code} placeholder={"Country name (" + l.language_name + ")"} type="text" />
                                    </label>
                                )
                            }
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => _save()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="editeModal" tabindex="-1" aria-labelledby="editeModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editeModalLabel">Edit Country</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {
                                langs.map((l, k) =>
                                    <label class="mb-3 top-label">
                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Country name in {l.language_name}</span>
                                        <input class="form-control hobbi_edit_input" data-code={l.language_code} data-hobby-id={getHobbyId(l.language_code)} data-id={getId(l.language_code)} placeholder="Country Name" type="text" defaultValue={getHobbie(l.language_code)} />
                                    </label>
                                )
                            }
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => _editSave()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        <div class="col">
                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">Countries</h1>
                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>

                                    <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                                        <button data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" class="btn btn-outline-primary btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto" id="newContactButton">
                                            <i data-acorn-icon="plus" class="icon" data-acorn-size="18"></i>
                                            <span className='ml-2'>Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}

                            <div className='col'>
                                <div class="mb-1 mb-lg-1 bg-transparent no-shadow d-none d-lg-block">
                                    <div class="row g-0">
                                        <div class="col-auto sw-0 d-none d-lg-flex"></div>
                                        <div class="col">
                                            <div class="ps-0 ps-1 pe-4 pt-0 pb-0 h-100">
                                                <div class="row g-0 h-100 align-content-center custom-sort">
                                                    <div class="col-3 col-lg-4 d-flex flex-column mb-lg-0 pe-3 d-flex">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="name">COUNTRY CODE</div>
                                                    </div>
                                                    <div class="col-3 col-lg-3 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer sort" data-sort="email">COUNTRY</div>
                                                    </div>
                                                    <div class="col-3 col-lg-3 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer" data-sort="phone"></div>
                                                    </div>
                                                    <div class="col-3 col-lg-1 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="text-muted text-small cursor-pointer" data-sort="group"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                Countries.map((h, k) =>
                                    <>
                                        <div className='card mt-2 mb-2 pt-0 pb-0' key={k}>
                                            <div className='card-body pt-3 pb-3'>
                                                <div class="row g-0 h-100 align-content-center custom-sort">
                                                    <div key={k} class="col-3 col-lg-1 d-flex flex-column mb-lg-0 pe-3 d-flex justify-content-center">
                                                        <div class="cursor-pointer" data-sort="name">{h._country_code}</div>
                                                    </div>
                                                    {
                                                        h &&
                                                        h._data.map((l, k) =>
                                                            <>
                                                                <div key={k} class="col-3 col-lg-3 d-flex flex-column mb-lg-0 pe-3 d-flex justify-content-center">
                                                                    <div class="cursor-pointer" data-sort="name">{l.country_name} [ {l.language_name} ]</div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    <div class="col-3 col-lg-1 d-flex flex-column pe-1 justify-content-center">
                                                        <div class="btn-group me-1 check-all-container-checkbox-click" id="checkAllButton">
                                                            <button
                                                                onClick={() => _editData(h)}
                                                                type="button"
                                                                class="btn btn-outline-primary btn-icon btn-icon-only me-1 me-lg-auto"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="bottom"
                                                                title="Edit"
                                                                data-delay='{"show":"1000", "hide":"0"}'
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-edit mb-3 d-inline-block" style={{ color: '#646666' }}><path d="M14.6264 2.54528C15.0872 2.08442 15.6782 1.79143 16.2693 1.73077C16.8604 1.67011 17.4032 1.84674 17.7783 2.22181C18.1533 2.59689 18.33 3.13967 18.2693 3.73077C18.2087 4.32186 17.9157 4.91284 17.4548 5.3737L6.53226 16.2962L2.22192 17.7782L3.70384 13.4678L14.6264 2.54528Z"></path></svg>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                class="btn btn-outline-primary btn-icon btn-icon-only me-1 me-lg-auto"
                                                                onClick={() => _deleteConfirmation(h._country_id)}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-bin mb-3 d-inline-block" style={{ color: '#646666' }}><path d="M4 5V14.5C4 15.9045 4 16.6067 4.33706 17.1111C4.48298 17.3295 4.67048 17.517 4.88886 17.6629C5.39331 18 6.09554 18 7.5 18H12.5C13.9045 18 14.6067 18 15.1111 17.6629C15.3295 17.517 15.517 17.3295 15.6629 17.1111C16 16.6067 16 15.9045 16 14.5V5"></path><path d="M14 5L13.9424 4.74074C13.6934 3.62043 13.569 3.06028 13.225 2.67266C13.0751 2.50368 12.8977 2.36133 12.7002 2.25164C12.2472 2 11.6734 2 10.5257 2L9.47427 2C8.32663 2 7.75281 2 7.29981 2.25164C7.10234 2.36133 6.92488 2.50368 6.77496 2.67266C6.43105 3.06028 6.30657 3.62044 6.05761 4.74074L6 5"></path><path d="M2 5H18M12 9V13M8 9V13"></path></svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                        </div>
                    </div>
                </div>
            </main>


            <Footer/>
        </>
    )
}

export default Countries;