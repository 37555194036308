import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader";
import { get, post, put, deleteM } from "../../utility/Adapter";
import { withParams } from "../HOC";

const Employers = () => {
  const [loading, setLoading] = useState(false);
  const [employers, setEmployers] = useState([]);

  useEffect(function () {
    _loadEmployers();
  }, []);

  const _loadEmployers = () => {
    get("admin/employers")
      .then(async function (response) {
        setEmployers(response.data.data);
        setLoading(false);

        var table = window.$('#datatableRows').DataTable();
        table.destroy();

        setTimeout(function () {

          var uninitialized = window.$('#datatableRows').filter(function () {
            return !window.$.fn.DataTable.fnIsDataTable(this);
          });

          uninitialized.each(function () {
            window.$(this).dataTable(
              {
                dom: 'Bfrtip',
                buttons: [
                  {
                    extend: 'excel',
                    text: 'Export Excel',
                    className: 'btn btn-light',
                    exportOptions: {
                      columns: 'th:not(:last-child)'
                    }
                  },
                  {
                    extend: 'csv',
                    text: 'Export Csv',
                    className: 'btn btn-light',
                    exportOptions: {
                      columns: 'th:not(:last-child)'
                    }
                  },

                ]
              }
            );
          });
        }, 100);


      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  Element.prototype.insertAfter = function (newEl) {
    this.parentNode.insertBefore(newEl, this.nextSibling);
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const _save = () => {
    var ele = document.querySelectorAll(".error");

    for (var j = 0; j < ele.length; j++) {
      ele[j].remove();
    }

    var name = document.getElementById("name").value;
    var phone = document.getElementById("phone").value;
    var email = document.getElementById("email").value;
    var agency = document.getElementById("agency").value;
    var password = document.getElementById("password").value;

    if (name.length == 0) {
      var errorElement = document.createElement("div");
      errorElement.classList.add("error");
      errorElement.innerHTML = "Please enter name";
      document.getElementById("name").insertAfter(errorElement);
      return false;
    } else if (phone.length == 0) {
      var errorElement = document.createElement("div");
      errorElement.classList.add("error");
      errorElement.innerHTML = "Please enter phone";
      document.getElementById("phone").insertAfter(errorElement);
      return false;
    } else if (password.length == 0) {
      var errorElement = document.createElement("div");
      errorElement.classList.add("error");
      errorElement.innerHTML = "Please enter password";
      document.getElementById("password").insertAfter(errorElement);
      return false;
    } else if (!validateEmail(email)) {
      var errorElement = document.createElement("div");
      errorElement.classList.add("error");
      errorElement.innerHTML = "Please enter email";
      document.getElementById("email").insertAfter(errorElement);
      return false;
    }

    var raw = JSON.stringify({
      email: email,
      name: name,
      phone: phone,
      agency: agency,
      password: password,
    });

    post("admin/employer", raw)
      .then(async function (response) {

        document.getElementById("name").value = "";
        document.getElementById("phone").value = "";
        document.getElementById("email").value = "";
        document.getElementById("agency").value = "";
        document.getElementById("password").value = "";

        alert("Successfully created!");
        _loadEmployers();
        window.$("#exampleModal").modal("toggle");
      })
      .catch(function (error) {
        alert("Email address is already exists");
      });
  };

  return (
    <>
      <Header />
      <Loader loading={loading} />

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Add New Employer
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <label class="mb-3 top-label">
                <span
                  style={{
                    color: "rgb(94, 92, 92)",
                    textTransform: "uppercase",
                  }}
                >
                  Employer Name
                </span>
                <input
                  class="form-control"
                  id="name"
                  placeholder="Employer name"
                  type="text"
                />
              </label>

              <label class="mb-3 top-label">
                <span
                  style={{
                    color: "rgb(94, 92, 92)",
                    textTransform: "uppercase",
                  }}
                >
                  Employer Email
                </span>
                <input
                  class="form-control"
                  id="email"
                  placeholder="Employer email"
                  type="email"
                />
              </label>

              <label class="mb-3 top-label">
                <span
                  style={{
                    color: "rgb(94, 92, 92)",
                    textTransform: "uppercase",
                  }}
                >
                  Employer Phone
                </span>
                <input
                  class="form-control"
                  id="phone"
                  placeholder="Employer phone"
                  type="number"
                />
              </label>

              <label class="mb-3 top-label">
                <span
                  style={{
                    color: "rgb(94, 92, 92)",
                    textTransform: "uppercase",
                  }}
                >
                  Employer Agency Name
                </span>
                <input
                  class="form-control"
                  id="agency"
                  placeholder="Employer agency name"
                  type="text"
                />
              </label>

              <label class="mb-3 top-label">
                <span
                  style={{
                    color: "rgb(94, 92, 92)",
                    textTransform: "uppercase",
                  }}
                >
                  Employer Password
                </span>
                <input
                  class="form-control"
                  id="password"
                  placeholder="Employer password"
                  type="password"
                />
              </label>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => _save()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <main>
        <div class="container-fluid">
          <div class="row">
            <SideBar />

            <div class="col">
              {/* Title and Top Buttons Start */}
              <div class="page-title-container">
                <div class="row g-0">
                  <div class="col-auto mb-2 mb-md-0 me-auto">
                    <div class="w-auto sw-md-30">
                      <h1 class="mb-0 pb-0 display-4" id="title">
                        Employers
                      </h1>
                    </div>
                  </div>
                  <div class="w-100 d-md-none"></div>

                  <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      type="button"
                      class="btn btn-outline-primary btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto"
                      id="newContactButton"
                    >
                      <i
                        data-acorn-icon="plus"
                        class="icon"
                        data-acorn-size="18"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      ></i>
                      <span className="ml-2">Add New</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* Title and Top Buttons End */}


              <div class="data-table-rows slim">
                <div class="data-table-responsive-wrapper">
                  <table id="datatableRows" class="data-table nowrap hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Agency</th>
                        <th>Created By</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employers.map((e, k) => (
                        <tr>
                          <td>{k + 1}</td>
                          <td class="text-capitalize">{e.employer_name}</td>
                          <td>{e.employer_email}</td>
                          <td>{e.employer_phone}</td>
                          <td class="text-capitalize">{e.employer_agency}</td>
                          <td>
                            {e.employer_created_by == 1 ? "Admin" : "Self"}
                          </td>
                          <td>
                            <a
                              title="View Enquiries"
                              style={{ color: "#000" }}
                              href={"/employer/enquiries/" + e._id}
                            >
                              <span
                                class="badge bg-outline-primary group"
                                id="contactGroup"
                              >
                                Enquiries
                              </span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default withParams(Employers);
