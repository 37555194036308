import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';

import { get, post, put, deleteM } from "../../utility/Adapter";
import { createError, removeErrors } from "../../utility/Validation";

const Profile = () => {

    const [loading, setLoading] = useState(false);
    const [saveBtn, setSaveBtn] = useState(false);
    const [myProfile, setMyProfile] = useState({
        candidate_education_details: {

        },
        candidate_nurse_course: {

        }
    });
    const [countries, setCountries] = useState([]);
    const [nationality, setNationality] = useState([]);
    const [speaking, setSpeaking] = useState([]);
    const [hobbies, setHobbies] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [languageSkills, setLanguageSkills] = useState([]);

    useEffect(() => {
        _loadProfile();
        _loadCountry();
        _loadNationality();
        _loadSpeaking();
        _loadHobbies();
        _loadDesignations();
    }, []);

    const _loadProfile = () => {
        get('candidates/profile').then(async function (response) {
            setMyProfile(response.data.data);
            setTimeout(function () {
                document.getElementById("country").value = response.data.data.candidate_residency;
                document.getElementById("nationality").value = response.data.data.candidate_nationality;

                if (response.data.data.candidate_gender != undefined)
                    document.getElementById("gender").value = response.data.data.candidate_gender;

                if (response.data.data.candidate_family_status != undefined)
                    document.getElementById("family_status").value = response.data.data.candidate_family_status;

                if (response.data.data.candidate_education_details.country != undefined) {
                    document.getElementById("hs_country").value = response.data.data.candidate_education_details.country;
                }

                if (response.data.data.candidate_nurse_course != undefined) {
                    if (response.data.data.candidate_nurse_course.course != undefined) {
                        document.getElementById("nc_course").value = response.data.data.candidate_nurse_course.course;
                    }

                    if (response.data.data.candidate_nurse_course.country != undefined) {
                        document.getElementById("nc_country").value = response.data.data.candidate_nurse_course.country;
                    }
                }

                if (response.data.data.candidate_professional != undefined) {
                    for (var j=0; j < response.data.data.candidate_professional.length; j++) {
                        document.getElementById("experience_start_" + j).value = response.data.data.candidate_professional[j].experience_start;
                        document.getElementById("experience_end_" + j).value = response.data.data.candidate_professional[j].experience_end;
                        document.getElementById("experience_designation_" + j).value = response.data.data.candidate_professional[j].designation_id;
                        document.getElementById("experience_employer_" + j).value = response.data.data.candidate_professional[j].employer_name;
                        document.getElementById("experience_bed_capacity_" + j).value = response.data.data.candidate_professional[j].bed_capacity;
                    }
                }
                
                if (response.data.data.candidate_language_level != undefined) {
                    for (var j = 0; j < response.data.data.candidate_language_level.length; j++) {
                        var tmp = {
                            name: response.data.data.candidate_language_level[j].speaking_name,
                            language: response.data.data.candidate_language_level[j].language,
                            level: response.data.data.candidate_language_level[j].candidate_language_level
                        }
            
                        setLanguageSkills([...languageSkills, tmp]);
                    }
                }

                if (response.data.data.candidate_hobbies != undefined) {
                    for (var j = 0; j < response.data.data.candidate_hobbies.length; j++) {
                        document.getElementById("hb_" + response.data.data.candidate_hobbies[j].hobby_id).checked = true;
                    }
                }
                

            }, 100)

        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadCountry = () => {
        get('countries/en').then(async function (response) {
            setCountries(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadNationality = () => {
        get('nationality/en').then(async function (response) {
            setNationality(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadSpeaking = () => {
        get('speaking/en').then(async function (response) {
            setSpeaking(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadHobbies = () => {
        get('hobbies/en').then(async function (response) {
            setHobbies(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadDesignations = () => {
        get('destinations/en').then(async function (response) {
            setDesignations(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _addLanguageSkills = () => {
        var language = document.getElementById('language');
        var language_level = document.getElementById('language_level').value;

        if (language.value) {
            var tmp = {
                name: language.options[language.selectedIndex].text,
                language: language.value,
                level: language_level
            }

            setLanguageSkills([...languageSkills, tmp]);
        }
    }

    const enableEdit = (e) => {
        setSaveBtn(true)
    }

    Element.prototype.insertAfter = function (newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const save = (e) => {
        removeErrors();
        var error = false;

        var elements = document.getElementsByClassName('required');
        for (var i = 0; i < elements.length; i++) {
            var error_message = elements[i].getAttribute('data-error');

            if (elements[i].value == "") {
                createError(elements[i], error_message);
                error = true;
            }
        }

        if (error == false) {

            var first_name = document.getElementById('first_name').value;
            var last_name = document.getElementById('last_name').value;
            var phone = document.getElementById('phone').value;
            var email = document.getElementById('email').value;
            var gender = document.getElementById('gender').value;
            var family_status = document.getElementById('family_status').value;
            var country = document.getElementById('country').value;
            var nationality = document.getElementById('nationality').value;
            var passport_number = document.getElementById('passport_number').value;
            var passport_expiry_date = document.getElementById('passport_expiry_date').value;
            var present_address = document.getElementById('present_address').value;
            var present_address_passport = document.getElementById('present_address_passport').value;
            var dob = document.getElementById('dob').value;

            /*EDUCATIONAL DETAILS HIGHER SECONDARY*/
            var hs_start = document.getElementById('hs_start').value;
            var hs_end = document.getElementById('hs_end').value;
            var hs_school = document.getElementById('hs_school').value;
            var hs_university = document.getElementById('hs_universiry').value;
            var hs_state = document.getElementById('hs_state').value;
            var hs_country = document.getElementById('hs_country').value;

            /*EDUCATIONAL DETAILS NURSING*/
            if (myProfile.candidate_degree != 'STUDENT') {
                var nc_start = document.getElementById('nc_start').value;
                var nc_end = document.getElementById('nc_end').value;
                var nc_course = document.getElementById('nc_course').value;
                var nc_college = document.getElementById('nc_college').value;
                var nc_university = document.getElementById('nc_universiry').value;
                var nc_state = document.getElementById('nc_state').value;
                var nc_country = document.getElementById('nc_country').value;


                var experience_start = document.getElementById('experience_start_0').value;
                var experience_end = document.getElementById('experience_end_0').value;
                var experience_designation = document.getElementById('experience_designation_0').value;
                var experience_employer = document.getElementById('experience_employer_0').value;
                var experience_bed_capacity = document.getElementById('experience_bed_capacity_0').value;
            }
            else {
                var nc_start, nc_end, nc_course, nc_college, nc_university, nc_state, nc_country, experience_bed_capacity, experience_employer, experience_designation, experience_start, experience_end = '';
            }


            var languageSkillsCandidate = JSON.stringify(languageSkills);
            var hobbiesElements = document.getElementsByClassName('hobbies');
            var hobbies = [];

            for (var j = 0; j < hobbiesElements.length; j++) {
                if (hobbiesElements[j].checked) {
                    hobbies.push(hobbiesElements[j].value);
                }
            }

            var candidateHobbies = JSON.stringify(hobbies);

            var raw = JSON.stringify({
                "candidate_residency": country,
                "candidate_nationality": nationality,
                "candidate_gender": gender,
                "candidate_family_status": family_status,
                "candidate_passport_details": passport_number,
                "candidate_passport_expiry_date": passport_expiry_date,
                "candidate_address": present_address,
                "candidate_passport_address": present_address_passport,
                "candidate_last_name": last_name,
                "candidate_first_name": first_name,
                "candidate_dob": dob,
                "candidate_hs_start": hs_start,
                "candidate_hs_end": hs_end,
                "candidate_hs_school": hs_school,
                "candidate_hs_university": hs_university,
                "candidate_hs_state": hs_state,
                "candidate_hs_country": hs_country,
                "candidate_nc_start": nc_start,
                "candidate_nc_end": nc_end,
                "candidate_nc_course": nc_course,
                "candidate_nc_college": nc_college,
                "candidate_nc_university": nc_university,
                "candidate_nc_state": nc_state,
                "candidate_nc_country": nc_country,
                "candidate_languageSkills": languageSkills,
                "candidate_hobbies": candidateHobbies,
                "candidate_experience_start": experience_start,
                "candidate_experience_end": experience_end,
                "candidate_experience_designation": experience_designation,
                "candidate_experience_employer": experience_employer,
                "candidate_experience_bed_capacity": experience_bed_capacity
            });


            put('candidates', raw).then(async function (response) {
                setSaveBtn(false);
            }).catch(function (error) {
                console.log(error);
            });

        }
    }

    const experiences = () => {
        var experiences = [];

        for (var i = 1; i <= 20; i++) {
            experiences.push(<option value={i}>{i + " Year's"}</option>);
        }

        return experiences;
    }

    const experienceDetails = () => {
        var data = [];
        var required = 'required';
        for (var j = 0; j < 1; j++) {
            data.push(
                <div className='row'>
                    <div class="col-sm-2 form-group">
                        <label class="mb-3 top-label">
                            <input type="date" id={"experience_start_" + j} class={"form-control " + required} data-error="Enter start date" />
                            <span style={{ color: 'rgb(94, 92, 92)' }}>Start Date</span>
                        </label>
                    </div>

                    <div class="col-sm-2 form-group">
                        <label class="mb-3 top-label">
                            <input type="date" id={"experience_end_" + j} class={"form-control " + required} data-error="Enter end date" />
                            <span style={{ color: 'rgb(94, 92, 92)' }}>End Date</span>
                        </label>
                    </div>

                    <div class="col-sm-2 form-group">
                        <label class="mb-3 top-label">
                            <select class={"form-control " + required} id={"experience_designation_" + j} data-error="Please select designation">
                                {
                                    designations.map((d, k) =>
                                        <option value={d.destination_id}>
                                            {d.destination_name}
                                        </option>
                                    )
                                }
                            </select>
                            <span style={{ color: 'rgb(94, 92, 92)' }}>Designation</span>
                        </label>
                    </div>

                    <div class="col-sm-4 form-group">
                        <label class="mb-3 top-label">
                            <input type="text" id={"experience_employer_" + j} class={"form-control " + required} data-error="Enter employer name" />
                            <span style={{ color: 'rgb(94, 92, 92)' }}>Employer Name</span>
                        </label>
                    </div>

                    <div class="col-sm-2 form-group">
                        <label class="mb-3 top-label">
                            <input type="text" id={"experience_bed_capacity_" + j} class={"form-control " + required} data-error="Enter bed capacity" />
                            <span style={{ color: 'rgb(94, 92, 92)' }}>Bed Capacity</span>
                        </label>
                    </div>
                </div>
            );

            required = '';
        }

        return data;
    }

    return (
        <>
            <Header />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        {/* Page Content Start */}
                        <div class="col">
                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container mb-3">
                                <div class="row">
                                    <div class="col mb-2">
                                        <h1 class="mb-2 pb-0 display-4" id="title">Profile</h1>
                                        <div class="text-muted font-heading text-small">Profile Details</div>
                                    </div>

                                    <div class="col-12 col-sm-auto d-flex align-items-center justify-content-end">
                                        <button type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto" data-bs-toggle="modal" data-bs-target="#largeRightModalExample">
                                            <i data-acorn-icon="plus"></i>
                                            <span>Edit</span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}
                            <div class="row mb-2">
                                <div class="col-sm-3 col-md-3 col-lg-3 col-xxl-3 mb-1">
                                    <img className="img-fluid rounded-md" src="http://localhost/acorn/Acorn/Source/Html/acorn-html-service-provider/src/img/profile/profile-9.webp" style={{ width: '100%' }} />
                                </div>
                                <div class="col-sm-8 col-md-8 col-lg-8 col-xxl-8 mb-1">
                                    <h3 style={{ textTransform: 'capitalize' }}>{myProfile.candidate_first_name} {myProfile.candidate_last_name}</h3>
                                    <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                    </span>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xxl-12 mb-1">
                                    <div className='card mb-5 mt-5'>
                                        <div className='card-body'>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">

                                                    <label class="mb-3 top-label">
                                                        <input type="text" class="form-control required" id="first_name" defaultValue={myProfile.candidate_first_name} data-error="Please enter first name" />
                                                        <span style={{ color: 'rgb(94, 92, 92)' }}>NAME</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <input type="text" class="form-control required" id="last_name" defaultValue={myProfile.candidate_last_name} data-error="Please enter last name" />
                                                        <span style={{ color: 'rgb(94, 92, 92)' }}>LAST NAME</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <input type="text" id="email" class="form-control required" defaultValue={myProfile.candidate_email} readOnly />
                                                        <span style={{ color: 'rgb(94, 92, 92)' }}>EMAIL</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <input type="text" class="form-control" id="phone" defaultValue={myProfile.candidate_phone} readOnly />
                                                        <span style={{ color: 'rgb(94, 92, 92)' }}>PHONE</span>
                                                    </label>


                                                    <label class="mb-3 top-label">
                                                        <input defaultValue={myProfile.candidate_dob} type="date" id="dob" class="form-control required" data-error="Please enter date of birth" />
                                                        <span style={{ color: 'rgb(94, 92, 92)' }}>DATE OF BIRTH</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <select class="form-control required" id="gender" data-error="Please select gender">
                                                            <option value="">Select</option>
                                                            <option value="m">Male</option>
                                                            <option value="f">Female</option>
                                                            <option value="t">Transgender</option>
                                                            <option value="o">Others</option>
                                                        </select>
                                                        <span style={{ color: 'rgb(94, 92, 92)' }}>GENDER</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <select class="form-control required" id="family_status" data-error="Please select family status">
                                                            <option value="">Select</option>
                                                            <option value="s">Single</option>
                                                            <option value="m">Married</option>
                                                            <option value="w">Widowed</option>
                                                            <option value="d">Divorced</option>
                                                        </select>
                                                        <span style={{ color: 'rgb(94, 92, 92)' }}>FAMILY STATUS</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <select name="country" id="country" class="form-control required" defaultValue={myProfile.candidate_residency} data-error="Please select country or region of residence">
                                                            <option value="">Select</option>
                                                            {
                                                                countries.map((c, k) =>
                                                                    <option value={c.country_id} key={k}>
                                                                        {c.country_name}
                                                                    </option>
                                                                )
                                                            }
                                                        </select>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Country or region of residence</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <select name="nationality" id="nationality" class="form-control required" defaultValue={myProfile.candidate_nationality} data-error="Please select nationality">
                                                            <option value="">Select</option>
                                                            {
                                                                nationality.map((c, k) =>
                                                                    <option value={c.nationality_id} key={k}>
                                                                        {c.nationality_name}
                                                                    </option>
                                                                )
                                                            }
                                                        </select>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Nationality</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <label class="mb-3 top-label">
                                                        <input id="passport_number" type="text" class="form-control required" data-error="Please enter passport number" defaultValue={myProfile.candidate_passport_details} />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Passport number</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <input id="passport_expiry_date" type="date" defaultValue={myProfile.candidate_passport_expiry} class="form-control required" data-error="Please enter passport expiry date" />
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Passport Expiry Date</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <textarea id="present_address" class="form-control required" style={{ height: 140 }} data-error="Please enter present address" defaultValue={myProfile.candidate_address}>{myProfile.candidate_address}</textarea>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Present address</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <textarea id="present_address_passport" class="form-control required" style={{ height: 140 }} data-error="Please enter permanent address" defaultValue={myProfile.candidate_passport_address}></textarea>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Permanent address as in passport</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card mb-5 mt-5'>
                                        <div className='card-body'>

                                            <h3 class="mb-2 pb-0 display-6" id="title">Educational Details</h3><hr />
                                            <div class="row">
                                                <div className='col-sm-6'>
                                                    <label class="mb-3 top-label">
                                                        <input type="date" id="hs_start" class="form-control required" data-error="Higher secondary starting month & year" defaultValue={myProfile.candidate_education_details.course_start}/>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Higher secondary course starting month & year </span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <input type="date" id="hs_end" class="form-control required" data-error="Higher secondary ending month & year" defaultValue={myProfile.candidate_education_details.course_end}/>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Higher secondary course ending month & year</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <input type="text" id="hs_school" class="form-control required" data-error="Please enter higher secondary school name" defaultValue={myProfile.candidate_education_details.school_name}/>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Higher secondary school name</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <input type="text" id="hs_universiry" class="form-control required" data-error="Enter your University/ Board name" defaultValue={myProfile.candidate_education_details.board_name}/>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter your University/ Board name</span>
                                                    </label>

                                                    <label class="mb-3 top-label">
                                                        <input type="text" id="hs_state" class="form-control required" data-error="Enter state" defaultValue={myProfile.candidate_education_details.state}/>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter state</span>
                                                    </label>


                                                    <label class="mb-3 top-label">
                                                        <select name="hs_country" id="hs_country" class="form-control required" data-error="Please select country">
                                                            <option value="">Select</option>
                                                            {
                                                                countries.map((c, k) =>
                                                                    <option value={c.country_id} key={k}>
                                                                        {c.country_name}
                                                                    </option>
                                                                )
                                                            }
                                                        </select>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Country</span>
                                                    </label>
                                                </div>
                                                {
                                                    myProfile.candidate_degree != 'STUDENT' &&
                                                    <div className='col-sm-6'>

                                                        <label class="mb-3 top-label">
                                                            <input type="date" id="nc_start" class="form-control required" data-error="Nursing starting month & year" defaultValue={myProfile.candidate_nurse_course.course_start}/>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Nursing course starting month & year</span>
                                                        </label>

                                                        <label class="mb-3 top-label">
                                                            <input type="date" id="nc_end" class="form-control required" data-error="Higher secondary ending month & year" defaultValue={myProfile.candidate_nurse_course.course_end}/>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Nursing course ending month & year</span>
                                                        </label>

                                                        <label class="mb-3 top-label">
                                                            <select class="form-control required" id="nc_course" data-error="Please select nursing course">
                                                                <option value="">Select</option>
                                                                <option value="1">B.Sc Nursing 4 years course</option>
                                                                <option value="2">M.Sc Nursing 2 years course</option>
                                                                <option value="3">Diploma in Nursing/GNM 3.5 years course</option>
                                                                <option value="4">Post Basic B.Sc 2 years course</option>
                                                            </select>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Nursing Course</span>
                                                        </label>

                                                        <label class="mb-3 top-label">
                                                            <input type="text" id="nc_college" class="form-control required" data-error="Enter your college" defaultValue={myProfile.candidate_nurse_course.college_name}/>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter your college name</span>
                                                        </label>

                                                        <label class="mb-3 top-label">
                                                            <input type="text" id="nc_universiry" class="form-control required" data-error="Enter your University/ Board name" defaultValue={myProfile.candidate_nurse_course.board_name}/>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter your University/ Board name</span>
                                                        </label>

                                                        <label class="mb-3 top-label">
                                                            <input type="text" id="nc_state" class="form-control required" data-error="Enter state" defaultValue={myProfile.candidate_nurse_course.state}/>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Enter state</span>
                                                        </label>


                                                        <label class="mb-3 top-label">
                                                            <select name="hs_country" id="nc_country" class="form-control required" data-error="Please select country">
                                                                <option value="">Select</option>
                                                                {
                                                                    countries.map((c, k) =>
                                                                        <option value={c.country_id} key={k}>
                                                                            {c.country_name}
                                                                        </option>
                                                                    )
                                                                }
                                                            </select>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Country</span>
                                                        </label>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    {
                                        myProfile.candidate_degree != 'STUDENT' &&
                                        <div className='card mb-5 mt-5'>
                                            <div className='card-body'>
                                                <h3 class="mb-2 pb-0 display-6" id="title">Professional experience</h3><hr />

                                                <div className='row'>
                                                    <div class=" col-sm-12 form-group">

                                                        <label class="mb-3 top-label">
                                                            <select class="form-control required" id="nc_total_experience" data-error="Please select total year experience">
                                                                <option value="">Select</option>
                                                                <option value="0">Less than 1 year</option>
                                                                {experiences()}
                                                                <option value="21">21+ Year's</option>
                                                            </select>
                                                            <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Select your total years of experience</span>
                                                        </label>

                                                    </div>
                                                </div>

                                                {experienceDetails()}

                                            </div>
                                        </div>
                                    }
                                    <div className='card mb-5 mt-5'>
                                        <div className='card-body'>
                                            <h3 class="mb-2 pb-0 display-6" id="title">Language Skills</h3><hr />
                                            <div className='row'>
                                                <div class=" col-sm-4 form-group">
                                                    <label class="mb-3 top-label">
                                                        <select class="form-control" id="language" data-error="Please select language">
                                                            <option value="">Select</option>
                                                            {
                                                                speaking.map((s, k) =>
                                                                    <option value={s.speaking_id}>{s.speaking_name}</option>
                                                                )
                                                            }
                                                        </select>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Select language</span>
                                                    </label>
                                                </div>
                                                <div class=" col-sm-4 form-group">
                                                    <label class="mb-3 top-label">
                                                        <select class="form-control" id="language_level" data-error="Please select total year experience">
                                                            <option value="">Select</option>
                                                            <option value="A1 - beginner level">A1 - beginner level</option>
                                                            <option value="A2 - elementary level">A2 - elementary level</option>
                                                            <option value="B1 - intermediate level">B1 - intermediate level</option>
                                                            <option value="B2 - upper intermediate level">B2 - upper intermediate level</option>
                                                            <option value="C1 - advanced level">C1 - advanced level</option>
                                                            <option value="C2 - highly competent level">C2 - highly competent level</option>
                                                        </select>
                                                        <span style={{ color: 'rgb(94, 92, 92)', textTransform: 'uppercase' }}>Select your level</span>
                                                    </label>
                                                </div>
                                                <div class=" col-sm-4 form-group">
                                                    <button onClick={() => _addLanguageSkills()} type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto" data-bs-toggle="modal" data-bs-target="#largeRightModalExample">
                                                        <i data-acorn-icon="plus"></i>
                                                        <span>Add</span>
                                                    </button>
                                                </div>

                                                <div style={{ clear: 'both' }}>&nbsp;</div>
                                                {
                                                    languageSkills.map((l, k) =>
                                                        <div className='col-sm-3 form-group'>
                                                            {l.name}<br />
                                                            <span style={{ color: "rgb(142, 141, 141)" }}>{l.level}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='card mb-5 mt-5'>
                                        <div className='card-body'>
                                            <h3 class="mb-2 pb-0 display-6" id="title">Hobbies</h3><hr />
                                            <div className='row'>
                                                {
                                                    hobbies.map((h, k) =>
                                                        <div class=" col-sm-3 form-group">
                                                            <div class="form-check mt-2">
                                                                <input type="checkbox" className="hobbies form-check-input" value={h.hobby_id} id={"hb_" + h.hobby_id} />
                                                                <label class="form-check-label" htmlFor={"hb_" + h.hobby_id} style={{ textTransform: 'capitalize' }}>{h.hobby_name}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-grid gap-2 mb-3">
                                        <button onClick={(e) => save(e)} className="btn btn-primary" type="button">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Page Content End */}
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default Profile;