import React, { useState, useHook, useEffect, useRef } from 'react';
import logo from '../images/logo.svg';
import avatar from '../images/avatar.jpg';

import { get, post, put, deleteM } from "../utility/Adapter";
import Cookies from 'js-cookie';

const Logout = () => {

    useEffect(() => {
        Cookies.remove('hcAdmin');
        window.location = '/login';

    }, []);

    return (
        <>
            Please wait...
        </>
    )
}

export default Logout;