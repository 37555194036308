import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import { get, post, put, deleteM } from "../../utility/Adapter";
import { withParams } from "../HOC";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const TranslatorDetails = (props) => {


    const [details, setDetails] = useState(
        {
            ongoing: [],
            pending: [],
            completed: [],
            waiting: [],
            waiting_payment: [],
            under_review: []
        }
    );

    const [translator, setTranslator] = useState([]);
    const [docData, setDocData] = useState([]);

    useEffect(function () {
        _loadDetails();
    }, []);

    const _loadDetails = () => {
        get('admin/translator/' + props.params.id).then(async function (response) {
            setDetails(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const approvePopup = (proposalNote, amount, obj) => {
        setDocData(obj);
        document.getElementById('proposal_note').value = proposalNote;
        document.getElementById('amount').value = amount;

        //window.$('#exampleModal').toggle();
        window.$('#exampleModal').modal('toggle');
    }

    const approve = () => {
        var amount = document.getElementById('amount').value;

        var raw = JSON.stringify({
            "amount": parseFloat(amount),
            "document_id": docData.document_id,
            "translator_id": docData.transilator_id,
            "candidate_id": docData.candidate_id,
        });

        post('admin/translators/approve', raw).then(async function (response) {
            _loadDetails();
            window.$('#exampleModal').modal('toggle');
            alert('Successfully submitted!');
        }).catch(function (error) {
        });
    }

    const markAsReceived = (amount, obj) => {
        setDocData(obj);
        document.getElementById('received_amount').value = amount;
        window.$('#markAsReceivedModal').modal('toggle');
    }

    const received = () => {
        var amount = document.getElementById('received_amount').value;
        var datetime = document.getElementById('datetime').value;
        var transaction_id = document.getElementById('transaction_id').value;

        var raw = JSON.stringify({
            "amount": parseFloat(amount),
            "document_id": docData.document_id,
            "translator_doc_id": docData.translator_doc_id,
            "translator_id": docData.transilator_id,
            "candidate_id": docData.candidate_id,
            "transaction_id": transaction_id,
            "date": datetime
        });

        post('admin/translators/mark_received', raw).then(async function (response) {
            _loadDetails();
            window.$('#markAsReceivedModal').modal('toggle');
            document.getElementById('received_amount').value = "";
            document.getElementById('datetime').value = "";
            document.getElementById('transaction_id').value = "";

            alert('Successfully submitted!');
        }).catch(function (error) {
        });
    }

    const viewDetails = (obj) => {
        setDocData(obj);
        window.$('#viewDetailsModal').modal('toggle');
    }

    const changeStatus = (obj) => {
        setDocData(obj);
        window.$('#changeStatusModal').modal('toggle');
    }

    const changeDocStatus = () => {
        var change_note = document.getElementById('change_note').value;

        var raw = JSON.stringify({
            "document_id": docData.document_id,
            "translator_doc_id": docData.translator_doc_id,
            "translator_id": docData.transilator_id,
            "candidate_id": docData.candidate_id,
            "note": change_note
        });

        post('admin/translators/change_status', raw).then(async function (response) {
            _loadDetails();
            window.$('#changeStatusModal').modal('toggle');
            alert('Successfully submitted!');
        }).catch(function (error) {
        });
    }

    const download = (id) => {
        window.open(
            process.env.REACT_APP_BASE_URL + 'documents/translated/download/' + id,
            '_blank'
        );
    }

    const accept = (obj) => {
        setDocData(obj);

        confirmAlert({
            title: 'Confirm to approve',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => acceptDoc(obj)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }


    const acceptDoc = (docData) => {
        var raw = JSON.stringify({
            "document_id": docData.document_id,
            "translator_doc_id": docData.translator_doc_id,
            "translator_id": docData.transilator_id,
            "candidate_id": docData.candidate_id,
        });

        console.log(raw);

        post('admin/translators/document_approve', raw).then(async function (response) {
            _loadDetails();
            alert('Successfully submitted!');
        }).catch(function (error) {
        });
    }

    return (
        <>
            <Header />

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelDefault" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabelDefault">Approve Proposal</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <label class="mb-3 top-label">
                                <textarea type="text" id="proposal_note" class="form-control required" data-error="Proposal Note" readOnly />
                                <span style={{ color: 'rgb(94, 92, 92)' }}>PROPOSAL NOTE</span>
                            </label>
                            <label class="mb-3 top-label">
                                <input type="text" id="amount" class="form-control required" data-error="Amount" />
                                <span style={{ color: 'rgb(94, 92, 92)' }}>NEW AMOUNT</span>
                            </label>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => approve()}>Approve</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="viewDetailsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelDefault" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabelDefault">Translation Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <th>Proposal Amount</th>
                                        <td>{docData.amount}</td>
                                    </tr>
                                    <tr>
                                        <th>Proposal Note</th>
                                        <td>{docData.note}</td>
                                    </tr>
                                    <tr>
                                        <th>Approved Amount</th>
                                        <td>{docData.approval_amount}</td>
                                    </tr>
                                    <tr>
                                        <th>Payment Transaction Id</th>
                                        <td>{docData.payment_id}</td>
                                    </tr>
                                    <tr>
                                        <th>Payment Date</th>
                                        <td>{docData.payment_date}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="markAsReceivedModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelDefault" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabelDefault">Mark as amount received from candidate</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <label class="mb-3 top-label">
                                <textarea type="text" id="received_amount" class="form-control required" data-error="Received amount" />
                                <span style={{ color: 'rgb(94, 92, 92)' }}>RECEIVED AMOUNT</span>
                            </label>
                            <label class="mb-3 top-label">
                                <input type="text" id="transaction_id" class="form-control" data-error="Amount" />
                                <span style={{ color: 'rgb(94, 92, 92)' }}>TRANSACTION ID</span>
                            </label>
                            <label class="mb-3 top-label">
                                <input type="date" id="datetime" class="form-control required" data-error="Date" />
                                <span style={{ color: 'rgb(94, 92, 92)' }}>RECEIVED DATE</span>
                            </label>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => received()}>Approve</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="changeStatusModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelDefault" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabelDefault">Change Status</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <label class="mb-3 top-label">
                                <textarea type="text" id="change_note" class="form-control required" data-error="Change Note" />
                                <span style={{ color: 'rgb(94, 92, 92)' }}>NOTE</span>
                            </label>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => changeDocStatus()}>Change Status</button>
                        </div>
                    </div>
                </div>
            </div>

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        <div class="col">

                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4" id="title">Translator Details</h1>

                                        </div>
                                    </div>
                                    <div class="w-100 d-md-none"></div>

                                    {/*<div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-3">
                                        <button data-bs-toggle="modal" data-bs-target="#insertModal" type="button" class="btn btn-outline-primary btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto" id="newContactButton">
                                            <i data-acorn-icon="plus" class="icon" data-acorn-size="18"></i>
                                            <span className='ml-2'>Add New</span>
                                        </button>
                                    </div>*/}
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}

                            <div className='row'>
                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="card hover-border-primary mb-5">
                                        <div class="card-body">
                                            <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3 text-capitalize">
                                                <span>{details.translator_name}</span>
                                            </div>
                                            <div class="text-small text-muted mb-1">NAME</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="card hover-border-primary mb-5">
                                        <div class="card-body">
                                            <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                                                <span>{details.translator_phone}</span>
                                            </div>
                                            <div class="text-small text-muted mb-1">PHONE</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="card hover-border-primary mb-5">
                                        <div class="card-body">
                                            <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                                                <span>0</span>
                                            </div>
                                            <div class="text-small text-muted mb-1">COMPLETED TRANSLATIONS</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="card hover-border-primary mb-5">
                                        <div class="card-body">
                                            <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                                                <span>0</span>
                                            </div>
                                            <div class="text-small text-muted mb-1">PENDING TRANSLATIONS</div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <h2 class="text-small text-uppercase">Waiting Admin Approval [ Proposal submitted ]</h2>

                            <div className='row'>
                                <div class="col-12 col-sm-12 col-lg-12">
                                    <div class="card hover-border-primary mb-5">
                                        <div class="card-body">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Candidate</th>
                                                        <th scope="col">Proposal Amount</th>
                                                        <th scope="col">Days</th>
                                                        <th scope="col">Created</th>
                                                        <th scope="col">Submitted</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        details.waiting.map((d, k) =>
                                                            <tr>
                                                                <th scope="row">
                                                                    <a href={"/candidate/" + d.candidate_id} className='text-capitalize'>
                                                                        {d.candidate_first_name} {d.candidate_last_name}
                                                                    </a>
                                                                </th>
                                                                <td>
                                                                    {d.amount}
                                                                </td>
                                                                <td>
                                                                    {d.days}
                                                                </td>
                                                                <td>{d.createdAt}</td>
                                                                <td>{d.updatedAt}</td>
                                                                <td>
                                                                    <a title="View Documents" style={{ color: 'rgb(0, 0, 0)' }} href={"/candidate/documents/" + d.candidate_id}><span class="badge bg-outline-primary group" id="contactGroup">View Documents</span></a>
                                                                    <a className='ms-2' href="#" onClick={() => approvePopup(d.note, d.amount, d)}><span class="badge bg-outline-success">Approve</span></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    {
                                                        details.waiting.length == 0 &&
                                                        <tr>
                                                            <td colSpan={5} align="center">
                                                                <span className='text-small'>
                                                                    No Data Found!
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h2 class="text-small text-uppercase">Waiting For Payment</h2>

                            <div className='row'>
                                <div class="col-12 col-sm-12 col-lg-12">
                                    <div class="card hover-border-primary mb-5">
                                        <div class="card-body">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Candidate</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Proposal Amount</th>
                                                        <th scope="col">Days</th>
                                                        <th scope="col">Created</th>
                                                        <th scope="col">Submitted</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        details.waiting_payment.map((d, k) =>
                                                            <tr>
                                                                <th scope="row">
                                                                    <a href={"/candidate/" + d.candidate_id} className='text-capitalize'>
                                                                        {d.candidate_first_name} {d.candidate_last_name}
                                                                    </a>
                                                                </th>
                                                                <td>
                                                                    {d.approval_amount}
                                                                </td>
                                                                <td>
                                                                    {d.amount}
                                                                </td>
                                                                <td>
                                                                    {d.days}
                                                                </td>
                                                                <td>{d.createdAt}</td>
                                                                <td>{d.updatedAt}</td>
                                                                <td>
                                                                    <a title="View Documents" style={{ color: 'rgb(0, 0, 0)' }} href={"/candidate/documents/" + d.candidate_id}><span class="badge bg-outline-primary group" id="contactGroup">View Documents</span></a>
                                                                    <a className='ms-2' href="#" onClick={() => markAsReceived(d.approval_amount, d)}><span class="badge bg-outline-success">Mark as received</span></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    {
                                                        details.waiting_payment.length == 0 &&
                                                        <tr>
                                                            <td colSpan={6} align="center">
                                                                <span className='text-small'>
                                                                    No Data Found!
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h2 class="text-small text-uppercase">Ongoing Translations</h2>
                            <div className='row'>
                                <div class="col-12 col-sm-12 col-lg-12">
                                    <div class="card hover-border-primary mb-5">
                                        <div class="card-body">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Candidate</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Translation Days</th>
                                                        <th scope="col">Created</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        details.ongoing.map((d, k) =>
                                                            <>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <a href={"/candidate/" + d.candidate_id} className='text-capitalize'>
                                                                            {d.candidate_first_name} {d.candidate_last_name}
                                                                        </a>
                                                                    </th>
                                                                    <td>
                                                                        {d.approval_amount}
                                                                    </td>
                                                                    <td>
                                                                        {d.days}
                                                                    </td>
                                                                    <td>{d.createdAt}</td>
                                                                    <td>
                                                                        <a title="View Documents" style={{ color: 'rgb(0, 0, 0)' }} href={"/candidate/documents/" + d.candidate_id}><span class="badge bg-outline-primary group" id="contactGroup">View Documents</span></a>
                                                                        <a href='#' onClick={() => viewDetails(d)}><span class="badge bg-outline-info ms-2">View Details</span></a>
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    d.re_translation_note != undefined &&
                                                                    <tr>
                                                                        <td className='text-small'>
                                                                            Re Translation Note:
                                                                        </td>
                                                                        <td colSpan={4} className='text-small'>
                                                                            {d.re_translation_note}
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        details.ongoing.length == 0 &&
                                                        <tr>
                                                            <td colSpan={5} align="center">
                                                                <span className='text-small'>
                                                                    No Data Found!
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h2 class="text-small text-uppercase">Under Review Translations</h2>

                            <div className='row'>
                                <div class="col-12 col-sm-12 col-lg-12">
                                    <div class="card hover-border-primary mb-5">
                                        <div class="card-body">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Candidate</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Days</th>
                                                        <th scope="col">Review Date</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        details.under_review.map((d, k) =>
                                                            <tr>
                                                                <th scope="row">
                                                                    <a href={"/candidate/" + d.candidate_id} className='text-capitalize'>
                                                                        {d.candidate_first_name} {d.candidate_last_name}
                                                                    </a>
                                                                </th>
                                                                <td>
                                                                    {d.approval_amount}
                                                                </td>
                                                                <td>
                                                                    {d.days}
                                                                </td>
                                                                <td>{d.end_date}</td>
                                                                <td>
                                                                    <a title="View Documents" style={{ color: 'rgb(0, 0, 0)' }} href={"/candidate/documents/" + d.candidate_id}><span class="badge bg-outline-primary group" id="contactGroup">View Documents</span></a>
                                                                    {/*<a className='ms-2' href="#" onClick={() => markAsReceived(d.approval_amount, d)}><span class="badge bg-outline-success">Mark as received</span></a>*/}
                                                                    <span class="badge bg-outline-quaternary ms-2" style={{ cursor: 'pointer' }} title="Change Status" onClick={() => changeStatus(d)}>Change Status to Re-Translation</span>
                                                                    <span class="badge bg-outline-info ms-2" onClick={() => download(d.document_id)}>Download Translated</span>
                                                                    <span class="badge bg-outline-success ms-2" onClick={() => accept(d)}>Accept Translation</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    {
                                                        details.under_review.length == 0 &&
                                                        <tr>
                                                            <td colSpan={5} align="center">
                                                                <span className='text-small'>
                                                                    No Data Found!
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h2 class="text-small text-uppercase">Pending Translations</h2>
                            <div className='row'>
                                <div class="col-12 col-sm-12 col-lg-12">
                                    <div class="card hover-border-primary mb-5">
                                        <div class="card-body">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Candidate</th>
                                                        <th scope="col">Translation Amount</th>
                                                        <th scope="col">Translation Days</th>
                                                        <th scope="col">Created</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        details.pending.map((d, k) =>
                                                            <tr>
                                                                <th scope="row">
                                                                    <a href={"/candidate/" + d.candidate_id} className='text-capitalize'>
                                                                        {d.candidate_first_name} {d.candidate_last_name}
                                                                    </a>
                                                                </th>
                                                                <td>
                                                                    {d.amount}
                                                                </td>
                                                                <td>
                                                                    {d.days}
                                                                </td>
                                                                <td>{d.createdAt}</td>
                                                                <td>
                                                                    <a title="View Documents" style={{ color: 'rgb(0, 0, 0)' }} href={"/candidate/documents/" + d.candidate_id}><span class="badge bg-outline-primary group" id="contactGroup">View Documents</span></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    {
                                                        details.pending.length == 0 &&
                                                        <tr>
                                                            <td colSpan={5} align="center">
                                                                <span className='text-small'>
                                                                    No Data Found!
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h2 class="text-small text-uppercase">Completed Translations</h2>
                            <div className='row'>
                                <div class="col-12 col-sm-12 col-lg-12">
                                    <div class="card hover-border-primary mb-5">
                                        <div class="card-body">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Candidate</th>
                                                        <th scope="col">Translation Amount</th>
                                                        <th scope="col">Translation Days</th>
                                                        <th scope="col">Created</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        details.completed.map((d, k) =>
                                                            <tr>
                                                                <th scope="row">
                                                                    <a href={"/candidate/" + d.candidate_id} className='text-capitalize'>
                                                                        {d.candidate_first_name} {d.candidate_last_name}
                                                                    </a>
                                                                </th>
                                                                <td>
                                                                    {d.amount}
                                                                </td>
                                                                <td>
                                                                    {d.days}
                                                                </td>
                                                                <td>{d.createdAt}</td>
                                                                <td>
                                                                    <a title="View Documents" style={{ color: 'rgb(0, 0, 0)' }} href={"/candidate/documents/" + d.candidate_id}><span class="badge bg-outline-primary group" id="contactGroup">View Documents</span></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    {
                                                        details.completed.length == 0 &&
                                                        <tr>
                                                            <td colSpan={5} align="center">
                                                                <span className='text-small'>
                                                                    No Data Found!
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default withParams(TranslatorDetails);

