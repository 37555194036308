import React, { useState, useHook, useEffect, useRef } from "react";
import { get, post, put, deleteM } from "../utility/Adapter";

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader"

import { useTranslation } from "react-i18next";
import "../translations/i18n";

const Enquiries = () => {

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  var languageLevels = {
    NOT_ENTROLLED: "Not yet enrolled",
    A1_ENTROLLED: "A1 Enrolled",
    A1_COMPLETE: "A2 Complete",
    B1_COMPLETE: "B1 Complete",
    B2_COMPLETE: "B2 Complete",
    C1_COMPLETE: "C1 Complete",
    C2_COMPLETE: "C2 Complete",
  };

  var languageLevelsId = [
    { id: "NOT_ENTROLLED", val: t("not_yet_enrolled") },
    { id: "A1_ENTROLLED", val: t("a1_entrolled") },
    { id: "A1_COMPLETE", val: "A2 " + t("complete") },
    { id: "B1_COMPLETE", val: "B1 " + t("complete") },
    { id: "B2_COMPLETE", val: "B2 " + t("complete") },
    /*{ id: "C1_COMPLETE", val: "C1 " + t("complete") },
    { id: "C2_COMPLETE", val: "C2 " + t("complete") },*/
  ];

  var candidateType = [
    {
      id: "GNM",
      val: t("GNM"),
    },
    {
      id: "NURSE",
      val: t("Nurse"),
    },
    {
      id: "REGISTERED_NURSE",
      val: t("registered_nurse"),
    },
    {
      id: "STUDENT",
      val: t("current_student"),
    },
    {
      id: "NONE",
      val: t("no_nursing_degree"),
    },
  ];

  const _generate = () => {
    var type = document.getElementsByName('type');
    var language = document.getElementsByName('language[]');
    var documents = document.getElementsByName('documents');
    var degree = document.getElementsByName('degree');
    var gender = document.getElementsByName('gender[]');

    var filterType = "";
    var filterLanguage = [];
    var filterDocuments = "";
    var filterDegree = "";
    var filterGender = "";

    filterType = type[0].value;

    for (var i = 0; i < language.length; i++) {
      if (language[i].checked) {
        filterLanguage.push(language[i].value);
      }
    }
    //console.log(filterLanguage);
    filterDocuments = documents[0].value;
    filterDegree = degree[0].value;

    for (var i = 0; i < gender.length; i++) {
      if (gender[i].checked) {
        filterGender = gender[i].value;
      }
    }

    //setLoading(true);
    var raw = JSON.stringify({
      "filterType": filterType,
      "filterLanguage": JSON.stringify(filterLanguage),
      "filterDocuments": filterDocuments,
      "filterDegree": filterDegree,
      "filterGender": filterGender,
    });

    setLoading(true);
    post('employer/generate', raw).then(async function (response) {
      setLoading(false);

      success({
        title: t("success"),
        text:
          t("success_generated"),
        modules: new Map([
          [
            Confirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Ok",
                  primary: true,
                  click: notice => {
                    notice.close();
                    window.location = '/employer';
                  }
                }
              ]
            }
          ]
        ])
      });

    }).catch(function (error) {
      setLoading(false);
      console.log(error);
    });


  }

  return (
    <>
      <Header />
      <Loader loading={loading} />
      <main>
        <div class="container-fluid">
          <div class="row">
            {/* Menu Start */}
            <SideBar />
            {/*  Menu End */}

            {/* Page Content Start */}
            <div class="col">
              {/* Title and Top Buttons Start */}
              <div class="page-title-container mb-3">
                <div class="row">
                  <div class="col mb-2">
                    <h1 class="mb-2 pb-0 display-4" id="title">
                      <i
                        data-acorn-icon="send"
                        class="icon"
                        data-acorn-size="28"
                      ></i>
                      &nbsp;{t("generate")} {t("enquiries")}
                    </h1>
                    <div class="text-muted font-heading text-small"></div>
                  </div>
                </div>
              </div>
              {/* Title and Top Buttons End */}

              <div class="card mb-5">
                <div class="card-body">

                  <div className="row">
                    <div className="col-lg-4">
                      <h4>{t("by_candidate_type")}</h4>
                      <br />

                      <select className="form-control" name="type">
                        <option value={""}>Select</option>
                        {candidateType.map((c, k) => (
                          <option value={c.id}>
                            {c.val}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-8">
                      <h4>{t("by_candidate_language")}</h4>
                      <br />
                      {languageLevelsId.map((l, k) => (
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id={l.id}
                            value={l.id}
                            name="language[]"
                          //onChange={(e) => _filterByLang(e)}
                          />
                          <label class="form-check-label" for={l.id}>
                            {l.val}
                          </label>
                        </div>
                      ))}
                    </div>

                  </div>

                  <div className="row mt-5">
                    <div className="col-lg-4 ">
                      <h4>{t("by_candidate_documents")}</h4>
                      <br />

                      <select className="form-control" name="documents">
                        <option value={""}>Select</option>
                        <option value={"trans_processing"}>
                          {t("translation_processing")}
                        </option>
                        <option value={"trans_completed"}>
                          {t("translation_completed")}
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-4">
                      <h4>{t("by_candidate_nursing_degree")}</h4>
                      <br />


                      <select className="form-control" name="degree">
                        <option value={""}>Select</option>
                        <option value={"1"}>
                          {t("bsc_nursing_4_years_course")}
                        </option>

                        <option value={"2"}>
                          {t("msc_nursing_2_years_course")}
                        </option>

                        <option value={"3"}>
                          {t("diploma_in")}
                        </option>

                        <option value={"4"}>
                          {t("post_basic")}
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-4">
                      <h4>{t("candiate_gender")}</h4>
                      <br />

                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id={"male"}
                          value={"male"}
                          name="gender[]"
                        //onChange={(e) => _filterByLang(e)}
                        />
                        <label class="form-check-label" for={"male"}>
                          {t("male")}
                        </label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id={"female"}
                          value={"female"}
                          name="gender[]"
                        //onChange={(e) => _filterByLang(e)}
                        />
                        <label class="form-check-label" for={"female"}>
                          {t("female")}
                        </label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id={"transgender"}
                          value={"transgender"}
                          name="gender[]"
                        //onChange={(e) => _filterByLang(e)}
                        />
                        <label class="form-check-label" for={"transgender"}>
                          {t("transgender")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-grid gap-2 mb-3">
                <button class="btn btn-primary" type="button" onClick={() => _generate()}>
                  {t("generate")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Enquiries;
